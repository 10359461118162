<div class="toolbar">
  <div class="toolbar-button-group">
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('undo')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Cofnij' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="undo"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('redo')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Ponów' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="redo"></fa-icon>
    </button>
  </div>

  <div class="toolbar-button-group">
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('bold')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Pogrubienie' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="bold"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('italic')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Kursywa' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="italic"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('underline')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Podkreślenie' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="underline"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('strikeThrough')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Przekreślenie' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="strikethrough"></fa-icon>
    </button>
  </div>

  <div class="toolbar-button-group">
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('justifyLeft')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Wyrównanie do lewej' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="align-left"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('justifyCenter')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Wyrównanie do środka' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="align-center"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('justifyRight')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Wyrównanie do prawej' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="align-right"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('justifyFull')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Justowanie' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="align-justify"></fa-icon>
    </button>
  </div>

  <div class="toolbar-button-group">
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('insertOrderedList')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Lista numerowana' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="list-ol"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('insertUnorderedList')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Lista punktowana' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="list-ul"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('outdent')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Zmniejsz wcięcie' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="outdent"></fa-icon>
    </button>
    <button class="toolbar-button"
            [class.disabled]="toolbarDisabled"
            (click)="handleEditorCommand('indent')"
            [disabled]="toolbarDisabled"
            [appTooltip]="'Zwiększ wcięcie' | translate: currentLanguageCode | async"
            [tooltipOptions]="{ delay: '100ms' }"
    >
      <fa-icon icon="indent"></fa-icon>
    </button>
  </div>

</div>
