import { EventEmitter, Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { HeaderData } from './header.type';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private subject = new Subject<HeaderData>();

  private autologoutVisible = new EventEmitter<boolean>();

  constructor() { }

  showHeader() {
    this.setData({ showHeader: true });
  }

  hideHeader() {
    this.setData({ showHeader: false });
  }

  setData(data: HeaderData) {
    this.subject.next(data);
  }

  getData(): Observable<HeaderData> {
    return this.subject.asObservable();
  }

  getAutologoutBarVisibility() {
    return this.autologoutVisible;
  }
}
