import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { OverlayService } from './overlay.service';
import { OverlayConfig, OverlayType } from './overlay.type';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [
        animate('200ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms ease-in')
      ])
    ])
  ]
})
export class OverlayComponent implements OnInit {

  currentOverlay: OverlayConfig;

  constructor(
    private overlay: OverlayService,
    private mainHeader: HeaderService
  ) { }

  ngOnInit() {
    this.currentOverlay = {
      show: false,
      spinner: false
    };

    this.overlay.get().subscribe((overlay: OverlayConfig) => {
      if (overlay.show) {

        if (this.currentOverlay.type && this.currentOverlay.type !== overlay.type) {
          this.hide();
        }

        switch (overlay.type) {
          case OverlayType.EDITOR:
            this.mainHeader.setData({
              showAbove: true
            });
            break;
          default:
            this.mainHeader.setData({
              showAbove: false
            });
        }
      }

      this.currentOverlay = overlay;
    });
  }

  hide() {
    this.overlay.hide({
      type: this.currentOverlay.type
    });

    this.currentOverlay = {
      show: false
    };
  }

}
