import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataStoreService } from '../../../../../core/services/data-store.service';
import { StoreKey } from '../../../../../core/services/data-store.type';
import { GtmEventType, GtmService } from '../../../../../core/services/gtm.service';
import { RouteUrl } from '../../../../../app.routes';
import { CreateRepository } from '../../create.repository';
import { CreateStep } from '../../create.type';
import { TranslationComponent } from '../../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends TranslationComponent implements OnInit {

  constructor(
    private router: Router,
    private repository: CreateRepository,
    private dataStore: DataStoreService,
    private gtm: GtmService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {}

  resendDocument(event: MouseEvent) {
    event.preventDefault();

    const createData = this.dataStore.getData(StoreKey.CREATE);
    this.dataStore.setData({ progress: true, step: CreateStep.PROGRESS }, StoreKey.CREATE);

    this.gtm.sendMsg({
      event: 'submitForm',
      description: 'Resend document to sign',
      type: GtmEventType.SUCCESS,
      payload: {
        templateId: createData.templateId,
        templateType: createData.templateType,
        recipients: createData.receivers.length,
        massSend: false
      }
    });

    this.router.navigate([RouteUrl.CREATE_PROGRESS]);
  }

}
