<div class="autologout-bar" *ngIf="willSoonExpire || refreshLoading || refreshSuccess">
  <div class="autologout-bar-content">
    <div *ngIf="willSoonExpire && !refreshLoading">
      <span [innerHTML]="((textContent ? textContent.willSoonExpire : '') | translate: currentLanguageCode | async) | replaceToHtml: { timeToExpire: timeToExpire }"></span>
      <span class="extend-session" (click)="clickRenewSession()">{{ textContent.extendSession | translate: currentLanguageCode | async }}</span>.
    </div>
    <div *ngIf="refreshLoading">
      <div class="spinner-container">
        <div class="spinner">{{ textContent.extendingSessionInProgress | translate: currentLanguageCode | async }}</div>
      </div>
      {{ textContent.extendingSessionInProgress | translate: currentLanguageCode | async }}
    </div>
    <div *ngIf="refreshSuccess">
      {{ textContent.sessionExtended | translate: currentLanguageCode | async }}
    </div>
  </div>
</div>
