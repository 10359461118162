<div class="front-pagination-wrapper" [class.small]="small">
  <div class="front-pagination" *ngIf="allPages.length * pageLimit > pageLimit">
    <div class="buttons">
      <button class="button primary small"
              [class.disabled]="page - 1 <= 0"
              [disabled]="page - 1 <= 0"
              (click)="changePage(1)"
      ><fa-icon icon="angle-double-left"></fa-icon><span></span></button>
      <button class="button primary small"
              [class.disabled]="page - 1 <= 0"
              [disabled]="page - 1 <= 0"
              (click)="changePage(page - 1)"
      ><fa-icon icon="angle-left"></fa-icon><span></span></button>
    </div>

    <div class="pagination-counter">
      {{ page }} / {{ allPages.length }}
    </div>

    <div class="buttons">
      <button class="button primary small"
              [class.disabled]="page + 1 > allPages.length"
              [disabled]="page + 1 > allPages.length"
              (click)="changePage(page + 1)"
      ><span></span><fa-icon icon="angle-right"></fa-icon></button>
      <button class="button primary small"
              [class.disabled]="page + 1 > allPages.length"
              [disabled]="page + 1 > allPages.length"
              (click)="changePage(allPages.length)"
      ><span></span><fa-icon icon="angle-double-right"></fa-icon></button>
    </div>
  </div>
</div>
