import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Subscription } from 'rxjs';

import { DataSource, EditorAction, EditorActionType, ReceiverType, RoleData } from '../../../editor.type';
import { DataStoreService } from '../../../../../../core/services/data-store.service';
import { StoreKey } from '../../../../../../core/services/data-store.type';
import { EditorService } from '../../../editor.service';
import { GtmEventType, GtmService } from '../../../../../../core/services/gtm.service';
import { AddressBookRepository } from '../../../../../address-book/address-book.repository';
import { UserService } from '../../../../../../core/components/header/user/user.service';

@Component({
  selector: 'app-add-role-panel',
  templateUrl: './add-role-panel.component.html',
  styleUrls: ['./add-role-panel.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('void', style({ height: 0, opacity: 0 })),
      transition(':enter', animate('200ms ease-in', style({ height: '!', opacity: 1 }))),
      transition(':leave', animate('200ms ease-out', style(
        { height: 0, opacity: 0, paddingBottom: 0, marginTop: 0 }
        ))),
    ])
  ]
})
export class AddRolePanelComponent implements OnInit, OnDestroy {

  @Input() newRoleId: number;
  @Input() data: any;

  newRoleName: string;
  selfRole: boolean;
  addressData: any;
  canAddRole: boolean;
  dataSource: string;
  massSend: boolean;
  fillContacts: boolean;
  dataSourceTokens: string[];
  tokensFromSchema: string[];

  subscriptions: Subscription[];

  constructor(
    private user: UserService,
    private addressBookRepository: AddressBookRepository,
    private dataStore: DataStoreService,
    private editor: EditorService,
    private gtm: GtmService
  ) { }

  ngOnInit() {
    this.clearSubscriptions();
    this.newRoleId = this.data.length;
    this.canAddRole = false;
    this.selfRole = false;
    this.massSend = false;
    this.fillContacts = false;
    this.dataSource = DataSource.INLINE;
    this.dataSourceTokens = [];
    this.tokensFromSchema = [];

    this.addressBookRepository.getAddressSchema().subscribe((schema: string[]) => {
      this.tokensFromSchema = schema;
    });

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.CLOSE_ADDRESS_BOOK:
            this.addressData = action.params;
            break;
        }
      })
    );
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  closePanel() {
    this.editor.callAction({
      type: EditorActionType.CLOSE_ADD_ROLE_PANEL
    });
  }

  checkDataSource() {
    if (this.dataSource === DataSource.ADDRESS_BOOK || this.selfRole) {
      this.dataSourceTokens = [...[], ...this.tokensFromSchema];
    }
  }

  validateRole() {
    this.canAddRole = this.newRoleName.trim() && !this.data.find((role: RoleData) => role.roleName === this.newRoleName.trim());
  }

  addSelfAddress() {
    if (this.selfRole) {
      this.addressData = this.user.getUserAccount();
      this.dataSource = DataSource.INLINE;
    } else {
      this.addressData = {};
    }

    this.checkDataSource();
  }

  showAddressBook(show: boolean) {
    if (show) {
      this.dataStore.setData({
        setMode: true,
        activeRole: {
          roleId: this.newRoleId,
          tokens: this.dataSourceTokens.map(tokenName => {
            return {
              id: tokenName,
              value: ''
            };
          })
        }
      }, StoreKey.ADDRESS_BOOK_PANEL);

      this.editor.callAction({
        type: EditorActionType.SHOW_ADDRESS_BOOK,
      });
    }
  }

  addRole() {
    const newRoleTokens = [];
    let dataSource = this.dataSource;

    if (dataSource === DataSource.ADDRESS_BOOK || this.selfRole) {
      dataSource = DataSource.INLINE;

      this.dataSourceTokens.forEach(tokenName => {
        newRoleTokens.push({
          id: tokenName,
          value: this.addressData ? this.addressData[tokenName] : '',
          count: 0
        });
      });
    }

    this.gtm.sendMsg({
      event: 'submitForm',
      description: 'New group added',
      type: GtmEventType.SUCCESS,
      payload: {
        dataSource: dataSource,
        roleName: this.newRoleName,
        roleId: this.newRoleId,
        roleTokens: newRoleTokens,
        receiverType: ReceiverType.PERSON_SIGNER
      }
    });

    this.editor.callAction({
      type: EditorActionType.ADD_NEW_ROLE,
      params: {
        dataSource: dataSource,
        roleName: this.newRoleName,
        roleId: this.newRoleId,
        roleTokens: newRoleTokens,
        receiverType: ReceiverType.PERSON_SIGNER
      }
    });
  }
}
