import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AutModalContentComponent } from '../../../../core/components/aut-modal/aut-modal.type';
import { AutModalService } from '../../../../core/components/aut-modal/aut-modal.service';
import { ArrayUniqueValidator } from '../../../../app.validators';
import { Receiver } from '../create.type';
import { EditorService } from '../../editor/editor.service';
import { EditorAction, EditorActionType } from '../../editor/editor.type';
import { TranslationComponent } from '../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss']
})
export class CreateModalComponent extends TranslationComponent implements OnInit, OnDestroy, AutModalContentComponent {

  data: any = {};
  receiverForm: FormGroup;
  receiversList: Receiver[];

  subscriptions: Subscription[];

  constructor(
    private modal: AutModalService,
    private formBuilder: FormBuilder,
    private editor: EditorService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.clearSubscriptions();

    this.receiversList = [...[], ...this.data.receivers];

    const emailValidators = [Validators.required, Validators.email];

    this.receiverForm = this.formBuilder.group({
      firstName: [{ value: '', disabled: this.data.receiver.isUser }, [Validators.required, Validators.pattern(/^.{3,20}$/)]],
      lastName: [{ value: '', disabled: this.data.receiver.isUser }, [Validators.required, Validators.pattern(/^.{2,70}$/)]],
      email: [{ value: '', disabled: this.data.receiver.isUser }],
      receiverType: ['',  Validators.required]
    });

    if (this.data.receiver) {
      this.receiverForm.setValue({
        firstName: this.data.receiver.firstName,
        lastName: this.data.receiver.lastName,
        email: this.data.receiver.email,
        receiverType: this.data.receiver.receiverType
      });

      if (this.data.edit) {
        this.receiversList.splice(
          this.receiversList.findIndex((receiver: Receiver) => receiver.email === this.data.receiver.email),
          1
        );
      }

      emailValidators.push(ArrayUniqueValidator(this.receiversList));
    }

    this.receiverForm.get('email').setValidators(emailValidators);

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.CLOSE_ADDRESS_BOOK:
            this.useAddress(action.params);
            break;
        }
      })
    );
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  private useAddress(address) {
    if (address) {
      this.receiverForm.setValue({
        firstName: address.firstName,
        lastName: address.lastName,
        email: address.email,
        receiverType: this.receiverForm.get('receiverType').value
      });
    }

    this.editor.callAction({ type: EditorActionType.NOOP_ACTION });
  }

  showAddressBook() {
    this.editor.callAction({
      type: EditorActionType.SHOW_ADDRESS_BOOK,
      params: true
    });
  }

  closeModal(hasData: boolean = false) {
    let receiverData = null;

    if (hasData) {
      receiverData = {...{}, ...this.data.receiver, ...this.receiverForm.getRawValue()};
    }

    this.modal.close(receiverData);
  }

}
