<div class="edit-token-panel">
  <app-progress-balls></app-progress-balls>
  <div class="tabs">
    <div class="tab"
         [class.active]="templateMode === TemplateMode.EDIT"
         (click)="switchTemplateMode(TemplateMode.EDIT)"
         appTranslate
    >Tworzenie</div>
    <div class="tab"
         [class.active]="templateMode === TemplateMode.FILL"
         (click)="switchTemplateMode(TemplateMode.FILL)"
         appTranslate
    >Uzupełnianie</div>
  </div>
  <div class="tabs-content" [ngSwitch]="templateMode" [@tabChange]="templateMode">
    <div class="tab-content" *ngSwitchCase="TemplateMode.EDIT" >
      <app-edit-token-tab
        [rolesData]="data"
        [templateType]="templateType"
        [templateMode]="templateMode"
      ></app-edit-token-tab>
    </div>

    <div class="tab-content" *ngSwitchCase="TemplateMode.FILL">
      <app-fill-token-tab
        [rolesData]="data"
        [templateMode]="templateMode"
      ></app-fill-token-tab>
    </div>
  </div>

  <div class="side-panel add-role" *ngIf="showAddRolePanel" [@sidePanel]>
    <app-add-role-panel [data]="data"></app-add-role-panel>
  </div>
</div>
