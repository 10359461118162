import { AbstractControl, ValidatorFn } from '@angular/forms';

export function NipValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const nipFormatRegexp = /^[0-9]{10}$/;
    const clearValue = control.value.replace(/-/g, '');

    if (nipFormatRegexp.test(clearValue) === false) {
      return { 'nip' : { value: control.value }};
    } else {
      const digits = ('' + clearValue).split('');
      const checksum = (6 * parseInt(digits[0], 10) +
        5 * parseInt(digits[1], 10) +
        7 * parseInt(digits[2], 10) +
        2 * parseInt(digits[3], 10) +
        3 * parseInt(digits[4], 10) +
        4 * parseInt(digits[5], 10) +
        5 * parseInt(digits[6], 10) +
        6 * parseInt(digits[7], 10) +
        7 * parseInt(digits[8], 10)) % 11;

      return (parseInt(digits[9], 10) === checksum) ? null : { 'nip' : { value: control.value }};
    }
  };
}

export function SchemaItemValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const schemaFormatRegexp = /^[a-zA-Z]{2,25}$/;

    if (schemaFormatRegexp.test(control.value) === false) {
      return { 'schemaItem' : { value: control.value }};
    } else {
      return null;
    }
  };
}

export function ArrayUniqueValidator(inputArray: any[]): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (inputArray.findIndex(arrayItem => arrayItem.email === control.value.trim()) !== -1) {
      return { 'arrayUnique' : { value: control.value } };
    } else {
      return null;
    }
  };
}

