import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressBookPanelItemService {

  private subject: BehaviorSubject<any>;

  constructor() {
    this.subject = new BehaviorSubject<any>(null);
  }

  get(): Observable<any> {
    return this.subject.asObservable();
  }

  open(id: any) {
    this.subject.next(id);
  }
}
