import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { AppComponent } from './app.component';
import { Routes } from './app.routes';
import { httpInterceptorProviders } from './app.interceptors';
import { EditorComponent } from './pages/automation/editor/editor.component';
import { KeysPipe } from './core/pipes/keys.pipe';
import { AddressBookComponent } from './pages/address-book/address-book.component';
import { AutomationComponent } from './pages/automation/automation.component';
import { AddressBookPanelComponent } from './pages/automation/editor/panel/address-book-panel/address-book-panel.component';
import { AddressComponent } from './pages/address-book/address/address.component';
import { TemplatesComponent } from './pages/automation/templates/templates.component';
import { AddressesComponent } from './pages/address-book/addresses/addresses.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FilterRolePipe } from './core/pipes/filter-role.pipe';
import { UserComponent } from './core/components/header/user/user.component';
import { StatusColorPipe } from './core/pipes/status-color.pipe';
import { FilterTokenPipe } from './core/pipes/filter-token.pipe';
import { DateTimePipe } from './core/pipes/date-time.pipe';
import { ReplaceToHtmlPipe } from './core/pipes/replace-to-html.pipe';
import { FrontPaginationComponent } from './core/components/front-pagination/front-pagination.component';
import { EditTokenPanelComponent } from './pages/automation/editor/panel/edit-token-panel/edit-token-panel.component';
import { FillTokenTabComponent } from './pages/automation/editor/panel/edit-token-panel/fill-token-tab/fill-token-tab.component';
import { EditTokenTabComponent } from './pages/automation/editor/panel/edit-token-panel/edit-token-tab/edit-token-tab.component';
import { ToolbarComponent } from './pages/automation/editor/toolbar/toolbar.component';
import { AutModalComponent } from './core/components/aut-modal/aut-modal.component';
import { AutModalDirective } from './core/components/aut-modal/aut-modal.directive';
import { SettingsPanelComponent } from './pages/automation/editor/panel/settings-panel/settings-panel.component';
import { PanelComponent } from './pages/automation/editor/panel/panel.component';
import { OverlayComponent } from './core/components/overlay/overlay.component';
import { TooltipDirective } from './core/components/tooltip/tooltip.directive';
import { SwitchComponent } from './core/components/switch/switch.component';
import { TextTooltipComponent } from './core/components/tooltip/types/text-tooltip/text-tooltip.component';
import { TokenTooltipComponent } from './core/components/tooltip/types/token-tooltip/token-tooltip.component';
import { TemplatesListComponent } from './pages/automation/templates/templates-list/templates-list.component';
import { AddressSchemaComponent } from './pages/address-book/address-schema/address-schema.component';
import { CreateComponent } from './pages/automation/create/create.component';
import { CreateModalComponent } from './pages/automation/create/create-modal/create-modal.component';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { KeyOrderPipe } from './core/pipes/key-order.pipe';
import { CyganBallsComponent } from './core/components/cygan-balls/cygan-balls.component';
import { AddressBookPanelItemComponent } from './pages/automation/editor/panel/address-book-panel/address-book-panel-list/address-book-panel-item/address-book-panel-item.component';
import { AddressBookPanelListComponent } from './pages/automation/editor/panel/address-book-panel/address-book-panel-list/address-book-panel-list.component';
import { ProgressComponent } from './pages/automation/create/progress/progress.component';
import { SummaryComponent } from './pages/automation/create/summary/summary.component';
import { ErrorComponent } from './pages/automation/create/summary/error/error.component';
import { SuccessComponent } from './pages/automation/create/summary/success/success.component';
import { StripPipe } from './core/pipes/strip.pipe';
import { EditTokenTabDirective } from './pages/automation/editor/panel/edit-token-panel/edit-token-tab/edit-token-tab.directive';
import { TranslationDirective } from './core/components/translation/translation.directive';
import { TranslationPipe } from './core/components/translation/translation.pipe';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AutologoutBarComponent } from './core/components/autologout-bar/autologout-bar.component';
import { ImportCsvModalComponent } from './core/components/import-csv-modal/import-csv-modal.component';
import { TranslationComponent } from './core/components/translation/translation.component';
import { AddRolePanelComponent } from './pages/automation/editor/panel/edit-token-panel/add-role-panel/add-role-panel.component';
import { TooltipComponent } from './core/components/tooltip/tooltip.component';
import { GSchoolComponent } from './pages/gschool/gschool.component';
import { UserPanelComponent } from './core/components/header/user/user-panel/user-panel.component';
import { ToEndPipe } from './core/pipes/to-end.pipe';
import { CsvErrorsModalComponent } from './core/components/csv-errors-modal/csv-errors-modal.component';
import { AppInitService } from './app-init.service';

@NgModule({
    declarations: [
        AppComponent,
        EditorComponent,
        KeysPipe,
        TemplatesComponent,
        AddressBookPanelComponent,
        AddressBookComponent,
        AutomationComponent,
        AddressesComponent,
        AddressComponent,
        HeaderComponent,
        FilterRolePipe,
        FilterTokenPipe,
        StatusColorPipe,
        UserComponent,
        DateTimePipe,
        ReplaceToHtmlPipe,
        FrontPaginationComponent,
        ToolbarComponent,
        EditTokenTabComponent,
        FillTokenTabComponent,
        EditTokenPanelComponent,
        AutModalComponent,
        AutModalDirective,
        PanelComponent,
        SettingsPanelComponent,
        OverlayComponent,
        TooltipDirective,
        SwitchComponent,
        TextTooltipComponent,
        TokenTooltipComponent,
        TemplatesListComponent,
        AddressSchemaComponent,
        CreateComponent,
        CreateModalComponent,
        SpinnerComponent,
        KeyOrderPipe,
        CyganBallsComponent,
        AddressBookPanelItemComponent,
        AddressBookPanelListComponent,
        ProgressComponent,
        SummaryComponent,
        ErrorComponent,
        SuccessComponent,
        StripPipe,
        EditTokenTabDirective,
        TranslationDirective,
        TranslationPipe,
        NotFoundComponent,
        ImportCsvModalComponent,
        AutologoutBarComponent,
        TranslationComponent,
        AddRolePanelComponent,
        TooltipComponent,
        GSchoolComponent,
        UserPanelComponent,
        ToEndPipe,
        CsvErrorsModalComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(Routes, { relativeLinkResolution: 'legacy' }),
        FormsModule
    ],
    exports: [
        TranslationDirective
    ],
    providers: [
        httpInterceptorProviders,
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appInit: AppInitService) => {
                return () => appInit.init();
            },
            deps: [AppInitService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
