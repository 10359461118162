<div class="scroll-page address-add">
  <div class="content-box">
    <div class="content-box-header">
      <span *ngIf="edit; else addlabel" appTranslate>Edycja kontaktu</span>
      <ng-template #addlabel>{{ 'Dodaj nowy kontakt' | translate: currentLanguageCode | async }}</ng-template>
    </div>
    <div class="content-box-content">
      <form class="address" [formGroup]="addressForm" (ngSubmit)="saveAddress()">
        <div class="address-input" *ngFor="let control of addressSchema">
          <label for="{{ control }}">
            <span [class.required]="addressForm.controls[control].errors">
              {{ control | translate: currentLanguageCode | async  }}:
            </span>
            <span *ngIf="control === 'nip' && addressForm.controls.nip.errors" class="error-message" appTranslate>
              Niepoprawny NIP
            </span>
            <input id="{{ control }}" formControlName="{{ control }}" class="aut-input" type="text" autocomplete="off">
          </label>
        </div>
        <div class="actions">
          <button type="button"
                  id="cancel-contact-edit"
                  class="button"
                  routerLink="/automation/addressbook"
                  appTranslate
          >Anuluj</button>
          <button type="button"
                  id="remove-contact"
                  class="button danger"
                  (click)="removeAddress()"
                  *ngIf="edit"
          >
            <fa-icon icon="trash-alt"></fa-icon>
            {{ 'Usuń' | translate: currentLanguageCode | async }}
          </button>
          <button type="submit"
                  id="save-contact"
                  class="button primary"
                  [class.disabled]="!addressForm.valid"
                  [disabled]="!addressForm.valid"
          >
            <span *ngIf="edit; else add" appTranslate>Zapisz</span>
            <ng-template #add>
              <fa-icon icon="user-plus"></fa-icon>
              {{ 'Dodaj' | translate: currentLanguageCode | async }}
            </ng-template>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
