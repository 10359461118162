<div class="scroll-page address-add">
  <div class="content-box">
    <div class="content-box-header">
      <span appTranslate>Edycja schematu książki adresowej</span>
      <div class="content-box-header-actions">
<!--        <button class="button primary medium" routerLink="/automation/addressbook" appTranslate>-->
<!--          Lista kontaktów-->
<!--        </button>-->
      </div>
    </div>
    <div class="content-box-content">
      <div class="address-schema">
        <form class="address-schema-form" [formGroup]="schemaForm" (ngSubmit)="addItemSchema()">
          <div class="address-schema-label" appTranslate>
            Nowe pole
          </div>
          <div class="address-schema-form-group">
            <label for="newSchemaItem">
              <span  appTranslate>Nazwa</span>:
            </label>
            <div class="input-group">
              <input id="newSchemaItem"
                     #newSchemaItem
                     formControlName="schemaItem"
                     class="aut-input"
                     type="text"
                     autocomplete="off"
                     (input)="checkIfExists(newSchemaItem.value)"
              >
              <button type="submit"
                      class="button primary medium"
                      [class.disabled]="hasItemAlready || !schemaForm.valid"
                      [disabled]="hasItemAlready || !schemaForm.valid"
                      appTranslate
              >
                Dodaj
              </button>
            </div>
          </div>
        </form>

        <div class="address-schema-fields">
          <div class="address-schema-label" appTranslate>
            Pola w książce adresowej
          </div>
          <div class="address-schema-list">
            <div class="address-schema-item" *ngFor="let schemaItem of addressSchema">
              {{ schemaItem }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
