import { Component, OnInit } from '@angular/core';

import { DataStoreService } from '../../../../core/services/data-store.service';
import { StoreKey } from '../../../../core/services/data-store.type';
import { CreateData, CreateStep } from '../create.type';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  createData: CreateData;

  constructor(
    private dataStore: DataStoreService
  ) { }

  ngOnInit() {
    this.createData = this.dataStore.getData(StoreKey.CREATE);
    this.dataStore.setData({ step: CreateStep.PREPARE, summary: false }, StoreKey.CREATE);
  }

}
