import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Subscription } from 'rxjs';

import { AddressBookService } from '../../../../../../address-book/address-book.service';
import { AddressBookPanelItemService } from './address-book-panel-item.service';
import { EditorActionType } from '../../../../editor.type';
import { EditorService } from '../../../../editor.service';
import { TranslationComponent } from '../../../../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-address-book-panel-item',
  templateUrl: './address-book-panel-item.component.html',
  styleUrls: ['./address-book-panel-item.component.scss'],
  animations: [
    trigger('slide', [
      state('void', style({ height: 0 })),
      transition(':enter', [
        animate('250ms cubic-bezier(.35,0,.25,1)', style({
          height: '*'
        })),
      ]),
      transition(':leave', [
        animate('250ms cubic-bezier(.35,0,.25,1)'),
      ]),
    ]),
  ]
})
export class AddressBookPanelItemComponent extends TranslationComponent implements OnInit, OnDestroy {

  @Input() addressData;
  @Input() options;

  id: any;
  initials: string;
  style: any;
  isOpen: boolean;

  subscription: Subscription;

  constructor(
    private addressService: AddressBookService,
    private item: AddressBookPanelItemService,
    private editor: EditorService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.id = this.addressData;
    this.isOpen = false;
    this.initials = this.addressService.getInitials(this.addressData);

    this.style = {
      backgroundColor: this.options.color
    };

    this.subscription = this.item.get().subscribe(id => {
      this.isOpen = this.isOpen && this.id === id ? false : this.id === id;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  open() {
    this.item.open(this.id);
  }

  select() {
    this.editor.callAction({
      type: EditorActionType.CLOSE_ADDRESS_BOOK,
      params: this.addressData
    });
  }

}
