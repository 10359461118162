<div class="address-book">
  <div class="address-book-header">
    <div class="header-title" appTranslate>
      Uzupełnij z książki adresowej
    </div>
    <button class="button small" (click)="closeBook(null)" appTranslate>
      Anuluj
    </button>
  </div>

  <div class="address-book-content">
    <div class="addresses-scroll">
      <div class="addresses">
        <app-address-book-panel-list
          *ngIf="options.self"
          [header]="'Twoje dane' | translate: currentLanguageCode | async"
          [addresses]="[selfAddress]"
          [options]="{ color: '#1c92dd', keyOrder: customKeyOrder }"
        >
        </app-address-book-panel-list>

        <div class="spinner-box" *ngIf="showSpinner">
          <app-spinner color="blue"></app-spinner>
        </div>
        <div class="address-empty" *ngIf="!showSpinner && !filteredAddresses.length" appTranslate>
          Brak kontaktów w książce adresowej
        </div>

        <app-address-book-panel-list
          *ngIf="addresses?.length"
          [header]="'Twoje kontakty' | translate: currentLanguageCode | async"
          [addresses]="filteredAddresses"
          [options]="{ keyOrder: customKeyOrder }"
        >
        </app-address-book-panel-list>

        <app-front-pagination
          *ngIf="addresses?.length"
          [small]="true"
          [pageLimit]="pageLimit"
          [itemsCount]="addresses.length"
          (pageChanged)="changePage($event)"
        ></app-front-pagination>

      </div>
    </div>
  </div>
</div>
