import { Component, Injector, OnInit } from '@angular/core';

import { DataStoreService } from '../../../../../core/services/data-store.service';
import { StoreKey } from '../../../../../core/services/data-store.type';
import { CreateData } from '../../create.type';
import { ReceiverType } from '../../../editor/editor.type';
import { TranslationComponent } from '../../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent extends TranslationComponent implements OnInit {

  jokes: string[];
  currentJoke: string;
  createData: CreateData;
  infoHeader: string;
  infoMessage: string;
  senderMessage: string;
  receiversMessage: string;

  constructor(
    private dataStore: DataStoreService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.createData = this.dataStore.getData(StoreKey.CREATE);

    this.setSummaryInfo();
    this.setSenderInfo();
    this.setReceiversInfo();

    this.jokes = [
      'Wypić kawę i poczytać książkę.',
      'Zrobić smoothie z jarmużu i dwóch jabłek.',
      'Nauczyć się żonglowania',
      'Dać nam like’a na Facebooku https://www.facebook.com/AutentiPL/'
    ];

    this.currentJoke = this.jokes[Math.floor(Math.random() * (this.jokes.length - 1))];
  }

  private setSummaryInfo() {
    switch (this.createData.user.receiverType) {
      case ReceiverType.PERSON_SIGNER:
        this.infoMessage = 'W tym czasie będzie on widoczny w zakładce “Wymagające działania” gdzie będziesz mógł go podpisać';
        if (this.createData.receivers.length > 1) {
          this.infoHeader = 'Twój dokument został poprawnie wysłany i oczekuje na reakcję odbiorców';
        } else {
          this.infoHeader = 'Twój dokument został poprawnie wysłany i oczekuje na twoją reakcję';
        }
        break;
      case ReceiverType.PERSON_VIEWER:
        if (this.createData.receivers.filter(
          receiver => receiver.receiverType === ReceiverType.PERSON_VIEWER
        ).length === this.createData.receivers.length) {
          this.infoHeader = 'Twój dokument został poprawnie wysłany';
          this.infoMessage = 'W tym czasie będzie on widoczny w zakładce “Zakończone” gdzie będziesz mógł obserwować jego status.';
        } else {
          this.infoHeader = 'Twój dokument został poprawnie wysłany i oczekuje na reakcję odbiorców';
          this.infoMessage =
            'W tym czasie będzie on widoczny w zakładce “Oczekujące na innych” gdzie będziesz mógł obserwować jego status.';
        }
        break;
    }
  }

  private setSenderInfo() {
    this.senderMessage = this.createData.user.receiverType === ReceiverType.PERSON_SIGNER ?
      'Jesteś nadawcą oraz dodatkowo masz podpisać dokument jako konsument' :
      'Jesteś nadawcą oraz dodatkowo masz zapoznać się z treścią dokumentu (bez podpisywania)';
  }

  private setReceiversInfo() {
    if (this.createData.receivers.length > 1) {
      if (this.createData.receivers.filter(
        receiver => receiver.receiverType === ReceiverType.PERSON_SIGNER
      ).length === this.createData.receivers.length) {
        this.receiversMessage = 'Wszyscy mają podpisać dokument';
      } else if (this.createData.receivers.filter(
        receiver => receiver.receiverType === ReceiverType.PERSON_VIEWER
      ).length === this.createData.receivers.length) {
        this.receiversMessage = 'Wszyscy mają zapoznać się z treścią dokumentu (bez podpisywania)';
      } else {
        const viewers = this.createData.receivers.filter(
          receiver => receiver.receiverType === ReceiverType.PERSON_VIEWER
        ).length;
        const signers = this.createData.receivers.length - viewers;

        this.receiversMessage = `${signers} ma podpisać dokument, ${viewers} tylko zapoznać się z treścią dokumentu (bez podpisywania)`;
      }
    } else {
      this.receiversMessage = this.createData.user.receiverType === ReceiverType.PERSON_SIGNER ?
        'Masz podpisać dokument' : 'Masz zapoznać się z treścią dokumentu (bez podpisywania)';
    }
  }
}
