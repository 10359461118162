import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function createMetaTag(name: string, content: string) {
  const newMeta = document.createElement('meta');

  newMeta.name = name;
  newMeta.content = content;

  return newMeta;
}

function addCustomMetaTag(name: string, content: string) {
  const newMeta = createMetaTag(name, content);

  const metaTags = document.querySelectorAll('meta');
  const lastTag = metaTags[metaTags.length - 1];

  lastTag.parentNode.insertBefore(newMeta, lastTag.nextSibling);
}

if (environment.production) {
  enableProdMode();
}

addCustomMetaTag('last-modified', environment.lastModified);
addCustomMetaTag('app-version', environment.appVersion);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
