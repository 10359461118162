import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() id: any;
  @Input() value: any;
  @Input() model: any;
  @Input() disabled: any;

  @Output() changed: EventEmitter<any>;
  @Output() modelChanged: EventEmitter<any>;

  focus: boolean;

  constructor() {
    this.changed = new EventEmitter<any>();
    this.modelChanged = new EventEmitter<any>();
  }

  ngOnInit() {
    this.focus = false;
  }

  switchChanged() {
    this.changed.emit(this.model);
  }
}
