import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { TooltipComponent } from '../../tooltip.component';
import { TooltipPositionType } from '../../tooltip.type';

@Component({
  selector: 'app-text-tooltip',
  templateUrl: './text-tooltip.component.html',
  styleUrls: ['./text-tooltip.component.scss'],
  animations: [
    trigger('appear', [
      state('void', style({ opacity: 0 })),
      transition(':enter', animate('100ms {{delay}} ease-in', style({ opacity: 1 }))),
      transition(':leave', animate('100ms ease-out', style({ opacity: 0 })))
    ])
  ]
})
export class TextTooltipComponent extends TooltipComponent implements OnInit, AfterViewInit {

  @ViewChild('textTooltip') textTooltip: ElementRef;

  text: string;
  wide: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.positionType = this.data.position.type || TooltipPositionType.TOP;
    this.text = this.data.text;
    this.wide = !!this.data.wide;
  }

  ngAfterViewInit() {
    this.setTooltipPosition(this.textTooltip);
  }

}
