import { Injectable } from '@angular/core';

import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

import { TranslationService } from '../components/translation/translation.service';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  infoTexts: string[];
  originTexts: string[];
  defaultSettings: SweetAlertOptions;

  constructor(
    private translationService: TranslationService
  ) {
    this.originTexts = [
      'Tak', 'Anuluj', 'Informacja', 'Operacja w toku', 'Sukces', 'Operacja zakończona sukcesem',
      'Błąd', 'Coś poszło nie tak', 'Uwaga', 'Jesteś pewny'
    ];

   this.translationService.watchTranslation().subscribe(translations => {
      const currentLanguage = this.translationService.getLanguage();
      this.infoTexts = this.originTexts.map(originText => {
        if (translations && translations.hasOwnProperty(currentLanguage)) {
          return translations[currentLanguage][originText] || originText;
        }

        return '';
      });

     this.defaultSettings = {
       confirmButtonText: this.infoTexts[0],
       confirmButtonColor: '#1c91dd',
       cancelButtonText: this.infoTexts[1],
       cancelButtonColor: '#dc493a',
     };
    });
  }

  info(customOptions: SweetAlertOptions = null): Promise<SweetAlertResult>  {
    const settings: SweetAlertOptions = {
      ...this.defaultSettings,
      ...{ title: this.infoTexts[2],
        text: this.infoTexts[3] + '...',
        icon: 'info',
        showConfirmButton: false,
        timer: 2000
      },
      ...customOptions
    };
    return Swal.fire(settings);
  }

  success(customOptions: SweetAlertOptions = null): Promise<SweetAlertResult>  {
    const settings: SweetAlertOptions = {
      ...this.defaultSettings,
      ...{ title: this.infoTexts[4],
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      },
      ...customOptions
    };

    return Swal.fire(settings);
  }

  error(customOptions: SweetAlertOptions = null): Promise<SweetAlertResult> {
    const settings: SweetAlertOptions = {
      ...this.defaultSettings,
      ...{ title: this.infoTexts[6],
        text: this.infoTexts[7] + '... :(',
        icon: 'error',
        showConfirmButton: false,
        timer: 2000
      },
      ...customOptions
    };

    return Swal.fire(settings);
  }

  askWarning(customOptions: SweetAlertOptions = null): Promise<SweetAlertResult> {
    const settings: SweetAlertOptions = {
      ...this.defaultSettings,
      ...{ title: this.infoTexts[8],
        text: this.infoTexts[9] + '?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.infoTexts[1]
      },
      ...customOptions
    };

    return Swal.fire(settings);
  }
}
