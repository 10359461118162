import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { AccountType, User, UserAccount } from './user.type';
import { EnvironmentService } from '../../../services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl: string;
  subject: BehaviorSubject<User>;

  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
    this.subject = new BehaviorSubject<any>(null);
    this.apiUrl = `${this.env.get('apiUrl')}/${this.env.get('apiPrefix')}`;
  }

  watchUserData(): Observable<User> {
    return this.subject.asObservable();
  }

  getUserData(): User {
    return this.subject.getValue();
  }

  getUserAccount(): UserAccount {
    const user = this.subject.getValue();

    return user.account;
  }

  setUserData(userData: User) {
    this.subject.next(userData);
  }

  getUser(): Observable<User> {
    const url = `${this.apiUrl}/document-templates/client/account`;

    return this.http.get<User>(url).pipe(
      mergeMap((response: User) => this.getUserLogo(response).pipe(
        map((logo) => {
          if (logo) {
            response.account.logo = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(logo));
          }
          return response;
        }),
        catchError(error => {
          return new Observable<User>(subject => {
            response.account.logo = null;
            subject.next(response);
            subject.complete();
          });
        })
      ))
    );
  }

  getUserLogo(user: User): Observable<any> {
    if (user.account.type === AccountType.ORGANIZATION) {
      const url = `${this.apiUrl}/images/logo`;

      return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
        map(response => response.body)
      );
    }

    return new Observable(subject => {
      subject.next(null);
      subject.complete();
    });
  }
}
