import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { Address } from '../address-book.type';
import { AddressBookRepository } from '../address-book.repository';
import { TranslationComponent } from '../../../core/components/translation/translation.component';
import { PopupService } from '../../../core/services/popup.service';
import { GtmEventType, GtmService } from '../../../core/services/gtm.service';
import { NipValidator } from '../../../app.validators';
import { RouteUrl } from '../../../app.routes';

@Component({
  selector: 'app-add-contact',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends TranslationComponent implements OnInit {

  addressSchema: string[];
  addressForm: FormGroup;
  edit: boolean;
  editAddressId: string;
  companyValidation: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private repository: AddressBookRepository,
    private popup: PopupService,
    private gtm: GtmService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.edit = false;
    this.companyValidation = false;

    this.route.data.subscribe((data: {
      addressSchema: string[];
      address: Address
    }) => {
      this.addressSchema = [...[], ...data.addressSchema];
      this.createForm();

      if (data.address) {
        this.edit = true;
        this.editAddressId = data.address.id;
        const { id, ...address} = data.address;
        this.editAddress(address);
      }
    });
  }

  private createForm() {
    this.addressForm = this.formBuilder.group({});

    this.addressSchema.forEach(schemaItem => {
      const formControl = new FormControl('');

      switch (schemaItem) {
        case 'email':
          formControl.setValidators([Validators.required, Validators.email]);
          break;
        case 'firstName':
          formControl.setValidators([Validators.required]);
          break;
        case 'lastName':
          formControl.setValidators([Validators.required]);
          break;

      }

      this.addressForm.addControl(schemaItem, formControl);
    });

    this.setDynamicValidators();
  }

  private setDynamicValidators() {
    const nipControl = this.addressForm.get('nip');
    const companyNameControl = this.addressForm.get('companyName');
    const jobTitleControl = this.addressForm.get('jobTitle');

    this.addressForm.valueChanges.pipe(
      map(controls => {
        return {
          nip: controls.nip,
          companyName: controls.companyName,
          jobTitle: controls.jobTitle
        };
      })
    ).subscribe(controls => {
      if (controls.nip.trim() !== '' || controls.companyName.trim() !== '' || controls.jobTitle.trim() !== '') {
        if (!this.companyValidation) {
          nipControl.setValidators([NipValidator(), Validators.required]);
          companyNameControl.setValidators(Validators.required);
          jobTitleControl.setValidators(Validators.required);
          this.companyValidation = true;
          nipControl.updateValueAndValidity();
          companyNameControl.updateValueAndValidity();
          jobTitleControl.updateValueAndValidity();
        }

      } else {
        if (this.companyValidation) {
          nipControl.clearValidators();
          companyNameControl.clearValidators();
          jobTitleControl.clearValidators();
          this.companyValidation = false;
          nipControl.updateValueAndValidity();
          companyNameControl.updateValueAndValidity();
          jobTitleControl.updateValueAndValidity();
        }
      }
    });
  }

  private editAddress(address: Address) {
    const addressMap = {};

    this.addressSchema.forEach(schemaItem => {
      if (schemaItem === 'email') {
        addressMap['email'] = address.email;
      } else {
        addressMap[schemaItem] = address.tokens[schemaItem] || '';
      }
    });

    this.addressForm.setValue({ ...{}, ...addressMap });
  }

  saveAddress() {
    const address = { ...{}, ...this.addressForm.value };

    if (address.nip) {
      address.nip = address.nip.replace(/-+/g, '-').replace(/^-|-$/g, '');
    }

    const addressToSave = {
      email: address.email,
      tokens: address
    };

    if (this.edit) {
      this.repository.updateAddress(this.editAddressId, addressToSave).subscribe(response => {
        this.popup.success({ text: this.translationService.translate('Kontakt zapisany') });
        this.router.navigate([RouteUrl.ADDRESS_BOOK]);

        this.gtm.sendMsg({
          event: 'submitForm',
          description: 'Update contact',
          type: GtmEventType.SUCCESS,
          payload: {
            addressId: this.editAddressId,
            newAddress: address
          }
        });
      });
    } else {
      this.repository.addAddress(addressToSave).subscribe(response => {
        this.popup.success({ text: this.translationService.translate('Dodano nowy kontakt') });

        this.gtm.sendMsg({
          event: 'submitForm',
          description: 'Added new contact',
        });

        this.router.navigate([RouteUrl.ADDRESS_BOOK]);
      });
    }
  }

  removeAddress() {
    this.repository.removeAddress(this.editAddressId).subscribe(response => {

      this.gtm.sendMsg({
        event: 'submitForm',
        description: 'Remove contact',
        type: GtmEventType.SUCCESS,
        payload: {
          addressId: this.editAddressId
        }
      });

      this.router.navigate([RouteUrl.ADDRESS_BOOK]);
    });
  }

}
