export enum Node {
  TEXT = '#text',
  DIV = 'DIV',
  TOKEN = 'TOKEN',
  BLOCKQUOTE = 'BLOCKQUOTE',
  ORDERED_LIST = 'UL',
  UNORDERED_LIST = 'OL',
  LI = 'LI',
  TD = 'TD'
}

export interface RoleData {
  roleId: number;
  roleName: string;
  dataSource: {
    type: DataSource,
    tokens?: any,
  };
  tokens: TokenData[];
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
  nip?: string;
  lang?: string;
  checked?: boolean;
  company?: boolean;
}

export interface TokenData {
  id: string;
  value: string;
  count?: number;
}

export interface CursorPosition {
  x: number;
  y: number;
}

export interface TokenParams {
  id?: string;
  roleId?: number;
  uniq?: string;
}

export interface DraggedToken {
  id: string;
  roleId: number;
  uniq: string;
  position: CursorPosition;
}

export const enum EditorActionType {
  NOOP_ACTION = 'NOOP_ACTION',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  INSERT_TOKEN = 'INSERT_TOKEN',
  CHECK_TOKENS_VALUES = 'CHECK_TOKENS_VALUES',
  SHOW_ADDRESS_BOOK = 'SHOW_ADDRESS_BOOK',
  CLOSE_ADDRESS_BOOK = 'CLOSE_ADDRESS_BOOK',
  CLOSE_MULTI_SEND_ADDRESS_BOOK = 'CLOSE_MULTI_SEND_ADDRESS_BOOK',
  SAVE_TEMPLATE = 'SAVE_TEMPLATE',
  CHECK_TEMPLATE = 'CHECK_TEMPLATE',
  SHOW_ADD_ROLE_PANEL = 'SHOW_ADD_ROLE_PANEL',
  CLOSE_ADD_ROLE_PANEL = 'CLOSE_ADD_ROLE_PANEL',
  ADD_NEW_ROLE = 'ADD_NEW_ROLE',
  REINDEX_TOKENS = 'REINDEX_TOKENS',
  CHANGE_TOKENS_MODE = 'CHANGE_TOKENS_MODE',
  UPDATE_CARET_POSITION = 'UPDATE_CARET_POSITION',
  ENABLE_TOOLBAR = 'ENABLE_TOOLBAR',
  DISABLE_TOOLBAR = 'DISABLE_TOOLBAR',
  PREPARE_TO_SEND = 'PREPARE_TO_SEND',
}

export interface EditorAction {
  type: EditorActionType;
  params?: any;
}

export const enum ReceiverType {
  PERSON_SIGNER = 'PERSON_SIGNER',
  PERSON_VIEWER = 'PERSON_VIEWER',
  COMPANY_SIGNER =  'COMPANY_SIGNER',
  COMPANY_VIEWER = 'COMPANY_VIEWER',
  NONE = 'NONE',
  VIEWER = 'VIEWER',
  SIGNER = 'SIGNER'
}

export const enum DataSource {
  INLINE = 'INLINE',
  WORKFLOW = 'WORKFLOW',
  ADDRESS_BOOK = 'ADDRESS_BOOK',
  ADDRESS_BOOK_MULTI = 'ADDRESS_BOOK_MULTI'
}

export const INDENT_WIDTH = 40;
export const MAX_INDENT_WIDTH = 640;
