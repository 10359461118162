import { Directive, ElementRef, HostListener, Input, OnInit, ViewContainerRef } from '@angular/core';

import { TooltipService } from './tooltip.service';
import { TextTooltipData, TooltipOptions, TooltipPosition, TooltipPositionType } from './tooltip.type';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit {

  @Input('appTooltip') tooltipText: string;
  // tslint:disable-next-line:no-input-rename
  @Input('tooltipOptions') tooltipOptions: TooltipOptions;

  element: ElementRef;
  containerRef: ViewContainerRef;

  defaultOptions: TooltipOptions;

  constructor(
    el: ElementRef,
    vc: ViewContainerRef,
    private tooltip: TooltipService
  ) {
    this.element = el;
    this.containerRef = vc;

    this.defaultOptions = {
      position: TooltipPositionType.TOP,
      delay: '2000ms'
    };
  }

  ngOnInit() {
    this.tooltipOptions = {...{}, ...this.defaultOptions, ...this.tooltipOptions };
  }

  @HostListener('mouseenter') onMouseEnter() {
    const { top, left, right, width, height } = this.element.nativeElement.getBoundingClientRect();
    let position: TooltipPosition = {
      type: TooltipPositionType.TOP
    };

    switch (this.tooltipOptions.position) {
      case TooltipPositionType.RIGHT:
        position = {
          type: TooltipPositionType.RIGHT,
          top: top + (height / 2),
          left: (left + width + 8)
        };
        break;
      case TooltipPositionType.LEFT:
        position = {
          type: TooltipPositionType.LEFT,
          top: top + (height / 2),
          left: right - width
        };
        break;
      case TooltipPositionType.TOP:
        position = {
          type: TooltipPositionType.TOP,
          top: top,
          left: left + (width / 2)
        };
        break;
    }

    const tooltipData: TextTooltipData = {
      position: position,
      delay: this.tooltipOptions.delay,
      text: this.tooltipText,
      wide: this.tooltipOptions.wide
    };

    this.tooltip.show(this.containerRef, tooltipData);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.tooltip.hide();
  }

  @HostListener('mousewheel') onMouseScroll() {
    this.tooltip.hide();
  }

}

