import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CreateRepository } from '../create.repository';
import { DataStoreService } from '../../../../core/services/data-store.service';
import { SentDocuments } from '../create.type';
import { StoreKey } from '../../../../core/services/data-store.type';
import { RouteUrl } from '../../../../app.routes';
import { GtmEventType, GtmService } from '../../../../core/services/gtm.service';
import { CreateStep } from '../create.type';
import { TranslationComponent } from '../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent extends TranslationComponent implements OnInit {

  messages: string[];
  currentMessage: string;
  createProcessId: string;
  requestComplete: boolean;

  constructor(
    private router: Router,
    private repository: CreateRepository,
    private dataStore: DataStoreService,
    private gtm: GtmService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.createProcessId = '';
    this.currentMessage = '';
    this.requestComplete = false;

    this.messages = [
      'Tworzymy dokument PDF',
      'Dodajemy Kartę Podpisów',
      'Nabijamy pieczęć otwierającą i szyfrujemy dokument'
    ];

    const createData = this.dataStore.getData(StoreKey.CREATE);

    this.repository.sendDocument(createData.documentToSend).subscribe(createProcessId => {
      this.createProcessId = createProcessId;

      this.gtm.sendMsg({
        event: 'submitForm',
        description: 'Send document to sign',
        type: GtmEventType.SUCCESS,
        payload: {
          templateId: createData.templateId,
          templateType: createData.templateType,
          recipients: createData.receivers.length,
          massSend: false
        }
      });

      this.changeMessages();

      this.repository.getDocumentStatus(this.createProcessId).subscribe((response: SentDocuments) => {
        this.requestComplete = true;
        this.dataStore.setData({
          step: CreateStep.SUMMARY,
          success: true,
          documentUrl: response.redirectUrl
        }, StoreKey.CREATE);
      }, error => {
        this.dataStore.setData({
          step: CreateStep.SUMMARY,
          success: false,
        }, StoreKey.CREATE);

        this.router.navigate([RouteUrl.CREATE_SUMMARY_ERROR]);
      });

      this.router.navigate([`${RouteUrl.CREATE}/${this.createProcessId}`]);
    }, error => {
      this.dataStore.setData({
        step: CreateStep.SUMMARY,
        success: false
      }, StoreKey.CREATE);

      this.router.navigate([RouteUrl.CREATE_SUMMARY_ERROR]);
    });
  }

  private changeMessages() {
    let messageIndex = 0;
    const messagesCount = this.messages.length - 1;

    this.currentMessage = this.messages[messageIndex];

    const changeInterval = setInterval(() => {
      if (messageIndex === messagesCount && this.requestComplete) {
        clearInterval(changeInterval);
        this.router.navigate([`${RouteUrl.CREATE}/${this.createProcessId}/summary`]);
      }
      messageIndex += messageIndex < messagesCount ? 1 : 0;
      this.currentMessage = this.messages[messageIndex];
    }, 2000);
  }

}
