import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { OverlayConfig, OverlayType } from './overlay.type';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private subject: Subject<OverlayConfig>;
  defaultConfig: OverlayConfig;

  constructor() {
    this.subject = new Subject<OverlayConfig>();
    this.defaultConfig = {
      type: OverlayType.MAIN,
      color: 'invisible'
    };
  }

  get(): Observable<OverlayConfig> {
    return this.subject.asObservable();
  }

  show(config: OverlayConfig = {}) {
    const overlayConfig = { ...{}, ...this.defaultConfig, ...config, ...{ show: true } };

    this.subject.next(overlayConfig);
  }

  hide(config: OverlayConfig = {}) {
    const overlayConfig = { ...{}, ...this.defaultConfig, ...config, ...{ show: false } };

    this.subject.next(overlayConfig);
  }
}
