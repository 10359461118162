import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';

import { DraggedToken, EditorAction, EditorActionType, RoleData, TokenData } from '../../../editor.type';
import { EditorService } from '../../../editor.service';
import { EditorDragService } from '../../../editor-drag.service';
import { OverlayService } from '../../../../../../core/components/overlay/overlay.service';
import { OverlayConfig, OverlayType } from '../../../../../../core/components/overlay/overlay.type';
import { PopupService } from '../../../../../../core/services/popup.service';
import { DataStoreService } from '../../../../../../core/services/data-store.service';
import { StoreKey } from '../../../../../../core/services/data-store.type';
import { GtmService } from '../../../../../../core/services/gtm.service';
import { CyganBallsService } from '../../../../../../core/components/cygan-balls/cygan-balls.service';
import { TranslationComponent } from '../../../../../../core/components/translation/translation.component';
import { TemplateType } from '../../../../templates/templates.type';
import { HeaderService } from '../../../../../../core/components/header/header.service';

@Component({
  selector: 'app-edit-token-tab',
  templateUrl: './edit-token-tab.component.html',
  styleUrls: ['./edit-token-tab.component.scss'],
  animations: [
    trigger('slide', [
      state('false, void', style({ height: 0, padding: 0, marginBottom: 0, overflow: 'hidden' })),
      transition('false => true', [
        animate('250ms cubic-bezier(.35,0,.25,1)', style({
          height: '!',
          padding: '!',
          marginBottom: '!',
          overflow: 'visible'
        })),
      ]),
      transition('true => false, :leave', [
        animate('250ms cubic-bezier(.35,0,.25,1)'),
      ]),
    ]),
    trigger('fade', [
      state('void', style({
        opacity: 0, top: 0, right: 0, margin: 0, padding: 0, height: 0
      })),
      transition(':enter', [
        animate('200ms ease-in',
          style({ opacity: 1, top: '!', right: '!', height: '!' })
        )
      ]),
      transition(':leave', [animate('200ms ease-out')]),
    ]),
    trigger('disappear', [
      transition(':leave', animate('800ms ease', style({
        opacity: 0,
      })))
    ])
  ]
})
export class EditTokenTabComponent extends TranslationComponent implements OnInit, OnDestroy {

  @Input() rolesData: RoleData[];
  @Input() templateType: TemplateType;
  @Input() templateMode: string;

  draggedToken: DraggedToken;
  addNewToken = false;
  newTokenRole: number;
  newTokenId: string;
  expandedRoles: any;
  roleOption: number;
  tokenOption: string;
  canSaveTemplate: boolean;
  showFirstTimeInfo: boolean;
  templateNew: boolean;
  TemplateType = TemplateType;
  originTexts: string[];
  infoTexts: string[];

  autologoutBarVisible = false;

  subscriptions: Subscription[];

  constructor(
    private route: ActivatedRoute,
    private editor: EditorService,
    private dragService: EditorDragService,
    private overlay: OverlayService,
    private popup: PopupService,
    private dataStore: DataStoreService,
    private gtm: GtmService,
    private cyganBalls: CyganBallsService,
    private headerService: HeaderService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.clearSubscriptions();
    this.newTokenId = '';
    this.showFirstTimeInfo = false;
    this.originTexts = [
      'Usuwanie grupy', 'Nie możesz jeszcze usunąć grupy', 'Aby to zrobić, usuń wszystkie wystąpienia pól przypisane do tej grupy z treści dokumentu.',
      'Czy chcesz usunąć tę grupę?', 'Usuń', 'Anuluj', 'Grupa', 'nie została wykorzystana w treści, dlatego zostanie usunięta po zapisaniu szablonu.',
      'Grupy', 'nie zostały wykorzystane w treści, dlatego zostaną usunięte po zapisaniu szablonu.', 'Zapis szablonu',
      'Zapisz', 'Anuluj', 'Usuwanie pola', 'Nie możesz jeszcze usunąć pola', 'Aby to zrobić, usuń wszystkie wystąpienia tego pola z treści dokumentu',
      'Dopiero wtedy będzie można usunąć to pole'
    ];
    this.infoTexts = [];

    this.templateNew = this.route.snapshot.data.hasOwnProperty('templateNew') ?
      this.route.snapshot.data.templateNew : false;

    this.setRolesState();

    this.translationService.watchTranslation().subscribe(translations => {
      const currentLanguage = this.translationService.getLanguage();
      this.infoTexts = this.originTexts.map(originText => {
        return translations[currentLanguage][originText] || originText;
      });
    });

    this.cyganBalls.setItems([
      { label: 'Tworzenie', active: true },
      { label: 'Uzupełnianie' },
      { label: 'Dodawanie odbiorców' },
      { label: 'Wysyłanie' },
    ]);

    this.editor.callAction({
      type: EditorActionType.CHECK_TEMPLATE
    });

    this.subscriptions.push(
      this.headerService.getAutologoutBarVisibility().subscribe((visibility) => {
        this.autologoutBarVisible = visibility;
      })
    );

    this.subscriptions.push(
      this.dragService.getDragToken().subscribe((draggedToken: DraggedToken) => {
        this.draggedToken = draggedToken;
     })
    );

    this.subscriptions.push(
      this.overlay.get().subscribe((overlay: OverlayConfig) => {
        switch (overlay.type) {
          case OverlayType.MAIN:
            this.roleOption = !overlay.show ? -1 : this.roleOption;
            this.tokenOption = !overlay.show ? '' : this.tokenOption;
            break;
        }
      })
    );

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.ADD_NEW_ROLE:
            this.addRole(action.params);
            break;
        }
      })
    );

    this.dataStore.watchData().subscribe(data => {
      if (data[StoreKey.EDITOR]) {
        this.canSaveTemplate = data[StoreKey.EDITOR].canSaveTemplate;
        this.showFirstTimeInfo = data[StoreKey.EDITOR].templateNew;
      }
    });
  }

  ngOnDestroy() {
    this.dataStore.setData(this.expandedRoles, StoreKey.TAB_EXTENDED_ROLES);

    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  private setRolesState() {
    this.expandedRoles = this.dataStore.getData(StoreKey.TAB_EXTENDED_ROLES);

    if (!this.expandedRoles) {
      this.expandedRoles = {};
      this.rolesData.forEach(role => {
        this.expandedRoles[role.roleId] = true;
      });
    }
  }

  private hasTokens(role: RoleData): boolean {
    for (let i = 0; i < role.tokens.length; i++) {
      if (role.tokens[i].count > 0) {
        return true;
      }
    }

    return false;
  }

  hideFirstTimeInfo() {
    this.showFirstTimeInfo = false;
    this.dataStore.setData({ templateNew: false }, StoreKey.EDITOR);
  }

  expandRole(roleIndex: number) {
    this.expandedRoles[roleIndex] = !this.expandedRoles[roleIndex];
  }

  showRoleOptions(roleIndex: number) {
    if (this.roleOption !== roleIndex) {
      this.roleOption = roleIndex;
      this.overlay.show();
    }
  }

  handleTokenInsert(tokenId, roleId) {
    this.editor.callAction({
      type: EditorActionType.INSERT_TOKEN,
      params: {
        id: tokenId,
        roleId: roleId
      }
    });
  }

  handleTokenDragStart(event: DragEvent, roleId, tokenId) {
    const element = (<HTMLElement>event.target);
    const styles = window.getComputedStyle(element);

    // Required by Firefox
    event.dataTransfer.setData('text/plain', '');

    tokenId = tokenId || element.getAttribute('data-id');
    roleId = roleId || +element.getAttribute('data-role');

    element.classList.add('dragged');
    event.dataTransfer.setDragImage(
      this.dragService.createDragImage(tokenId, styles), 20, -10
    );

    const draggedToken = {
      id: tokenId,
      roleId: roleId,
      uniq: element.getAttribute('data-uniq') || '',
      position: {
        x: 0,
        y: 0
      }
    };

    this.dragService.setDragToken(draggedToken);
  }

  handleTokenDragEnd(event: DragEvent) {
    document.querySelector('.dragged').classList.remove('dragged');
  }

  showNewRolePanel() {
    this.gtm.sendMsg({
      event: 'submitForm',
      description: 'Add new role'
    });

    this.editor.callAction({
      type: EditorActionType.SHOW_ADD_ROLE_PANEL
    });
  }

  addRole(newRole: any, newTemplateFirstRole: boolean = false) {
    const tokens = {};

    newRole.roleTokens.forEach(token => {
      tokens[token.id] = token.value;
    });

    for (const expand in this.expandedRoles) {
      if (this.expandedRoles.hasOwnProperty(expand)) {
        this.expandedRoles[expand] = false;
      }
    }

    this.expandedRoles[newRole.roleId] = true;

    this.rolesData.push({
      dataSource: {
        type: newRole.dataSource,
        tokens: tokens
      },
      roleId: newRole.roleId,
      roleName: newRole.roleName,
      tokens: newRole.roleTokens,
    });

    if (!newTemplateFirstRole) {
      this.editor.callAction({
        type: EditorActionType.CLOSE_ADD_ROLE_PANEL,
        params: this.rolesData
      });
    }
  }

  removeRole(role: RoleData) {
    if (this.hasTokens(role)) {
      this.popup.info({
        title: this.infoTexts[0],
        html: `${this.infoTexts[1]} “${role.roleName}”. <br> ${this.infoTexts[2]}.`,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        timer: null
      });
    } else {
      this.popup.askWarning({
        title: this.infoTexts[0],
        text: this.infoTexts[3],
        confirmButtonText: this.infoTexts[4],
        cancelButtonText: this.infoTexts[5]
      }).then(result => {
        if (result.value) {
          this.rolesData.splice(this.rolesData.indexOf(role), 1);

          this.reindexRoles();
        }
      });
    }

    this.overlay.hide();
  }

  private reindexRoles() {
    const tmpData = cloneDeep(this.rolesData);

    tmpData.map((role, index) => {
      role.roleId = index;
      return role;
    });

    this.rolesData = cloneDeep(tmpData);

    this.editor.callAction({
      type: EditorActionType.REINDEX_TOKENS
    });
  }

  private getEmptyRolesNames() {
    return this.rolesData.filter(tokenData => (
      tokenData.tokens.length === tokenData.tokens.filter(token => !token.count).length)
    ).map(roleData => roleData.roleName);
  }

  private saveWithWarning(emptyRoles: string[], newTemplate: boolean) {
    let message = `${this.infoTexts[6]} <b>${emptyRoles[0]}</b> ${this.infoTexts[7]}.`;

    if (emptyRoles.length > 1) {
      message = `${this.infoTexts[8]} <b>${emptyRoles.join(', ')}</b> ${this.infoTexts[9]}.`;
    }

    this.popup.askWarning({
      title: this.infoTexts[10],
      html: message,
      confirmButtonText: this.infoTexts[11],
      cancelButtonText: this.infoTexts[12]
    }).then(result => {
      if (result.value) {
        this.editor.callAction({
          type: EditorActionType.SAVE_TEMPLATE,
          params: {
            newTemplate: newTemplate
          }
        });
      }
    });
  }

  showNewTokenInput(roleId: number) {
    this.newTokenRole = roleId;
    this.addNewToken = true;

    setTimeout(() => {
      const input = <HTMLElement>document.querySelector('#new-token-id');
      input.focus();
    }, 0);
  }

  closeNewTokenInput() {
    this.newTokenId = '';
    this.newTokenRole = -1;
    this.addNewToken = false;
  }

  showTokenOptions(tokenString: string) {
    this.tokenOption = tokenString;
    this.overlay.show({ type: OverlayType.MAIN });
  }

  tokenExistsInRole(role: RoleData, tokenId: string): boolean {
    const newTokenId = tokenId.replace(/\s+/g, '');

    return !!role.tokens.find(token => token.id === newTokenId);
  }

  addToken(role: RoleData, newTokenId: string) {
    if (!this.tokenExistsInRole(role, newTokenId)) {
      role.tokens.push({
        id: newTokenId,
        value: '',
        count: 0
      });

      this.closeNewTokenInput();
    }
  }

  canRemoveToken(role: RoleData, token: TokenData) {
    return token.count < 1;
  }

  removeToken(role: RoleData, token: TokenData) {
    this.overlay.hide();

    if (this.canRemoveToken(role, token)) {
      role.tokens = role.tokens.filter(searchToken => searchToken.id !== token.id);

      this.editor.callAction({ type: EditorActionType.UPDATE_TOKEN });
    } else {
      this.popup.info({
        titleText: this.infoTexts[13],
        html: `${this.infoTexts[14]}: "<b>${token.id}</b>"<br/>
           ${this.infoTexts[15]}.<br/>
           ${this.infoTexts[16]}.`,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        timer: null
      });
    }
  }

  saveTemplate(event: MouseEvent, newTemplate: boolean = false) {
    event.preventDefault();

    const emptyRoles = this.getEmptyRolesNames();

    if (emptyRoles.length) {
      this.saveWithWarning(emptyRoles, newTemplate);
    } else {
      this.editor.callAction({
        type: EditorActionType.SAVE_TEMPLATE,
        params: {
          newTemplate: newTemplate
        }
      });
    }
  }
}
