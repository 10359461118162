import { Component, OnInit } from '@angular/core';

import { AutModalContentComponent } from '../aut-modal/aut-modal.type';
import { AutModalService } from '../aut-modal/aut-modal.service';
import { Charset } from '../../../pages/address-book/address-book.type';

@Component({
  selector: 'app-import-csv-modal',
  templateUrl: './import-csv-modal.component.html',
  styleUrls: ['./import-csv-modal.component.scss']
})
export class ImportCsvModalComponent implements OnInit, AutModalContentComponent {

  data: any = {};

  Charset = Charset;

  constructor(
    private modal: AutModalService,
  ) { }

  ngOnInit() { }

  select(charset: Charset) {
    this.modal.close(charset);
  }

}
