import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { EditorAction, EditorActionType, ReceiverType, RoleData } from '../editor/editor.type';
import { CreateModalComponent } from './create-modal/create-modal.component';
import { EditorService } from '../editor/editor.service';
import { TranslationComponent } from '../../../core/components/translation/translation.component';
import { CreateStep, DisabledInfo, Receiver } from './create.type';
import { Item } from '../../../core/components/cygan-balls/cygan-balls.component';
import { DataStoreService } from '../../../core/services/data-store.service';
import { AutModalService } from '../../../core/components/aut-modal/aut-modal.service';
import { UserService } from '../../../core/components/header/user/user.service';
import { OverlayService } from '../../../core/components/overlay/overlay.service';
import { Base64Service } from '../../../core/services/base64.service';
import { StoreKey } from '../../../core/services/data-store.type';
import { RestrictionType, SubscriptionData } from '../../../core/components/subscriptions/subscriptions.type';
import { OverlayConfig, OverlayType } from '../../../core/components/overlay/overlay.type';
import { RouteUrl } from '../../../app.routes';
import { CreateService } from './create.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  animations: [
    trigger('sidePanel', [
      state('void', style({
        transform: 'translateX(100%)',
        background: '#fff',
        width: '!',
        height: '!'
      })),
      transition(':enter', [
        animate('450ms cubic-bezier(.35,0,.25,1)', style({
          transform: 'translateX(0)'
        }))
      ]),
      transition(':leave', [
        animate('450ms cubic-bezier(.35,0,.25,1)', style({
          transform: 'translateX(100%)'
        })),
      ])
    ]),
  ]
})
export class CreateComponent extends TranslationComponent implements OnInit, OnDestroy {

  documentData: any;
  roles: RoleData[];
  receivers: Receiver[];
  roleReceivers: Receiver[];
  userData: any;
  showAddressBook: boolean;
  receiverLabel: any;
  stepItems: Item[];
  disableButton: boolean;
  limitReached: boolean;
  disabledInfo: DisabledInfo;

  subscriptions: Subscription[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataStore: DataStoreService,
    private user: UserService,
    private modal: AutModalService,
    private editor: EditorService,
    private overlay: OverlayService,
    private base64: Base64Service,
    private create: CreateService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.clearSubscriptions();
    this.dataStore.removeData(StoreKey.CREATE);
    this.showAddressBook = false;
    this.disableButton = false;
    this.limitReached = false;
    this.disabledInfo = {
      signer: false
    };
    this.receivers = [];
    this.documentData = this.dataStore.getData(StoreKey.DOCUMENT_DATA);
    this.roles = this.documentData.roles;

    this.route.data.subscribe((data: {
      subscriptionData: SubscriptionData
    }) => {
      this.disableButton = this.limitReached =
        !!data.subscriptionData.restrictions.find(restriction => restriction === RestrictionType.DOCUMENT_SENDING);
    });

    this.roleReceivers = this.documentData.roles.map((role: RoleData) => {
      return {...{}, ...this.createReceiver(role), ...{ roleName: role.roleName }};
    });

    this.stepItems = [
      { label: 'Tworzenie', done: true },
      { label: 'Uzupełnianie', done: true },
      { label: 'Dodawanie odbiorców', active: true },
      { label: 'Wysyłanie' },
    ];

    this.receiverLabel = {
      'PERSON_SIGNER': 'Podpisujący dokument',
      'PERSON_VIEWER': 'Tylko prawo do wglądu',
    };

    this.userData = this.user.getUserAccount();

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.SHOW_ADDRESS_BOOK:
            this.showAddressBook = action.params;
            break;
          case EditorActionType.CLOSE_ADDRESS_BOOK:
            this.showAddressBook = false;
            break;
        }
      })
    );

    this.subscriptions.push(
      this.overlay.get().subscribe((overlay: OverlayConfig) => {
        switch (overlay.type) {
          case OverlayType.MAIN:
            this.showAddressBook = false;
            break;
        }
      })
    );

    this.dataStore.setData({
      progress: false,
      success: false
    });

    this.addSelfAsReceiver();
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  private createOptions(receiver: Receiver, label: string = 'Nowy odbiorca', buttonLabel: string = 'Dodaj', edit: boolean = false) {
    return {
      receiver: receiver,
      receivers: this.receivers,
      label: label,
      buttonLabel: buttonLabel,
      edit: edit
    };
  }

  private createReceiver(role: RoleData = null): Receiver {
    const receiver = {
      isUser: false,
      roleId: -1,
      firstName: '',
      lastName: '',
      email: '',
      receiverType: ReceiverType.PERSON_SIGNER
    };

    if (role) {
      receiver.roleId = role.roleId;

      role.tokens.forEach(token => {
        if (receiver.hasOwnProperty(token.id)) {
          receiver[token.id] = token.value;
        }
      });
    } else {
      receiver.roleId = new Set(this.roleReceivers.map((mapRole: Receiver) => mapRole.roleId)
        .concat(this.receivers.map((mapReceiver: Receiver) => mapReceiver.roleId))
      ).size;
    }

    return receiver;
  }

  private addOrReplace(data) {
    const index = this.receivers.findIndex(receiver => receiver.roleId === data.roleId);

    if (index !== -1) {
      this.receivers[index] = {...{}, ...data};
    } else {
      this.receivers.push(data);
    }

    this.checkRequireSigner();
  }

  private checkRequireSigner() {
    this.disableButton = this.receivers.filter(
      (receiver: Receiver) => !receiver.isUser && receiver.receiverType === ReceiverType.PERSON_SIGNER
    ).length === 0;

    this.disabledInfo.signer = this.disableButton;
  }

  isReceiver(roleId: number): boolean {
    return this.receivers.findIndex(receiver => {
      return receiver.roleId === roleId;
    }) !== -1;
  }

  addReceiver(receiver: Receiver = null) {
    const options = this.createOptions(receiver || this.createReceiver());

    this.modal.show(CreateModalComponent, options).then(data => {
      if (data) {
        this.addOrReplace(data);
      }
    });
  }

  addSelfAsReceiver() {
    const receiver = this.createReceiver();

    receiver.isUser = true;
    receiver.roleId = -1;
    receiver.firstName = this.userData.firstName;
    receiver.lastName = this.userData.lastName;
    receiver.email = this.userData.email;

    this.addOrReplace(receiver);
  }

  editReceiver(receiver: Receiver) {
    const options = this.createOptions(receiver, 'Edycja odbiorcy', 'Zapisz', true);

    this.modal.show(CreateModalComponent, options).then(data => {
      if (data) {
        this.addOrReplace(data);
      }
    });
  }

  removeReceiver(receiver: Receiver) {
    const index = this.receivers.findIndex(existsReceiver => existsReceiver.roleId === receiver.roleId);

    if (!receiver.isUser && index !== -1) {
      this.receivers.splice(index, 1);
    }

    this.checkRequireSigner();
  }

  sendDocument() {
    if (this.limitReached || !this.receivers.length || this.disableButton) {
      return;
    }

    this.disableButton = true;

    const documentToSend = this.create.prepareDocument(this.documentData, this.roles, this.receivers);

    this.dataStore.setData({
      step: CreateStep.PROGRESS,
      documentName: this.documentData.title,
      documentToSend,
      templateId: this.documentData.templateId,
      templateType: this.documentData.templateType,
      user: {
        name: this.userData.firstName,
        receiverType: this.receivers.find(receiver => receiver.isUser).receiverType
      },
      receivers: this.receivers.map((receiver: Receiver) => ({ receiverType: receiver.receiverType }))
    }, StoreKey.CREATE);

    this.router.navigate([RouteUrl.CREATE_PROGRESS]);
  }
}
