import { Component, ElementRef, HostListener, Injector, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';

import {
  DraggedToken,
  EditorAction,
  EditorActionType,
  Node,
  RoleData,
  TokenData,
  TokenParams,
  DataSource
} from './editor.type';
import { Template, TemplateMode, TemplateType } from '../templates/templates.type';
import { Key } from './editor.keys';
import { TemplatesRepository } from '../templates/templates.repository';
import { HeaderService } from '../../../core/components/header/header.service';
import { PopupService } from '../../../core/services/popup.service';
import { RouteUrl } from '../../../app.routes';
import { EnvironmentService } from '../../../core/services/environment.service';
import { EditorService } from './editor.service';
import { EditorDragService } from './editor-drag.service';
import { DataStoreService } from '../../../core/services/data-store.service';
import { StoreKey } from '../../../core/services/data-store.type';
import { TextTooltipData, TokenTooltipData, TooltipPositionType } from '../../../core/components/tooltip/tooltip.type';
import { TooltipService } from '../../../core/components/tooltip/tooltip.service';
import { TokenTooltipComponent } from '../../../core/components/tooltip/types/token-tooltip/token-tooltip.component';
import { OverlayService } from '../../../core/components/overlay/overlay.service';
import { GtmEventType, GtmService } from '../../../core/services/gtm.service';
import { Base64Service } from '../../../core/services/base64.service';
import { RestrictionType, SubscriptionData } from '../../../core/components/subscriptions/subscriptions.type';
import { TranslationComponent } from '../../../core/components/translation/translation.component';
import { AuthService } from '../../../core/auth/auth.service';
import { TimeToExpireType } from '../../../core/auth/auth.type';

declare global {
  interface Document {
    caretPositionFromPoint: any;
  }
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent extends TranslationComponent implements OnInit, OnDestroy {

  @ViewChild('page', { static: true }) pageRef: ElementRef;
  @ViewChild('templateTitleRef', { static: true }) templateTitleRef: ElementRef;

  originalData: any;
  template: Template;
  editorPage: HTMLElement;
  templateTitle: HTMLElement;
  caretPosition: Range;
  tokensTemplate: HTMLElement[];
  rolesData: RoleData[];
  originalRolesData: RoleData[];
  activeTemplateId: string;
  draggedToken: DraggedToken;
  templateMode: string;
  templateType: TemplateType;
  canSaveTemplate: boolean;
  templateTitleText: string;
  holdTooltip: boolean;

  infoTexts: string[];
  originTexts: string[];

  subscriptions: Subscription[];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private repository: TemplatesRepository,
    private env: EnvironmentService,
    private mainHeader: HeaderService,
    private dataStore: DataStoreService,
    private popup: PopupService,
    private editor: EditorService,
    private dragService: EditorDragService,
    private tooltip: TooltipService,
    private overlay: OverlayService,
    private gtm: GtmService,
    private base64: Base64Service,
    private authService: AuthService,
    private injector: Injector
  ) {
    super(injector);

    this.originTexts = ['Kliknij aby uzupełnić pole'];

    this.translationService.watchTranslation().subscribe(translations => {
      const currentLanguage = this.translationService.getLanguage();
      this.infoTexts = this.originTexts.map(originText => {
        return translations[currentLanguage][originText] || originText;
      });
    });
  }

  ngOnInit() {
    this.clearSubscriptions();
    this.holdTooltip = false;
    this.canSaveTemplate = false;

    this.route.data.subscribe((data: {
      template: Template,
      templateType: TemplateType,
      templateMode: TemplateMode,
      templateNew: boolean
      subscriptionData: SubscriptionData
    }) => {
      this.dataStore.setData({
        subscriptionLimitReached: !!data.subscriptionData.restrictions.find(restriction => restriction === RestrictionType.DOCUMENT_SENDING)
      }, StoreKey.EDITOR);

      this.originalData = cloneDeep(data);
      this.originalRolesData = [];

      const editorData = this.dataStore.getData(StoreKey.EDITOR);

      if (!editorData || (editorData && !editorData['templateMode'])) {
        this.dataStore.setData({
            templateMode: data.templateMode,
            templateType: data.templateType,
            templateNew: data.templateNew
          },
          StoreKey.EDITOR
        );
      }

      this.templateType = data.templateType;
      this.template = data.template;

      this.rolesData = [];
      this.editorPage = this.pageRef.nativeElement;

      this.templateTitle = this.templateTitleRef.nativeElement;
      this.templateTitleText = '';

      if (data.template) {
        this.activeTemplateId = data.template.id;
        this.rolesData = data.template.draft.roles;
        this.editorPage.innerHTML = data.template.draft.content;
        this.templateTitle.innerHTML = this.originalData.template ? this.originalData.template.draft.title : '';
        this.templateTitleText = this.templateTitle.innerHTML;

        this.originalRolesData = cloneDeep(data.template.draft.roles);

        this.getTokensFromTemplate();
        this.mapDataTokens();

        this.canSaveTemplate = true;
      }

      this.refreshView();
    });

    this.dataStore.setData({
      canSaveTemplate: this.canSaveTemplate
    }, StoreKey.EDITOR);

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        this.handleEditorAction(action);
      })
    );

    this.subscriptions.push(
      this.dragService.getDragToken().subscribe((draggedToken: any) => {
        this.draggedToken = draggedToken;
      })
    );

    this.subscriptions.push(
      this.authService.getTimeToExpire().subscribe((timeToExpire: TimeToExpireType) => {
        this.updateToolbar();
      })
    );
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  private handleEditorAction(action: EditorAction) {
    switch (action.type) {
      case EditorActionType.CHANGE_TOKENS_MODE:
        this.changeTokensMode(action.params);
        break;
      case EditorActionType.REINDEX_TOKENS:
        this.reindexTokensTemplate();
        break;
      case EditorActionType.UPDATE_TOKEN:
        this.handleEditorInput();
        break;
      case EditorActionType.INSERT_TOKEN:
        this.handleTokenInsert(action.params);
        break;
      case EditorActionType.CHECK_TEMPLATE:
        this.checkTemplateSettings();
        break;
      case EditorActionType.SAVE_TEMPLATE:
        this.saveTemplate(action.params);
        break;
      case EditorActionType.PREPARE_TO_SEND:
        this.prepareToSend();
        break;
      case EditorActionType.UPDATE_CARET_POSITION:
        this.caretPosition = action.params;
        break;
      case EditorActionType.CLOSE_ADD_ROLE_PANEL:
        if (action.params) {
          this.rolesData = cloneDeep(action.params);
        }
        break;
    }
  }

  private getTokensFromTemplate() {
    const tokensFromPage = Array.from(this.editorPage.querySelectorAll('token') as NodeListOf<HTMLElement>);
    const tokensFromTitle = Array.from(this.templateTitle.querySelectorAll('token') as NodeListOf<HTMLElement>);

    this.tokensTemplate = [...[], ...tokensFromPage, ...tokensFromTitle];
  }

  private reindexTokensTemplate() {
    this.getTokensFromTemplate();

    const roleMap = {};

    let roleIndex = 0;
    this.originalRolesData.forEach(role => {
      if (this.rolesData.find(dataRole => dataRole.roleName === role.roleName)) {
        roleMap[role.roleId] = roleIndex;
        roleIndex++;
      }
    });

    this.tokensTemplate.forEach(token => {
      const hasValue = token.classList.contains('has-value');
      const roleId = token.getAttribute('data-role');

      token.setAttribute('data-role', `${roleMap[roleId]}`);
      token.className = '';
      token.classList.add(`role-${roleMap[roleId]}`);

      if (hasValue) {
        token.classList.add('has-value');
      }
    });

    this.originalRolesData = cloneDeep(this.rolesData);
  }

  private changeTokensMode(contentEditable: boolean) {
    this.tokensTemplate = Array.from(this.editorPage.querySelectorAll('token') as NodeListOf<HTMLElement>);
    this.tokensTemplate.forEach((token, index) => {
      (<HTMLElement>this.tokensTemplate[index]).contentEditable = `${contentEditable}`;
    });
  }

  private mapDataTokens() {
    this.rolesData.map(role => {
      const tokensDataSource = [];

      if (role.dataSource.type === DataSource.INLINE) {
        for (const token in role.dataSource.tokens) {
          if (role.dataSource.tokens.hasOwnProperty(token)) {
            tokensDataSource.push({
              id: token,
              value: role.dataSource.tokens[token],
            });
          }
        }
      }

      const tokensTemplateData = this.tokensTemplate.filter(token => +token.getAttribute('data-role') === role.roleId)
        .map(token => {
          const tokenId = token.getAttribute('data-id');

          return {
            id: tokenId,
            value: role.dataSource.tokens[tokenId] || ''
          };
        }).filter((dataToken, index, self) => {
          return index === self.map(token => token.id).indexOf(dataToken.id);
        });

      role['tokens'] = [...[], ...tokensDataSource, ...tokensTemplateData].filter((dataToken, index, self) => {
        return index === self.map(token => token.id).indexOf(dataToken.id);
      });

      return role;
    });
  }

  private refreshView() {
    this.getTokensFromTemplate();

    this.rolesData.map(tokenData => {
      tokenData.tokens.map(token => {
        token.count = 0;

        return token;
      });

      return tokenData;
    });

    this.tokensTemplate.map((tokenElement, index) => {
      const id = tokenElement.getAttribute('data-id');
      const roleId = +tokenElement.getAttribute('data-role');

      const roleData = this.rolesData.find(role => role.roleId === roleId);
      if (!roleData) {
        return;
      }

      const data = roleData.tokens.find(token => token.id === id);
      data.count = data && data.count ? data.count + 1 : 1;

      tokenElement.contentEditable = 'false';
      tokenElement.draggable = true;
      tokenElement.innerHTML = data && data.value ? data.value : `[${id}]`;
      tokenElement.classList.add(`role-${roleId}`);
      tokenElement.setAttribute('data-uniq', `${index}`);
      data && data.value ? tokenElement.classList.add('has-value') : tokenElement.classList.remove('has-value');

      tokenElement.removeEventListener('dragstart', tokenElement['dragStart'], false);
      tokenElement.removeEventListener('dragend', tokenElement['dragEnd'], false);
      tokenElement.removeEventListener('click', tokenElement['click'], false);
      tokenElement.removeEventListener('mouseenter', tokenElement['mouseEnter'], false);
      tokenElement.removeEventListener('mouseleave', tokenElement['mouseLeave'], false);

      tokenElement.addEventListener('dragstart', tokenElement['dragStart'] = this.handleTokenDragStart.bind(this), false);
      tokenElement.addEventListener('dragend', tokenElement['dragEnd'] = this.handleTokenDragEnd.bind(this), false);
      tokenElement.addEventListener('click', tokenElement['click'] = this.handleTokenClick.bind(this), false);
      tokenElement.addEventListener('mouseenter', tokenElement['mouseEnter'] = this.handleTokenHover.bind(this), false);
      tokenElement.addEventListener('mouseleave', tokenElement['mouseLeave'] = this.handleTokenLeave.bind(this), false);

      return tokenElement;
    });

    this.editor.callAction({ type: EditorActionType.CHECK_TOKENS_VALUES });
  }

  private caretRangeFromPoint(event: any): Range {
    let range = null;

    if (document.caretRangeFromPoint) {
      range = document.caretRangeFromPoint(event.pageX, event.pageY);
    } else if (document.caretPositionFromPoint) {
      range = document.createRange();
      range.setStart(event.rangeParent, event.rangeOffset);
    } else {
      console.error('Your browser is bad ;(');
    }

    return range;
  }

  private getCaretPosition(element: HTMLElement, event: any = null): Range {
    const range = event ?
      this.caretRangeFromPoint(event) :
      window.getSelection().getRangeAt(0);

    const caretRange = range.cloneRange();
    caretRange.selectNodeContents(element);
    caretRange.setEnd(range.endContainer, range.endOffset);
    caretRange.collapse(false);

    return caretRange;
  }

  private removeToken(tokenParams: TokenParams) {
    if (tokenParams.uniq) {
      const tokenNode = document.querySelector(`token[data-uniq='${tokenParams.uniq}']`);

      tokenNode.remove();
    } else {
      const allTokenNodes = this.editorPage.querySelectorAll(
        `token[data-id='${tokenParams.id}'][data-role='${tokenParams.roleId}']`
      );

      for (let i = 0; i < allTokenNodes.length; i++) {
        allTokenNodes[i].remove();
      }
    }
  }

  private handleBackspace(event: KeyboardEvent) {
    const range = this.getCaretPosition(this.editorPage);
    const container = range.endContainer;
    const offset = range.endOffset;

    switch (container.nodeName) {
      case Node.DIV:
      case Node.TD:
      case Node.LI:
        if (container.childNodes[offset - 1]) {
          const child = container.childNodes[offset - 1];
          const prevChild = child.previousSibling;

          switch (child.nodeName) {
            case Node.TEXT:
              if (child.nodeValue === '') {
                child.remove();
                event.preventDefault();
                if (prevChild && prevChild.nodeName === Node.TOKEN) {
                  container.removeChild(prevChild);
                }
              }
              break;
            case Node.TOKEN:
              event.preventDefault();
              child.remove();
              break;
          }
        }
        break;
    }
  }

  private handleLeftArrow() {
    let range = this.getCaretPosition(this.editorPage);
    let prevNode = null;

    const container = range.endContainer;
    const offset = range.startOffset;
    const selection = window.getSelection();

    if (container.nodeName === Node.TEXT && offset === 0) {
      prevNode = container.previousSibling;
    }

    if (container.nodeName === Node.DIV && container.childNodes[offset - 1]) {
      prevNode = container.childNodes[offset - 1].previousSibling;
    }

    if (prevNode) {
      while (prevNode.nodeName === Node.TEXT && prevNode.nodeValue === '' && prevNode.previousSibling) {
        prevNode = prevNode.previousSibling;
      }

      while (prevNode.nodeName !== Node.TEXT && prevNode.previousSibling) {
        prevNode = prevNode.previousSibling;
      }

      if (
        (prevNode.previousSibling && prevNode.previousSibling.nodeName === Node.TEXT && prevNode.previousSibling.nodeValue !== '') ||
        (prevNode.nodeName === Node.TEXT && prevNode.nodeValue !== '')
      ) {
        prevNode = prevNode.nextSibling;
      }

      range = document.createRange();
      range.setStartAfter(prevNode);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);

      this.caretPosition = range;
    }
  }

  private handleRightArrow() {
    let range = this.getCaretPosition(this.editorPage);
    let nextNode = null;

    const container = range.endContainer;
    const offset = range.startOffset;
    const selection = window.getSelection();

    if (container.nodeName === Node.TEXT && offset === container.nodeValue.length) {
      nextNode = container.nextSibling;
    }

    if (container.nodeName === Node.DIV && container.childNodes[offset - 1]) {
      nextNode = container.childNodes[offset - 1].nextSibling;
    }

    if (nextNode) {
      while (nextNode.nodeName === Node.TEXT && nextNode.nodeValue === '' && nextNode.nextSibling) {
        nextNode = nextNode.nextSibling;
      }

      while (nextNode.nodeName !== Node.TEXT && nextNode.nextSibling) {
        nextNode = nextNode.nextSibling;
      }

      if (nextNode.previousSibling && nextNode.previousSibling.nodeName === Node.TOKEN) {
        if (
          (nextNode.nextSibling && nextNode.nextSibling.nodeName === Node.TEXT && nextNode.nextSibling.nodeValue !== '') ||
          (nextNode.nodeName === Node.TEXT && nextNode.nodeValue !== '')
        ) {
          nextNode = nextNode.previousSibling;
        }
      }

      range = document.createRange();
      range.setStartBefore(nextNode);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);

      this.caretPosition = range;
    }
  }

  private handleTokenClick(event: MouseEvent) {
    this.tooltip.hide();

    this.holdTooltip = true;

    const element = (<Element>event.target);

    const roleData = this.rolesData.find((role: RoleData) => {
      return role.roleId === +element.getAttribute('data-role');
    });

    if (roleData) {
      const tokenData = roleData.tokens.find((token: TokenData) => {
        return token.id === element.getAttribute('data-id');
      });

      if (tokenData) {
        const { top, left, width } = element.getBoundingClientRect();
        const tooltipData: TokenTooltipData = {
            position: {
              type: TooltipPositionType.TOP,
              top: top,
              left: left + (width / 2)
            },
            token: tokenData
        };

        this.tooltip.show(this.viewContainerRef, tooltipData, TokenTooltipComponent);
      }
    }
  }

  private handleTokenHover(event: MouseEvent) {
    this.holdTooltip = false;
    this.tooltip.hide();

    const element = (<Element>event.target);
    const { top, left, width } = element.getBoundingClientRect();

    const tooltipData: TextTooltipData  = {
      position: {
        type: TooltipPositionType.TOP,
          top: top,
          left: left + (width / 2)
      },
      delay: '1000ms',
      text: this.infoTexts[0]
    };

    this.tooltip.show(this.viewContainerRef, tooltipData);
  }

  private handleTokenLeave(event: MouseEvent) {
    if (!this.holdTooltip) {
      this.tooltip.hide();
    }
  }

  checkTemplateSettings() {
    this.templateTitleText = this.templateTitle.innerText.trim();

    this.canSaveTemplate = this.templateTitleText.length > 0 &&
      this.editorPage.innerText.trim().length > 0;

    this.dataStore.setData({ canSaveTemplate: this.canSaveTemplate }, StoreKey.EDITOR);
  }

  disableEnter(event: KeyboardEvent) {
    event.preventDefault();

    return false;
  }

  disableShortcuts(event: KeyboardEvent) {
    if (event.ctrlKey) {
      event.preventDefault();
      return false;
    }
  }

  disablePaste(event) {
    event.preventDefault();

    return false;
  }

  handleSettingsFocus(event) {
    this.editor.callAction({
      type: EditorActionType.DISABLE_TOOLBAR
    });
  }

  handleEditorClick(event) {
    this.editor.callAction({
      type: EditorActionType.ENABLE_TOOLBAR
    });

    this.changeTokensMode(false);
    this.caretPosition = this.getCaretPosition(this.editorPage, event);

    if (this.caretPosition.endContainer.nodeName === Node.TOKEN) {
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(this.editorPage);
      range.setStartAfter(this.caretPosition.endContainer);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);

      this.caretPosition = range;
    }
  }

  handleEditorPaste(event: ClipboardEvent) {
    event.preventDefault();

    const data = event.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, data);
  }

  handleEditorInput() {
    this.checkTemplateSettings();
    this.refreshView();

    return false;
  }

  handleEditorKeyUp(event: KeyboardEvent) {
    this.caretPosition = this.getCaretPosition(this.editorPage);
  }

  handleEditorKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case Key.BACKSPACE:
        this.handleBackspace(event);
        break;
      case Key.ARROW_LEFT:
        this.handleLeftArrow();
        break;
      case Key.ARROW_RIGHT:
        this.handleRightArrow();
        break;
    }

    this.refreshView();
  }

  @HostListener('window:resize', ['$event'])
  handleWindowResize($event) {
    const toolbar = <HTMLElement>document.querySelector('.toolbar');

    if ((window.pageXOffset || document.documentElement.scrollLeft) <= 0) {
      toolbar.style.left = 'auto';
    }

  }

  @HostListener('window:scroll', ['$event'])
  handleWindowScroll($event) {
    const toolbar = <HTMLElement>document.querySelector('.toolbar');
    const sidebar = <HTMLElement>document.querySelector('.sidebar');

    if (toolbar.style.position === 'fixed') {
      toolbar.style.left =
        sidebar.clientWidth - (window.pageXOffset || document.documentElement.scrollLeft) + 'px';
    }
  }

  handleEditorScroll(event?: Event) {
    this.tooltip.hide();
    this.updateToolbar();
  }

  updateToolbar() {
    const page = <HTMLElement>document.querySelector('.page');
    const toolbar = <HTMLElement>document.querySelector('.toolbar');
    const sidebar = <HTMLElement>document.querySelector('.sidebar');
    const header = <HTMLElement>document.querySelector('header.main');
    const autologoutBar = <HTMLElement>document.querySelector('.autologout-bar');

    const topMargin = (header ? header.clientHeight : 0) + (autologoutBar ? autologoutBar.clientHeight : 0);

    if (page.getBoundingClientRect().top - topMargin - toolbar.clientHeight <= 0) {
      toolbar.style.position = 'fixed';
      toolbar.style.top = `${topMargin}px`;

      if ((window.pageXOffset || document.documentElement.scrollLeft) > 0) {
        toolbar.style.left =
          sidebar.clientWidth - (window.pageXOffset || document.documentElement.scrollLeft) + 'px';
      } else {
        toolbar.style.left = 'auto';
      }
    } else {
      toolbar.style.position = 'static';
    }
  }

  handleTokenDragStart(event: DragEvent, roleId, tokenId) {
    const element = (<HTMLElement>event.target);
    const styles = window.getComputedStyle(element);

    // Required by Firefox
    event.dataTransfer.setData('text/plain', '');

    tokenId = tokenId || element.getAttribute('data-id');
    roleId = roleId || +element.getAttribute('data-role');

    element.classList.add('dragged');
    event.dataTransfer.setDragImage(
      this.dragService.createDragImage(tokenId, styles), 20, -10
    );

    const draggedToken = {
      id: tokenId,
      roleId: roleId,
      uniq: element.getAttribute('data-uniq') || '',
      position: {
        x: 0,
        y: 0
      }
    };

    this.dragService.setDragToken(draggedToken);
  }

  handleTokenDragEnd(event: DragEvent) {
    const element = (<HTMLElement>event.target);
    element.classList.remove('dragged');
  }

  handleEditorDragOver(event: DragEvent) {
    // If chrome/webkit browser disable dragover event - drop event not working with dragover
    if (document.caretRangeFromPoint) {
      event.preventDefault();
    }

    if (!this.draggedToken) {
      event.preventDefault();
      return false;
    }

    const range = this.caretRangeFromPoint(event);

    this.dragService.setDragToken(this.draggedToken);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  handleEditorDrop(event: DragEvent) {
    const range = this.caretRangeFromPoint(event);
    const textNode = range.startContainer;

    document.getElementById('drag-image').remove();

    if (textNode && textNode.parentNode.nodeName !== Node.TOKEN) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      const inserted = this.editor.insertToken(
        range, { ...{}, ...{ id: this.draggedToken.id, roleId: this.draggedToken.roleId } }
      );

      this.caretPosition = range;

      if (inserted && this.draggedToken.uniq) {
        this.removeToken({...{ uniq: this.draggedToken.uniq }});
      }
    }

    this.refreshView();
  }

  handleTokenInsert(tokenParams: TokenParams) {
    this.editorPage.focus();

    const range = this.caretPosition || this.getCaretPosition(this.editorPage);
    const selection = window.getSelection();

    if (!this.caretPosition) {
      range.selectNodeContents(this.editorPage);
    }

    this.editor.insertToken(range, {...{ id: tokenParams.id, roleId: tokenParams.roleId }});

    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);

    this.refreshView();
  }

  saveTemplate(params: any) {
    const saveTemplate = {
      id: params.newTemplate ? null : this.activeTemplateId,
      clientId: this.activeTemplateId,
      name: this.templateTitle.innerText || this.template.name,
      draft: {
        title: this.base64.encode(this.templateTitle.innerHTML),
        message: this.base64.encode(''),
        content: this.base64.encode(this.editorPage.innerHTML),
        roles: this.rolesData.map((role: RoleData) => {
          // TMP Hack
          role.dataSource.type = DataSource.INLINE;

          let dataSource =  {};

          if (role.dataSource.type === DataSource.INLINE) {
            const tokens = {};

            role.tokens.forEach(token => {
              tokens[token.id] = token.value;
            });

            dataSource = {
              type: DataSource.INLINE,
              tokens: tokens
            };
          }

          return {
            roleId: role.roleId,
            roleName: role.roleName,
            dataSource: dataSource,
          };
        }),
        participants: [],
        autoSign: false
      },
    };

    if (params.newTemplate || (this.templateMode && this.templateMode !== 'edit') || this.templateType === TemplateType.AUTENTI) {
      this.repository.createTemplate(saveTemplate).subscribe((success: any) => {
          this.popup.success({ text: this.translationService.translate('Szablon utworzony') } );

        this.gtm.sendMsg({
          event: 'submitForm',
          description: 'Save as new template',
          type: GtmEventType.SUCCESS,
          payload: {
            templateId: this.activeTemplateId,
            newTemplateId: success.id
          }
        });

          this.router.navigate([`${RouteUrl.CUSTOM_TEMPLATE}/${success.id}`]);
        }, error => {
          this.popup.error({ text: this.translationService.translate('Nie udało się zapisać szablonu ')});
        }
      );
    } else {
      this.repository.updateTemplate(saveTemplate).subscribe(success => {
          this.popup.success({ text: this.translationService.translate('Szablon zapisany') });

        this.gtm.sendMsg({
          event: 'submitForm',
          description: 'Save template',
          type: GtmEventType.SUCCESS,
          payload: {
            templateId: this.activeTemplateId,
          }
        });

        }, error => {
          this.popup.error({ text: this.translationService.translate('Nie udało się zapisać szablonu ') });
        }
      );
    }
  }

  prepareToSend() {
    const documentData = {
      templateId: this.template ? this.template.id : 'New document - not saved',
      templateType: this.templateType,
      title: this.templateTitle.innerHTML,
      message: '',
      content: this.editorPage.innerHTML,
      roles: this.rolesData
    };

    this.dataStore.setData(documentData, StoreKey.DOCUMENT_DATA);

    this.gtm.sendMsg({
      event: 'submitForm',
      description: 'Prepare to send',
      type: GtmEventType.SUCCESS,
      payload: documentData
    });

    this.router.navigate([RouteUrl.CREATE]);
  }

}
