<div class="scroll-page summary">
  <div class="content-box">
    <div class="content-box-content">
      <div class="summary-content">
        <div class="summary-info">
          <div class="header">
            <i class="icon-accept"></i> {{ infoHeader | translate: currentLanguageCode | async }}
          </div>
          <div class="message">
            {{ infoMessage | translate: currentLanguageCode | async }}
          </div>
        </div>

        <div class="box">
          <div class="header">
            {{ createData.user.name }}, <span appTranslate>Twoja rola w dokumencie</span>
          </div>
          <div class="message">
            {{ senderMessage | translate: currentLanguageCode | async }}
          </div>
        </div>

        <div class="box">
          <div class="header" appTranslate>
            Odbiorcy dokumentu
          </div>
          <div class="message">
            {{ receiversMessage | translate: currentLanguageCode | async }}
          </div>
          <ul class="options">
            <li>
              <i class="icon-document-v3"></i>
              <a href="{{ createData.documentUrl }}">
                <span appTranslate>Zobacz szczegóły dokumentu</span> "{{ createData.documentName | strip }}"
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="separator"></div>

      <div class="summary-options">
        <div class="box">
          <div class="header" appTranslate>
            Co możesz jeszcze zrobić?
          </div>
        </div>

        <div class="box">
          <div class="header small" appTranslate>Wysłać nowy dokument</div>
          <ul class="options">
            <li>
              <i class="icon-create-new-document"></i>
              <a routerLink="/automation/templates/custom/new" appTranslate>Przygotuj nowy dokument do wysłania</a>
            </li>
          </ul>
        </div>

        <div class="joke">
          <i class="icon-confetti"></i>{{ currentJoke | translate: currentLanguageCode | async }}
        </div>
      </div>

    </div>
  </div>
</div>
