import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { interval, Observable } from 'rxjs';
import { flatMap, map, takeLast, takeWhile } from 'rxjs/operators';

import { EnvironmentService } from '../../../core/services/environment.service';
import { RedirectResponse, SentDocuments } from './create.type';

@Injectable({
  providedIn: 'root'
})
export class CreateRepository {

  apiUrl: string;

  constructor(private http: HttpClient, private env: EnvironmentService) {
    this.apiUrl = `${this.env.get('apiUrl')}/${this.env.get('apiPrefix')}`;
  }

  sendDocument(data: any): Observable<string> {
    const url = `${this.apiUrl}/document-templates/create`;
    
    return this.http.post(url, data, { observe : 'response' }).pipe(
      map(response => {
        const createProcessId = response.headers.get('Location').split('/create/')[1];
        if (!createProcessId) {
          throw Error();
        }

        return createProcessId;
      })
    );
  }

  getDocumentStatus(createProcessId: string): Observable<SentDocuments> {
    const url = `${this.apiUrl}/document-templates/create/${createProcessId}`;

    return interval(2000).pipe(
      flatMap(() =>  this.http.get(url)),
      takeWhile((response: SentDocuments) => {
        if (response.failedDocuments.length) {
          throw new Error();
        }
        return response.numberOfDocuments !== response.sentDocuments.length;
      }, true),
      takeLast(1),
      flatMap((response: SentDocuments) => {
        return this.getDocumentUrl(response.sentDocuments[0]).pipe(
          map((redirectResponse: RedirectResponse) => {
            return {...response, ...{ redirectUrl: redirectResponse.redirect }};
          })
        );
      })
    );
  }

  getDocumentUrl(documentId: string) {
    const url = `${this.apiUrl}/document-templates/documents/${documentId}/redirect`;

    return this.http.get(url);
  }
}
