import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditorDragService {

  private dragTokenSubject: Subject<any>;

  constructor() {
    this.dragTokenSubject = new Subject<any>();
  }

  getDragToken(): Observable<any> {
    return this.dragTokenSubject.asObservable();
  }

  setDragToken(draggedToken: any) {
    this.dragTokenSubject.next(draggedToken);
  }

  createDragImage(label: string, styles: CSSStyleDeclaration) {
    const dragImage = document.createElement('div');

    dragImage.innerText = `[${label}]`;
    dragImage.setAttribute(
      'style',
      `position: absolute; top: 0; left: 0; border-radius: 4px; padding: 2px 6px;
       background: ${styles.backgroundColor}; font-size: 0.9em; text-align: center;
       color: ${styles.color}; font-weight: bold; z-index: -1;`);
    dragImage.setAttribute('id', 'drag-image');
    document.body.appendChild(dragImage);

    return dragImage;
  }

}
