import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TemplatesRepository } from './templates.repository';
import { Template } from './templates.type';
import { Base64Service } from '../../../core/services/base64.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateResolver implements Resolve<Observable<Template>> {
  constructor(
    private repository: TemplatesRepository,
    private base64: Base64Service
) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Template> {
    const templateId = route.paramMap.get('templateId');
    const templateType = route.data.templateType || '';

    return this.repository.getTemplate(templateId, templateType).pipe(
      map((template: Template) => {
        template.draft.title = this.base64.decode(template.draft.title);
        template.draft.message = this.base64.decode(template.draft.message);
        template.draft.content = this.base64.decode(template.draft.content);

        return template;
      })
    );
  }
}
