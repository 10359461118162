<div class="aut-modal" [@modal]="showModal">
  <div class="aut-modal-area" (click)="closeModal()"></div>
  <div class="aut-modal-wrapper">
    <div class="aut-modal-overflow">
      <div class="aut-modal-box">
        <header>
          <fa-icon icon="times" (click)="closeModal()"></fa-icon>
        </header>
        <ng-template appAutModalHost></ng-template>
      </div>
    </div>
  </div>
</div>
