import { Component, Injector, Input, OnInit } from '@angular/core';

import { AddressBookRepository } from '../../../../address-book/address-book.repository';
import { EditorActionType, RoleData } from '../../editor.type';
import { Address } from '../../../../address-book/address-book.type';
import { UserService } from '../../../../../core/components/header/user/user.service';
import { Storage } from '../../../../../core/auth/auth.type';
import { FrontPagination, FrontPaginationEvent } from '../../../../../core/components/front-pagination/front-pagination.component';
import { DataStoreService } from '../../../../../core/services/data-store.service';
import { EditorService } from '../../editor.service';
import { AddressBookService } from '../../../../address-book/address-book.service';
import { StoreKey } from '../../../../../core/services/data-store.type';
import { TranslationComponent } from '../../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-address-book-panel',
  templateUrl: './address-book-panel.component.html',
  styleUrls: ['./address-book-panel.component.scss']
})
export class AddressBookPanelComponent extends TranslationComponent implements OnInit, FrontPagination {

  @Input() options: any;

  addresses: any;
  filteredAddresses: any[];
  pageLimit: number;
  languages: string[];
  selfAddress: any;
  currentAddressIndex: number;
  customKeyOrder: string[];
  defaultOptions: any;

  activeRole: RoleData;
  showSpinner: boolean;

  constructor(
    private repository: AddressBookRepository,
    private user: UserService,
    private dataStore: DataStoreService,
    private editor: EditorService,
    private addressService: AddressBookService,
    private injector: Injector
  ) {
    super(injector);

    this.defaultOptions = {
      self: true,
      keyOrder: ['email', 'firstName', 'lastName'],
      tokensFilter: []
    };

    this.pageLimit = 10;
  }

  ngOnInit() {
    this.options = {...{}, ...this.defaultOptions, ...this.options};

    this.showSpinner = true;
    this.currentAddressIndex = -1;
    this.filteredAddresses = [];
    this.languages = JSON.parse(sessionStorage.getItem(Storage.LANGUAGES));
    this.customKeyOrder = this.options.keyOrder;

    if (this.options.self) {
      this.selfAddress = this.user.getUserAccount();
      this.selfAddress = this.addressService.mapAddress(
        { tokens: this.selfAddress },
        ['useV1', 'v1clientId', 'v1email', 'v1secret', 'id', 'login'],
        true
      );
    }

    const data = this.dataStore.getData(StoreKey.ADDRESS_BOOK_PANEL);
    let { tokensFilter } = this.options;

    if (data && !tokensFilter.length) {
      tokensFilter = data.activeRole.tokens.map(token => {
        return token.id;
      });
    }

    this.repository.getAddresses().subscribe((addressesPage: any) => {
      this.addresses = this.addressService.mapAddresses(addressesPage.content, tokensFilter);
      this.filteredAddresses = this.addresses.slice(0, this.pageLimit);
      this.showSpinner = false;
    });
  }

  changePage(event: FrontPaginationEvent) {
    this.filteredAddresses = this.addresses.slice(event.from, event.to);
  }

  closeBook(data) {
    this.editor.callAction({
      type: EditorActionType.CLOSE_ADDRESS_BOOK,
      params: data
    });
  }
}
