<div class="csv-errors-modal">
  <header appTranslate>
    Błąd importu danych
  </header>
  <div class="csv-errors-content">
    <ul>
      <li class="list-header">
        <div class="line" *ngIf="data[0].line" appTranslate>Numer wiersza</div>
        <div class="message"appTranslate>Komunikat błędu</div>
      </li>
      <li *ngFor="let row of data">
        <div class="line" *ngIf="row.line">{{ row.line }}</div>
        <div class="message">
          <ng-container *ngTemplateOutlet="row.line ? line : noline"></ng-container>
          <ng-template #line>
            <div>
              <span appTranslate>Niepoprawna wartość pola</span><span class="message-field">({{ row.data }})</span>
            </div>
          </ng-template>
          <ng-template #noline>
            <div>
              <span>{{ row.message | translate: currentLanguageCode | async }}</span>
            </div>
          </ng-template>
        </div>
      </li>
    </ul>
  </div>
  <footer>
    <div class="info" appTranslate>Popraw i spróbuj ponownie</div>
    <div class="buttons">
      <button class="button primary medium" (click)="close()">
        <span>OK</span>
      </button>
    </div>
  </footer>
</div>
