import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { transition, trigger, animate, style, state } from '@angular/animations';

import { SchemaItemValidator } from '../../../app.validators';
import { AddressBookRepository } from '../address-book.repository';
import { PopupService } from '../../../core/services/popup.service';

@Component({
  selector: 'app-address-schema',
  templateUrl: './address-schema.component.html',
  styleUrls: ['./address-schema.component.scss'],
  animations: [
    trigger('slide', [
      state('false, void', style({
        height: 0,
        marginTop: 0,
        paddingTop: 0,
        marginBottom: 0,
        paddingBottom: 0,
        overflow: 'hidden'
      })),
      transition(':enter', [
        animate('250ms cubic-bezier(.35,0,.25,1)', style({
          height: '!',
          marginTop: '!',
          paddingTop: '!',
          marginBottom: '!',
          paddingBottom: '!',
          overflow: 'visible',
        })),
      ]),
      transition(':leave', [
        animate('250ms cubic-bezier(.35,0,.25,1)'),
      ]),
    ])
  ]
})
export class AddressSchemaComponent implements OnInit {

  addressSchema: string[];
  schemaForm: FormGroup;
  hasItemAlready: boolean;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private repository: AddressBookRepository,
    private popup: PopupService
  ) { }

  ngOnInit() {
    this.hasItemAlready = false;

    this.route.data.subscribe((data: {
      addressSchema: string[]
    }) => {
      this.addressSchema = [...[], ...data.addressSchema];

      this.schemaForm = this.formBuilder.group({
        schemaItem: ['', [Validators.required, SchemaItemValidator()]]
      });

    });
  }

  checkIfExists(itemName: string) {
    this.hasItemAlready = this.addressSchema.includes(itemName);
  }

  addItemSchema() {
    const itemSchema =  this.schemaForm.get('schemaItem').value;

    this.repository.addItemToAddressSchema(itemSchema).subscribe(success => {
      this.popup.success();
      this.addressSchema.push(itemSchema);
      this.schemaForm.get('schemaItem').setValue('');
    }, error => {
      this.popup.error();
    });
  }

}
