import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Template, TemplateList, TemplateType } from './templates.type';
import { EnvironmentService } from '../../../core/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesRepository {

  apiUrl: string;

  constructor(private http: HttpClient, private env: EnvironmentService) {
    this.apiUrl = `${this.env.get('apiUrl')}/${this.env.get('apiPrefix')}`;
  }

  getTemplate(templateId: string, templateType: TemplateType = TemplateType.CUSTOM): Observable<Template> {
    const prefix = templateType === TemplateType.AUTENTI ? '/autenti' : '';
    const url = `${this.apiUrl}/document-templates${prefix}/templates/${templateId}`;

    return this.http.get<Template>(url);
  }

  getTemplatesList(templateType: TemplateType = TemplateType.CUSTOM): Observable<TemplateList[]> {
    const prefix = templateType === TemplateType.AUTENTI ? '/autenti' : '';
    const url = `${this.apiUrl}/document-templates${prefix}/templates`;

    return this.http.get<Template[]>(url).pipe(
      map(data => {
       return data.map((template: Template) => {
         return {
           id: template.id,
           name: template.name
         };
       });
      })
    );
  }

  createTemplate(template) {
    const url = this.apiUrl + '/document-templates/templates';

    return this.http.post(url, template);
  }

  updateTemplate(template) {
    const url = `${this.apiUrl}/document-templates/templates/${template.id}`;

    return this.http.put(url, template);
  }

  deleteTemplate(templateId: string, templateType: TemplateType = TemplateType.CUSTOM) {
    const prefix = templateType === TemplateType.AUTENTI ? '/autenti' : '';
    const url = `${this.apiUrl}/document-templates${prefix}/templates/${templateId}`;

    return this.http.delete(url);
  }
}
