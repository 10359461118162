import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  constructor() {}

  set(data: object | string, key: string, session: boolean = false) {
    const dataToSave = typeof data === 'string' ? data : JSON.stringify(data);
    session ? sessionStorage.setItem(key, dataToSave) : localStorage.setItem(key, dataToSave);
  }

  get(key: string) {
    let value = null;

    if (sessionStorage.getItem(key)) {
      value = sessionStorage.getItem(key);
    } else {
      value = localStorage.getItem(key);
    }

    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }
}
