import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { GSchoolService } from './gschool.service';
import { Template } from '../automation/templates/templates.type';
import { map } from 'rxjs/operators';
import { Base64Service } from '../../core/services/base64.service';

@Injectable({
  providedIn: 'root'
})
export class GSchoolResolver implements Resolve<Observable<Template>> {
  constructor(
    private service: GSchoolService,
    private base64: Base64Service
) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Template> {
    return this.service.getGSchoolTemplate().pipe(
      map((template: Template) => {
        template.draft.title = this.base64.decode(template.draft.title);
        template.draft.message = this.base64.decode(template.draft.message);
        template.draft.content = this.base64.decode(template.draft.content);

        return template;
      })
    );
  }
}
