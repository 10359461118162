import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';

import { Subscription } from 'rxjs';

import { OverlayService } from '../../../../core/components/overlay/overlay.service';
import { OverlayConfig, OverlayType } from '../../../../core/components/overlay/overlay.type';
import { EditorService } from '../editor.service';
import { EditorAction, EditorActionType } from '../editor.type';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  animations: [
    trigger('sidePanel', [
      state('void', style({
        transform: 'translateX(100%)',
        background: '#fff',
        width: '!',
        height: '!'
      })),
      transition(':enter', [
        animate('450ms cubic-bezier(.35,0,.25,1)', style({
          transform: 'translateX(0)'
        }))
      ]),
      transition(':leave', [
        animate('450ms cubic-bezier(.35,0,.25,1)', style({
          transform: 'translateX(100%)'
        })),
      ])
    ]),
    trigger('panelMenuChange', [
      transition(
        'tokens => some, tokens => settings, settings => tokens, settings => some, some => tokens, some => settings',
        [
          style({ height: '!' }),
          query(':enter', style({ transform: 'translateX(100%)' })),
          query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
          group([
            query(':leave',
              animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)' }))
            ),
            query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
          ])
        ]
      )
    ])
  ]
})
export class PanelComponent implements OnInit, OnDestroy {

  @Input() data: any;

  activeItem: string;
  showAddressBookPanel: boolean;

  subscriptions: Subscription[];

  constructor(
    private overlay: OverlayService,
    private editor: EditorService
  ) { }

  ngOnInit() {
    this.clearSubscriptions();
    this.activeItem = 'tokens';
    this.showAddressBookPanel = false;

    this.subscriptions.push(
      this.overlay.get().subscribe((overlayConfig: OverlayConfig) => {
        if (overlayConfig.type === OverlayType.EDITOR && !overlayConfig.show) {
          this.showAddressBookPanel = false;
        }
      })
    );

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.SHOW_ADDRESS_BOOK:
            this.showAddressBookPanel = true;
            break;
          case EditorActionType.CLOSE_ADDRESS_BOOK:
          case EditorActionType.CLOSE_MULTI_SEND_ADDRESS_BOOK:
            this.showAddressBookPanel = false;
            break;
        }
      })
    );
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  setActiveItem(itemName: string) {
    this.activeItem = itemName;
  }
}
