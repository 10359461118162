import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-book-panel-list',
  templateUrl: './address-book-panel-list.component.html',
  styleUrls: ['./address-book-panel-list.component.scss']
})
export class AddressBookPanelListComponent implements OnInit {

  @Input() header;
  @Input() addresses;
  @Input() options;

  defaultOptions: any;

  constructor() {
    this.defaultOptions = {
      color: '#0d1e2e'
    };
  }

  ngOnInit() {
    this.options = {...{}, ...this.defaultOptions, ...this.options};
  }
}
