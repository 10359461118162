import { RoleData } from '../editor/editor.type';
import { SenderType, SignatureType } from '../create/create.type';

export enum TemplateType {
  AUTENTI = 'AUTENTI',
  CUSTOM = 'CUSTOM'
}

export enum TemplateMode {
  FILL = 'FILL',
  EDIT = 'EDIT'
}

export interface TemplateList {
  id: string;
  name: string;
}

export interface Template {
  id: string;
  clientId;
  name: string;
  draft: Draft;
}

export interface Draft {
  content: string;
  title: string;
  message: string;
  roles: RoleData[];
  senderType: SenderType;
  signatureType: SignatureType;
  participants?: any[];
}
