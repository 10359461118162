import { Component, Injector, OnInit } from '@angular/core';

import { AutModalContentComponent } from '../aut-modal/aut-modal.type';
import { AutModalService } from '../aut-modal/aut-modal.service';
import { TranslationComponent } from '../translation/translation.component';

@Component({
  selector: 'app-csv-errors-modal',
  templateUrl: './csv-errors-modal.component.html',
  styleUrls: ['./csv-errors-modal.component.scss']
})
export class CsvErrorsModalComponent extends TranslationComponent implements OnInit, AutModalContentComponent {

  data: any = {};

  constructor(
    private modal: AutModalService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {}

  close() {
    this.modal.close();
  }

}
