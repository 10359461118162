import { TemplateType } from '../templates/templates.type';
import { ReceiverType } from '../editor/editor.type';

export enum CreateStep {
  PREPARE = 'PREPARE',
  PROGRESS = 'PROGRESS',
  SUMMARY = 'SUMMARY',
}

export interface CreateData {
  createProcessId?: string;
  step?: CreateStep;
  success?: boolean;
  documentName?: string;
  documentToSend?: any;
  documentUrl?: string;
  templateId?: string;
  templateType?: TemplateType;
  user?: {
    name: string;
    receiverType: ReceiverType;
  };
  receivers?: {
    receiverType: ReceiverType
  }[];
}

export enum SenderType {
  SIGNER = 'SIGNER',
  VIEWER = 'VIEWER'
}

export enum SignatureType {
  ESIGNATURE = 'ESIGNATURE',
  QUALIFIED_ESIGNATURE = 'QUALIFIED_ESIGNATURE'
}

export interface Receiver {
  isUser?: boolean;
  roleId?: number;
  roleName?: string;
  firstName: string;
  lastName: string;
  email: string;
  receiverType: ReceiverType;
}

export interface SentDocuments {
  numberOfDocuments: number;
  sentDocuments: string[];
  failedDocuments: string[];
  redirectUrl?: string;
}

export interface RedirectResponse {
  redirect: string;
}

export interface DisabledInfo {
  signer: boolean;
}
