import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { CyganBallsService } from './cygan-balls.service';
import { TranslationComponent } from '../translation/translation.component';

export interface Item {
  label: string;
  active?: boolean;
  done?: boolean;
}

@Component({
  selector: 'app-progress-balls',
  templateUrl: './cygan-balls.component.html',
  styleUrls: ['./cygan-balls.component.scss']
})
export class CyganBallsComponent extends TranslationComponent implements OnInit, OnDestroy {

  @Input() items: Item[];

  widthStyle: any;
  subscription: Subscription;

  constructor(
    private service: CyganBallsService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.subscription = this.service.get().subscribe(items => {
      this.items = [...[], ...items];
      this.refreshStyle();
    });

    if (this.items) {
      this.refreshStyle();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private refreshStyle() {
    this.widthStyle = {
      width: (100 / this.items.length) + '%'
    };
  }

}
