<div class="scroll-page addresses">
  <div class="content-box" [class.empty]="!addresses.length">
    <div class="content-box-empty-content">
      <div class="content-box-empty-info" appTranslate>
        Brak kontaktów
      </div>
      <div class="content-box-empty-actions">
        <button id="add-contact-empty-list"
                class="button primary medium"
                routerLink="add"
        >
          <fa-icon icon="user-plus"></fa-icon>
          <span appTranslate>Dodaj kontakt</span>
        </button>
        <button id="edit-schema-empty-list"
                class="button primary medium"
                routerLink="/automation/addressbook/edit-schema"
                [appTooltip]="'Dopasuj strukturę do swoich potrzeb' | translate: currentLanguageCode | async"
                [tooltipOptions]="{ delay: '500ms' }"
        >
          <span appTranslate>Zarządzaj</span>
        </button>
        <div class="aut-file-input primary medium">
          <input id="csv-empty"
                 type="file"
                 #fileEmpty
                 (click)="fileEmpty.value = null"
                 [accept]="acceptFormat"
                 (change)="fileChanged($event)"
          >
          <label for="csv-empty">
            <fa-icon icon="file-import"></fa-icon>
            {{ 'Import CSV' | translate: currentLanguageCode | async }}
          </label>
        </div>
        <button class="button primary medium" (click)="downloadCSV()">
          <fa-icon icon="file-download"></fa-icon>
          <span appTranslate>Pobierz przykładowy plik</span>
        </button>
      </div>
    </div>

    <div class="content-box-header">
      <div class="content-box-header-title">
        {{ 'Książka adresowa' | translate: currentLanguageCode | async }}
      </div>

      <div class="content-box-header-actions">
        <div class="search">
          <input type="text"
                 placeholder="{{ 'Szukaj' | translate: currentLanguageCode | async }}"
                 (input)="search(searchInput.value)"
                 #searchInput
          />
          <fa-icon icon="search"></fa-icon>
        </div>

        <div class="actions">
          <button id="edit-schema"
                  class="button primary medium"
                  routerLink="/automation/addressbook/edit-schema"
                  [appTooltip]="'Dopasuj strukturę do swoich potrzeb' | translate: currentLanguageCode | async"
                  [tooltipOptions]="{ delay: '500ms' }"
          >
            <span appTranslate>Zarządzaj</span>
          </button>
          <button id="add-contact"
                  class="button primary medium"
                  routerLink="add"
          >
            <fa-icon icon="user-plus"></fa-icon>
            <span appTranslate>Dodaj kontakt</span>
          </button>
          <div class="aut-file-input primary medium"
               [appTooltip]="'Uzupełnij swoją książkę adresową za pomocą pliku .csv' | translate: currentLanguageCode | async"
               [tooltipOptions]="{ delay: '500ms' }"
          >
            <input id="csv"
                   type="file"
                   #file
                   [accept]="acceptFormat"
                   (click)="file.value = null"
                   (change)="fileChanged($event)"
            >
            <label for="csv">
              <fa-icon icon="file-import"></fa-icon>
              <span appTranslate>Import CSV</span>
            </label>
          </div>
          <button class="button primary medium"
                  (click)="downloadCSV()"
                  [appTooltip]="'Pobierz przykładowy plik .csv a następnie go wypełnij danymi i zaimportuj' | translate: currentLanguageCode | async"
                  [tooltipOptions]="{ delay: '500ms' }"
          >
            <fa-icon icon="file-download"></fa-icon>
            <span appTranslate>Pobierz przykładowy plik</span>
          </button>
          <button id="reomve-all-contacts"
                  class="button danger medium"
                  (click)="removeAllAddresses()"
          >
            <fa-icon icon="trash-alt"></fa-icon>
            <span appTranslate>Usuń wszystkie</span>
          </button>
        </div>
      </div>
    </div>

    <div class="content-box-content">
      <ul class="addresses-list">
        <div class="addresses-list-empty" *ngIf="!filteredAddresses.length">
          <h3 appTranslate>Nie ma takiego kontaktu</h3>
          <p appTranslate>Spróbuj zmienić kryteria wyszukiwania. Możesz szukać po imieniu, nazwisku lub adresie e-mail.</p>
        </div>
        <li class="address header" *ngIf="filteredAddresses.length">
          <div class="attribute wide-02"></div>
          <div class="attribute">{{ 'email' | translate: currentLanguageCode | async  }}</div>
          <div class="attribute">{{ 'firstName' | translate: currentLanguageCode | async  }}</div>
          <div class="attribute">{{ 'lastName' | translate: currentLanguageCode | async  }}</div>
          <div class="attribute">{{ 'companyName' | translate: currentLanguageCode | async  }}</div>
          <div class="attribute">{{ 'nip' | translate: currentLanguageCode | async  }}</div>
          <div class="attribute wide-02" appTranslate>Akcje</div>
        </li>
        <li class="address" *ngFor="let address of filteredAddresses">
          <div class="attribute wide-02">
            <fa-icon icon="user"></fa-icon>
          </div>
          <div class="attribute">{{ address.email }}</div>
          <div class="attribute">{{ address.firstName }}</div>
          <div class="attribute">{{ address.lastName }}</div>
          <div class="attribute">{{ address.companyName }}</div>
          <div class="attribute">
            <span class="invalid" [appTooltip]="'Niepoprawny NIP'| translate: currentLanguageCode | async" *ngIf="address.nipStatus === 'invalid'">
              <fa-icon icon="exclamation-circle"></fa-icon>{{ address.nip }}
            </span>
            <span *ngIf="address.nipStatus === 'valid'">{{ address.nip }}</span>
          </div>
          <div class="attribute wide-02 centered">
            <div class="actions">
              <div class="action"
                   routerLink="{{address.id}}/edit"
                   [appTooltip]="'Edycja' | translate: currentLanguageCode | async "
              >
                <fa-icon icon="edit"></fa-icon>
              </div>
              <div class="action"
                   (click)="removeAddress(address.id)"
                   [appTooltip]="'Usuń' | translate: currentLanguageCode | async "
              >
                <fa-icon icon="trash-alt"></fa-icon>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <app-front-pagination
        [pageLimit]="pageLimit"
        [itemsCount]="addressesCount"
        (pageChanged)="changePage($event)"
      ></app-front-pagination>

    </div>
  </div>
</div>
