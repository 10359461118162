import { Injectable } from '@angular/core';

export const enum NipStatus {
  VALID = 'valid',
  INVALID = 'invalid'
}

@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  constructor() {}

  formatNip(nip: string): string {
    if (nip !== '') {
      const nipFormatRegexp = /^[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}$/;

      if (nipFormatRegexp.test(nip)) {
        return nip;
      }

      const nipRegexp = /^[0-9]{10}$/;
      nip = nip.replace(/-/g, '');

      if (nipRegexp.test(nip)) {
        return `${nip[0]}${nip[1]}${nip[2]}-${nip[3]}${nip[4]}${nip[5]}-${nip[6]}${nip[7]}-${nip[8]}${nip[9]}`;
      }
    }

    return nip;
  }

  validateNip(nip: string): NipStatus {
    if (nip !== '') {
      const nipWithoutDashes = nip.replace(/-/g, '');
      const nipRegexp = /^[0-9]{10}$/;

      if (nipRegexp.test(nipWithoutDashes) === false) {
        return NipStatus.INVALID;
      } else {
        const digits = ('' + nipWithoutDashes).split('');
        const checksum = (6 * parseInt(digits[0], 10) +
          5 * parseInt(digits[1], 10) +
          7 * parseInt(digits[2], 10) +
          2 * parseInt(digits[3], 10) +
          3 * parseInt(digits[4], 10) +
          4 * parseInt(digits[5], 10) +
          5 * parseInt(digits[6], 10) +
          6 * parseInt(digits[7], 10) +
          7 * parseInt(digits[8], 10)) % 11;

        return (parseInt(digits[9], 10) === checksum) ? NipStatus.VALID : NipStatus.INVALID;
      }
    }

    return NipStatus.VALID;
  }
}
