<div class="address">
  <div class="address-header" (click)="open()">
    <div class="icon" [ngStyle]="style">{{ initials }}</div>
    <div class="data">
      <div class="name">{{ addressData.firstName }}</div>
      <div class="email">{{ addressData.email }}</div>
    </div>
    <div class="details">
      <i class="icon-chevron-up" [class.rotate-180]="isOpen"></i>
    </div>
  </div>
  <div class="address-content" *ngIf="isOpen" [@slide]>
    <div class="address-content-wrapper">
      <div class="address-content-header" appTranslate>Poniższymi danymi zostaną uzupełnione pola użyte w dokumencie</div>
      <div class="address-tokens">
        <div class="address-token" *ngFor="let token of addressData | keyOrder: options.keyOrder">
          <div class="key">
            <div class="key-name">{{ token.key | translate: currentLanguageCode | async }}</div>
            <div class="key-raw-name">[{{ token.key }}]</div>
          </div>
          <div class="value">{{ token.value }}</div>
        </div>
        <div class="address-action">
          <button class="button primary medium" (click)="select()" appTranslate>
            Dodaj
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

