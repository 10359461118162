import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Item } from './cygan-balls.component';

@Injectable({
  providedIn: 'root'
})
export class CyganBallsService {

  private subject: Subject<Item[]>;

  constructor() {
    this.subject = new Subject<Item[]>();
  }

  get(): Observable<Item[]> {
    return this.subject.asObservable();
  }

  setItems(items: Item[]) {
    this.subject.next(items);
  }
}
