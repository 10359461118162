import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { LOGOUT_PATH, REFRESH_TOKEN_PATH, USER_LOGO_PATH } from './auth.type';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  inflightAuthRequest = null;

  constructor(
    private router: Router,
    private auth: AuthService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(REFRESH_TOKEN_PATH) || req.url.includes(LOGOUT_PATH) || req.url.includes(USER_LOGO_PATH)) {
      return next.handle(req);
    }

    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.auth.validateTokenExpiration();
    }

    return this.inflightAuthRequest.pipe(
      switchMap(() => {
        this.inflightAuthRequest = null;

        return next.handle(req);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = null;
            this.auth.logout(true);
            return throwError(error);
          }
        }

        if (error.status === 500 && error.statusText === 'Cannot refresh token') {
          this.auth.logout(true);
        }

        return throwError(error);
      })
    );
  }
}
