<div class="address-book-panel-list">
  <div class="header">
    {{ header }}
  </div>
  <ul>
    <li *ngFor="let address of addresses">
      <app-address-book-panel-item
        [id]="address"
        [addressData]="address"
        [options]="{ color: options.color, keyOrder: options.keyOrder }"
      >
      </app-address-book-panel-item>
    </li>
  </ul>
</div>
