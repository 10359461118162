import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddressBookService {

  constructor() { }

  getInitials(address: any): string {
    if (address.hasOwnProperty('firstName') && address.hasOwnProperty('lastName')) {
      return address.firstName[0].toUpperCase() + address.lastName[0].toUpperCase();
    }

    return '';
  }

  mapAddresses(addresses: any[], filter: string[] = [], exclude: boolean = false): any[] {
    return addresses.map(address => {
      return this.mapAddress(address, filter, exclude);
    });
  }

  mapAddress(address: any, filter: string[] = [], exclude: boolean = false): any {
    let tokens = [];

    for (const token in address.tokens) {
      if (address.tokens.hasOwnProperty(token)) {
        tokens.push(token);
      }
    }

    const mapAddress = {
      email: address.email,
      firstName: address.tokens.firstName,
      lastName: address.tokens.lastName,
    };

    if (exclude) {
      tokens = tokens.filter(token => !filter.find(filterToken => filterToken === token));
    } else if (filter.length) {
      tokens = filter;
    }

    for (const token of tokens) {
      if (address.tokens.hasOwnProperty(token)) {
        mapAddress[token] = address.tokens[token];
      }
    }

    return mapAddress;
  }
}
