<div class="edit-token-tab">
  <div class="roles-header">
    <div class="roles-header-label">
      {{ 'Grupy danych' | translate: currentLanguageCode | async }} <i class="icon-add-info"
                      [appTooltip]="'Masz jeden szablon różnych kontrahentów, dodaj grupę danych.' | translate: currentLanguageCode | async"
                      [tooltipOptions]="{ delay: '50ms' }"></i>
    </div>
    <div class="roles-header-actions">
      <button class="button primary medium"
              [class.disabled]="showFirstTimeInfo || rolesData.length >= 10"
              [disabled]="showFirstTimeInfo || rolesData.length >= 10"
              (click)="showNewRolePanel()"
              appTranslate
      >
        Nowa grupa
      </button>
    </div>
  </div>

  <div class="tokens-wrapper">
    <div class="tokens-panel-scroll" [class.active-autologout-bar]="autologoutBarVisible">
      <div class="tokens-panel" *ngIf="!showFirstTimeInfo">
        <div class="tokens-list">
          <div class="token-role"
               [class.colapse]="!expandedRoles[roleIndex]"
               *ngFor="let role of rolesData; let roleIndex = index"
          >
            <div class="role-header">
              <div class="role-name role-{{role.roleId}} {{ !expandedRoles[roleIndex] ? 'collapse' : '' }}"
                   (click)="expandRole(roleIndex)"
              >
                <div class="role-name-text">
                  {{ role.roleName }}
                </div>
                <div class="role-name-collapse" [class.expanded]="expandedRoles[roleIndex]">
                  <i class="icon-chevron-up"></i>
                </div>
              </div>
              <div class="role-sub-header"
                   [class.add-token]="addNewToken && newTokenRole === role.roleId"
                   [@slide]="expandedRoles[roleIndex]"
              >
                <div class="role-metadata">
                  <div class="role-add-token">
                    <button class="button small role-{{ role.roleId }}"
                            *ngIf="newTokenRole !== role.roleId"
                            (click)="showNewTokenInput(role.roleId)"
                            appTranslate
                    >
                      Nowe pole
                    </button>
                    <div class="add-new-token" *ngIf="addNewToken && newTokenRole === role.roleId">
                      <label for="new-token-id" class="new-token-label">
                        Nazwa pola:
                        <span class="danger" *ngIf="tokenExistsInRole(role, newTokenId)" appTranslate>
                          Pole o podanej nazwie już istnieje
                        </span>
                        <span class="danger" *ngIf="!newTokenId.trim().length" appTranslate>
                          Pole nie może byc puste
                        </span>
                      </label>
                      <input id="new-token-id"
                             class="aut-input"
                             type="text"
                             maxlength="30"
                             [(ngModel)]="newTokenId"
                             (keyup.enter)="tokenExistsInRole(role, newTokenId) || !newTokenId.trim().length ?
                              '' : addToken(role, newTokenId)"
                             appNoSpecialChars
                      >
                      <button class="button small role-{{ role.roleId }}"
                              [class.disabled]="tokenExistsInRole(role, newTokenId) || !newTokenId.trim().length"
                              [disabled]="tokenExistsInRole(role, newTokenId) || !newTokenId.trim().length"
                              (click)="addToken(role, newTokenId)"
                              appTranslate
                      >
                        Dodaj
                      </button>
                      <button class="button small close" (click)="closeNewTokenInput()">
                        <i class="icon-cancel"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="role-actions">
                  <button class="button small aut-dropdown-button"
                          [class.active]="roleOption === role.roleId"
                          (click)="showRoleOptions(role.roleId)"
                  >
                    <i class="icon-more-dots rotate-90"></i>
                  </button>
                </div>
                <div class="aut-dropdown-options"
                     *ngIf="roleOption === role.roleId"
                     [@fade]>
                  <ul class="options-list">
                    <li class="option">
                      <button (click)="removeRole(role)" appTranslate>
                        Usuń grupę
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="role-content">
              <div class="tokens" [@slide]="expandedRoles[roleIndex]">
                <div class="token-wrapper" *ngFor="let token of role.tokens"filter [@fade]>
                  <div class="token-label"
                       [class.active]="tokenOption === token.id"
                  >
                    <div class="token role-{{ role.roleId }}"
                         [class.empty]="token.count <= 0"
                         draggable="true"
                         (dragstart)="handleTokenDragStart($event, role.roleId, token.id)"
                         (dragend)="handleTokenDragEnd($event)"
                    >
                      <span class="token-drag"><i class="icon-more-dots"></i></span>
                      <span class="token-count">{{ token.count }}<span>x</span></span>
                      <span class="token-id">[{{ token.id }}]</span>
                    </div>
                    <div class="token-actions">
                      <div class="actions">
                        <button class="button small" (click)="handleTokenInsert(token.id, role.roleId)">
                          +
                        </button>
                        <button class="button small aut-dropdown-button"
                                [class.active]="tokenOption === role.roleId + '-' + token.id"
                                (click)="showTokenOptions(role.roleId + '-' + token.id)"
                        >
                          <i class="icon-more-dots rotate-90"></i>
                        </button>
                      </div>
                      <div class="token-filled" [class.visible]="token.value">
                        <i class="icon-file-filled"></i>
                      </div>
                      <div class="aut-dropdown-options"
                           *ngIf="tokenOption === role.roleId + '-' + token.id"
                           [@fade]
                      >
                        <ul class="options-list">
                          <li class="option danger">
                            <button (click)="removeToken(role, token)">
                              <i class="icon-delete"></i><span appTranslate>Usuń pole</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="first-time-info" *ngIf="showFirstTimeInfo" [@disappear]>
        <div class="message" appTranslate>
          Jeśli chcesz mieć jeden szablon i tworzyć z niego wiele dokumentów, dodaj grupy danych. Np. zmienia się
          nazwisko kontrahenta, więc dodaj grupę “kontrahent” a w niej pole “nazwisko”. Następnie przeciągnij je
          na szablon.
        </div>
        <div class="action">
          <button class="button primary medium" (click)="hideFirstTimeInfo()">OK</button>
        </div>
      </div>

    </div>
  </div>

  <div class="tokens-actions">
    <div class="messages">
      <span *ngIf="!canSaveTemplate" class="danger" appTranslate>Szablon musi zawierać treść</span>
    </div>
    <div class="actions">
      <button id="save-template"
              class="button primary medium"
              *ngIf="!templateNew && templateType === TemplateType.CUSTOM"
              (click)="saveTemplate($event)"
              [class.disabled]="!canSaveTemplate"
              [disabled]="!canSaveTemplate"
      >
        <fa-icon icon="save"></fa-icon>
        <span appTranslate>Zapisz</span>
      </button>
      <button id="save-template-as"
              class="button primary medium"
              (click)="saveTemplate($event, true)"
              [class.disabled]="!canSaveTemplate"
              [disabled]="!canSaveTemplate"
      >
        <fa-icon icon="save"></fa-icon>
        <span appTranslate *ngIf="!templateNew">Zapisz jako nowy</span>
        <span appTranslate *ngIf="templateNew">Zapisz</span>
      </button>
    </div>
  </div>

</div>
