import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TemplateList, TemplateType } from './templates.type';
import { TranslationComponent } from '../../../core/components/translation/translation.component';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent extends TranslationComponent implements OnInit {

  autentiTemplates: TemplateList[];
  customTemplates: TemplateList[];
  TemplateType = TemplateType;

  constructor(
    private route: ActivatedRoute,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.route.data.subscribe((data: {
      autentiTemplates: TemplateList[],
      customTemplates: TemplateList[],
    }) => {
      this.autentiTemplates = data.autentiTemplates.reverse();
      this.customTemplates = data.customTemplates.reverse();
    });
  }

  getLevel(documentOutlet) {
    return documentOutlet.activatedRouteData['level'];
  }
}
