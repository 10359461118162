import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FrontPaginationService {

  private subject: Subject<number>;

  constructor() {
    this.subject = new Subject<number>();
  }

  getPaginator(): Observable<number> {
    return this.subject.asObservable();
  }

  refresh(addressesCount: number) {
    this.subject.next(addressesCount);
  }
}
