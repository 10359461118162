import { Component, Injector, OnInit } from '@angular/core';

import { AccountType, UserBilling } from './user.type';
import { UserService } from './user.service';
import { OverlayService } from '../../overlay/overlay.service';
import { GtmEventType, GtmService } from '../../../services/gtm.service';
import { TranslationComponent } from '../../translation/translation.component';
import { OverlayConfig, OverlayType } from '../../overlay/overlay.type';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends TranslationComponent implements OnInit {

  showUserPanel: boolean;
  loggedUser: any;
  userInitials: string;
  userBilling: UserBilling;

  constructor(
    private user: UserService,
    private overlay: OverlayService,
    private gtm: GtmService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const user = this.user.getUserData();
    this.loggedUser = user.account;
    this.userBilling = user.billing;

    if (user.account.firstName === '') {
      this.userInitials = 'XD';
      user.account.firstName = 'Brak';
      user.account.lastName = 'Brak';
      user.account.email = 'brak';
      user.account.organizationName = 'Brak';
      user.account.jobTitle = 'Brak';
      user.account.type = AccountType.ORGANIZATION;
      user.billing.planName = 'Plan Pro';
      user.billing.expirationDate = 1603152000000;
      this.user.setUserData(user);
    } else {
      this.userInitials = `${user.account.firstName[0].toUpperCase()}${user.account.lastName[0].toUpperCase()}`;
    }

    this.overlay.get().subscribe((overlay: OverlayConfig) => {
      if (overlay.type === OverlayType.MAIN && !overlay.show) {
        this.closeUserPanel();
      }
    });

    this.gtm.sendMsg( {
      event: 'loadUser',
      description: 'Load user data',
      type: GtmEventType.SUCCESS,
    });
  }

  userPanelToggle() {
    if (this.showUserPanel) {
      this.showUserPanel = false;
      this.overlay.hide();
    } else {
      this.overlay.show();
      this.showUserPanel = true;
    }
  }

  closeUserPanel() {
    this.showUserPanel = false;
  }

}
