import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { SubscriptionsRepository } from './subscriptions.repository';
import { SubscriptionData } from './subscriptions.type';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsResolver implements Resolve<Observable<SubscriptionData>> {
  constructor(
    private repository: SubscriptionsRepository,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SubscriptionData> {
    return this.repository.getSubscriptionData();
  }
}
