<div class="fill-token-tab">
  <div class="roles-header">
    <div class="roles-header-label">
      {{ 'Grupy danych' | translate: currentLanguageCode | async }} <i class="icon-add-info"
                      [appTooltip]="'Wypełnij pola danymi aby utworzyć dokument. Możesz skorzystać z książki adresowej.' | translate: currentLanguageCode | async"
                      [tooltipOptions]="{ delay: '50ms' }"></i>
    </div>
  </div>

  <div class="tokens-wrapper">
    <div class="tokens-panel-scroll">
      <div class="tokens-panel">
        <div class="tokens-list">
          <div class="token-role"
               [class.collapse]="!expandedRoles[roleIndex]"
               *ngFor="let role of rolesData; let roleIndex = index"
          >
            <div class="role-header">
              <div class="role-name role-{{role.roleId}} {{ !expandedRoles[roleIndex] ? 'collapse' : '' }}"
                   (click)="expandRole(roleIndex)"
              >
                <div class="role-name-text">
                  {{ role.roleName }}
                </div>

                <div class="role-name-collapse" [class.expanded]="expandedRoles[roleIndex]">
                  <i class="icon-chevron-up"></i>
                </div>
              </div>
              <div class="role-sub-header" [@slide]="expandedRoles[roleIndex]">
                <div class="role-metadata">
                  <button class="button primary small"
                          (click)="showAddressBook(role)"
                          appTranslate
                  >
                    Uzupełnij z książki adresowej
                  </button>
                </div>
                <div class="role-actions"></div>
              </div>
            </div>
            <div class="tokens" [@slide]="expandedRoles[roleIndex]">
              <div class="token-wrapper" *ngFor="let token of role.tokens | filterToken">
                <div class="token-label">
                  <div class="token role-{{ role.roleId }}"
                       [class.zero]="token.count <= 0"
                       draggable="false"
                       (click)="setActiveToken(token)"
                  >
                    <span class="token-count">{{ token.count }}<span>x</span></span>
                    <span class="token-id">[{{ token.id }}]</span>
                  </div>
                </div>
                <div class="token-data">
                  <input type="text"
                         id="{{ role.roleId }}-{{ token.id }}"
                         class="aut-input"
                         [(ngModel)]="token.value"
                         (ngModelChange)="handleEditorInput()"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tokens-actions">
    <div class="messages">
      <span *ngIf="hasEmptyTokenValues && !limitReached" class="danger" appTranslate>Uzupełnij wszystkie pola</span>
      <span *ngIf="templateEmpty && !limitReached" class="danger" appTranslate>Szablon musi zawierać treść</span>
    </div>
    <div class="actions">
      <button class="button primary medium"
              *ngIf="!limitReached"
              (click)="prepareToSend()"
              [class.disabled]="templateEmpty || hasEmptyTokenValues"
      >
        <span appTranslate>Dodaj odbiorców</span>
      </button>
      <button class="button primary medium"
              *ngIf="limitReached"
              (click)="prepareToSend()"
              [class.disabled]="limitReached || templateEmpty || hasEmptyTokenValues"
              [appTooltip]="'Maksymalny limit wysłanych dokumentów dla tego konta został osiągnięty. Kup pakiet PRO aby wysyłać dokumenty.' | translate: currentLanguageCode | async"
              [tooltipOptions]="{ position: 'TOP', delay: '500ms' }"
      >
        <span appTranslate>Dodaj odbiorców</span>
      </button>
    </div>
  </div>
</div>
