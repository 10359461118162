export interface RefreshResponse {
  expiresIn: number;
}

export enum Storage {
  USER = 'autenti:templates:user',
  LANGUAGES = 'autenti:templates:languages'
}

export const REFRESH_TOKEN_PATH = 'refresh';
export const LOGOUT_PATH = 'logout';
export const USER_LOGO_PATH = 'app/images/logo';
export const TOKEN_EXPIRATION_SAFE_TIME = 60 * 15;
export const TOKEN_EXPIRATION_SAFE_BANNER_TIME = 60 * 5;
export const REFRESH_IN_PROGRESS_KEY = 'autenti:refreshInProgress';
export const AUTH_COOKIES = [
  'access_token', 'refreshToken', 'expirationTime', 'currentBoxId', 'isOrganization', 'lang', 'organizationId'
];

export interface TimeToExpireType {
  safe: boolean;
  difference: number;
}
