import { Component, Injector, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';

import { TemplateList, TemplateMode, TemplateType } from '../templates.type';
import { TemplatesRepository } from '../templates.repository';
import { TranslationComponent } from '../../../../core/components/translation/translation.component';
import { PopupService } from '../../../../core/services/popup.service';
import { GtmEventType, GtmService } from '../../../../core/services/gtm.service';
import { LocalStoreService } from '../../../../core/services/local-store.service';
import { DataStoreService } from '../../../../core/services/data-store.service';
import { RestrictionType, SubscriptionData } from '../../../../core/components/subscriptions/subscriptions.type';
import { StoreKey } from '../../../../core/services/data-store.type';
import { RouteUrl } from '../../../../app.routes';

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss'],
  animations: [
    trigger('removeTemplate', [
      transition(':leave', animate('800ms ease', style({
        width: 0,
        paddingLeft: 0,
        marginRight: 0,
        transform: 'rotateZ(360deg) scale(0)',
      })))
    ]),
    trigger('disappear', [
      transition(':leave', animate('800ms ease', style({
        opacity: 0,
      })))
    ])
  ]
})
export class TemplatesListComponent extends TranslationComponent implements OnInit {

  @Input() templates: TemplateList[];
  @Input() listTitle: string;
  @Input() templateType: TemplateType;
  @Input() canAddNew: boolean;
  @Input() hasOverlay: boolean;

  icon: string;
  showFirstTimeInfo: boolean;
  localStoreKey: string;
  TemplateMode = TemplateMode;
  TemplateType = TemplateType;
  limitReached: boolean;
  originTexts: string[];
  infoTexts: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private repository: TemplatesRepository,
    private popup: PopupService,
    private gtm: GtmService,
    private localStore: LocalStoreService,
    private dataStore: DataStoreService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.originTexts = ['Usunąć szablon?', 'Po usunięciu nie będzie można cofnąć zmian!', 'Tak, usuń', 'Nie'];

    this.translationService.watchTranslation().subscribe(translations => {
      const currentLanguage = this.translationService.getLanguage();
      this.infoTexts = this.originTexts.map(originText => {
        return translations[currentLanguage][originText] || originText;
      });
    });

    this.route.data.subscribe((data: {
      subscriptionData: SubscriptionData
    }) => {
      this.limitReached = !!data.subscriptionData.restrictions.find(restriction => restriction === RestrictionType.DOCUMENT_SENDING);
    }
   );

    this.icon = this.templateType === TemplateType.AUTENTI ? 'autenti-template' : 'user-template';

    if (this.hasOverlay) {
      this.localStoreKey = `autenti:templates:list:${this.templateType}`;
      const value = this.localStore.get(this.localStoreKey) || { firstTime: true };
      this.showFirstTimeInfo = value.firstTime;
    } else {
      this.showFirstTimeInfo = false;
    }
  }

  hideFirstTimeInfo() {
    this.showFirstTimeInfo = false;
    this.localStore.set({ firstTime: false }, this.localStoreKey);
  }

  removeTemplate(event: MouseEvent, templateId: string) {
    event.preventDefault();
    event.stopPropagation();

    this.popup.askWarning({
      title: this.infoTexts[0],
      text: this.infoTexts[1],
      confirmButtonText: this.infoTexts[2],
      cancelButtonText: this.infoTexts[3]
    }).then(result => {
      if (result.value) {
        this.repository.deleteTemplate(templateId).subscribe(success => {
          this.popup.success();
          this.templates = this.templates.filter(template => template.id !== templateId);

          this.gtm.sendMsg({
            event: 'submitForm',
            description: 'Remove template',
            type: GtmEventType.SUCCESS,
            payload: {
              templateId: templateId,
            }
          });

        }, error => {
          this.popup.error();
        });
      }
    });
  }

  editTemplate(templateId: string, templateMode: string) {
    if (this.templateType === TemplateType.CUSTOM && this.limitReached && templateMode === TemplateMode.FILL) {
      return;
    }

    this.gtm.sendMsg({
      event: 'submitForm',
      description: 'Edit template',
      type: GtmEventType.SUCCESS,
      payload: {
        templateId: templateId,
        templateType: this.templateType
      }
    });

    this.dataStore.setData({
      templateMode: templateMode,
      templateType: this.templateType
    }, StoreKey.EDITOR);

    this.router.navigate(
      [`/automation/templates/${this.templateType.toLowerCase()}/${templateId}`]
    );
  }

  addNewTemplate() {
    this.gtm.sendMsg({
      event: 'submitForm',
      description: 'Create template',
      type: GtmEventType.SUCCESS,
      payload: {
        templateType: this.templateType
      }
    });

    this.dataStore.setData({
      templateMode: TemplateMode.EDIT,
      templateType: this.templateType,
      templateNew: true
    }, StoreKey.EDITOR);

    this.router.navigate([RouteUrl.NEW_TEMPLATE]);
  }
}
