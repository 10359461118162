import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { EditorService } from '../editor.service';
import { EditorAction, EditorActionType, INDENT_WIDTH, MAX_INDENT_WIDTH, Node } from '../editor.type';
import { TranslationComponent } from '../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends TranslationComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[];

  toolbarDisabled: boolean;

  constructor(
    private editor: EditorService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.toolbarDisabled = false;
    this.clearSubscriptions();

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.ENABLE_TOOLBAR:
            this.toolbarDisabled = false;
            break;
          case EditorActionType.DISABLE_TOOLBAR:
            this.toolbarDisabled = true;
            break;
        }
      })
    );
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  private isIndentTag(currentNode): boolean {
    return  (currentNode.parentNode.nodeName === Node.BLOCKQUOTE || currentNode.nodeName === Node.BLOCKQUOTE) ||
      (currentNode.parentNode.nodeName === Node.UNORDERED_LIST || currentNode.nodeName === Node.UNORDERED_LIST) ||
      (currentNode.parentNode.nodeName === Node.ORDERED_LIST || currentNode.nodeName === Node.ORDERED_LIST);
  }

  handleEditorBlur(event) {
    (<HTMLElement>document.querySelector('.page')).focus();
  }

  handleEditorCommand(command: string) {

    this.editor.callAction({ type: EditorActionType.CHANGE_TOKENS_MODE, params: true });

    switch (command) {
      case 'indent':
        this.handleIndent();
        break;
      default:
        document.execCommand(command, false, 'true');
    }
  }

  handleIndent() {
    const selection = window.getSelection();
    let left = 0;
    let currentNode = selection.anchorNode.parentNode;

    while (currentNode.parentNode && this.isIndentTag(currentNode)) {
      left += INDENT_WIDTH;
      currentNode = currentNode.parentNode;
    }

    if (left <= MAX_INDENT_WIDTH) {
      document.execCommand('indent', false, 'true');
    }
  }

}
