<div class="scroll-page summary">
  <div class="content-box">
    <div class="content-box-content">
      <div class="summary-content">
        <div class="summary-info">
          <div class="header error">
            <i class="icon-reject"></i>
            <span appTranslate>Wystąpił problem i Twój dokument nie został wysłany</span>
          </div>
        </div>

        <div class="box">
          <div class="header" appTranslate>
            Co możesz teraz zrobić?
          </div>
        </div>

        <div class="box">
          <div class="header small" appTranslate>Spróbuj wysłać dokument jeszcze raz</div>
          <ul class="options">
            <li>
              <i class="icon-repeat"></i>
              <a (click)="resendDocument($event)" href="" appTranslate>Wyślij ponownie dokument</a>
            </li>
          </ul>
        </div>

        <div class="box extra-space">
          <div class="header small" appTranslate>Wyślij nowy dokument</div>
          <ul class="options">
            <li>
              <i class="icon-create-new-document"></i>
              <a routerLink="/automation/templates" appTranslate>Przygotuj nowy dokument do wysłania</a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</div>
