import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';

import { Observable, throwError } from 'rxjs';

import { HeaderService } from '../components/header/header.service';
import { AuthService } from './auth.service';
import { UserService } from '../components/header/user/user.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private header: HeaderService,
    private auth: AuthService,
    private user: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.user.getUser().pipe(
      map(user => {
        this.user.setUserData(user);
        this.header.showHeader();
        return true;
      }),
      catchError(error => {
        this.auth.logout();
        return throwError(error);
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.user.getUser().pipe(
      map(user => {
        this.user.setUserData(user);
        this.header.showHeader();
        return true;
      }),
      catchError(error => {
        this.auth.logout();
        return throwError(error);
      })
    );
  }
}
