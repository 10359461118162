import { Component, Injector, OnInit } from '@angular/core';
import { animate, group, query, style, transition, trigger } from '@angular/animations';

import { TranslationComponent } from '../../core/components/translation/translation.component';
import { EnvironmentService } from '../../core/services/environment.service';
import { GtmService } from '../../core/services/gtm.service';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss'],
  animations: [
    trigger('automationAnimation', [
      transition(
        'templates => *, documents => *, addressbook => *, workflows => documents, workflows => templates,' +
        'edit-template => *, new-template => *, summary => *',
        [
          style({ height: '!' }),
          query(':enter', style({ transform: 'translateX(-100%)' })),
          query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
          group([
            query('router-outlet ~ *', [
              style({}),
              animate(1, style({}))
            ], { optional: true }),
            query(':leave',
              animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)' }))
            ),
            query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
          ])
        ]
      ),
      transition('workflows => workflow, create => progress, progress => summary, create => summary', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
        group([
          query('router-outlet ~ *', [style({}), animate(1, style({}))], { optional: true }),
          query(':leave', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)' }))),
          query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
        ])
      ]),
      transition('workflow => *', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(-100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
        group([
          query('router-outlet ~ *', [style({}), animate(1, style({}))], { optional: true }),
          query(':leave', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)' }))),
          query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
        ])
      ]),
    ])
  ]
})
export class AutomationComponent extends TranslationComponent implements OnInit {

  addressBookUrl: string;

  constructor(
    private env: EnvironmentService,
    private gtm: GtmService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.addressBookUrl = this.env.get('addressbookUrl');
  }

  sendGtmEvent(menuLabel: string) {
    this.gtm.sendMsg({
      event: 'submitForm',
      description: `Go to: ${menuLabel}`
    });
  }

  getTab(automationOutlet) {
    return automationOutlet.activatedRouteData['tab'];
  }
}
