import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { EnvironmentService } from '../../core/services/environment.service';
import { Address } from './address-book.type';

@Injectable({
  providedIn: 'root'
})
export class AddressBookRepository {

  apiUrl: string;

  constructor(private http: HttpClient, private env: EnvironmentService) {
    this.apiUrl = `${this.env.get('apiUrl')}/${this.env.get('apiPrefix')}`;
  }

  getAddressSchema(): Observable<string[]> {
    const url = this.apiUrl + '/document-templates/client/addressbookschema';

    return this.http.get<string[]>(url);
  }

  addItemToAddressSchema(itemName: string) {
    const url = `${this.apiUrl}/document-templates/client/addressbookschema/${itemName}`;

    return this.http.put<string>(url, itemName);
  }

  getAddresses(search: string = ''): Observable<Address[]> {
    let query = '';

    if (search) {
      query = `&search=${encodeURIComponent(search)}`;
    }

    const url = `${this.apiUrl}/document-templates/addressbook?page=0&size=5000${query}`;

    return this.http.get<Address[]>(url);
  }

  addAddress(address: Address) {
    const url = this.apiUrl + '/document-templates/addressbook';

    return this.http.post(url, address);
  }

  addAddresses(addresses: Address[]) {
    const url = this.apiUrl + '/document-templates/multi/addressbook';

    return this.http.post(url, addresses);
  }

  getAddress(addressId: string): Observable<Address> {
    const url = this.apiUrl + '/document-templates/addressbook/' + addressId;

    return this.http.get<Address>(url);
  }

  updateAddress(addressId: string, address: Address) {
    const url = this.apiUrl + '/document-templates/addressbook/' + addressId;

    return this.http.put(url, address);
  }

  uploadAddressesCsvFile(data: FormData, charset: string) {
    const url = `${this.apiUrl}/document-templates/multi/addressbook`;

    const options = {
      headers: {
        'X-File-Charset': charset
      }
    };

    return this.http.post(url, data, options);
  }

  removeAddress(addressId: string) {
    const url = this.apiUrl + '/document-templates/addressbook/' + addressId;

    return this.http.delete(url);
  }

  removeAllAddresses() {
    const url = this.apiUrl + '/document-templates/addressbook';

    return this.http.delete(url);
  }
}
