import { Injectable } from '@angular/core';

import { UserService } from '../components/header/user/user.service';

export const enum GtmEventType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export interface EventData {
  event: string;
  description?: string;
  base?: any;
  payload?: any;
  type?: GtmEventType;
  inlineManualDetails?: {
    description: string;
    playerInstance: any;
    topicId: any;
    stepId?: any;
  };
}

declare global {
  interface Window {
    inline_manual_player: any;
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor(private user: UserService) {}

  private getBaseUser() {
    const userData = this.user.getUserAccount();
    let baseUser = {};

    if (Object.keys(userData).length) {
      baseUser = {
        email: userData.email,
        id: userData.id,
        givenName: userData.firstName,
        surname: userData.lastName
      };
    }

    return baseUser;
  }

  sendMsg(eventData: EventData) {
    if (!eventData.hasOwnProperty('type') || !eventData.type) {
      eventData['type'] = GtmEventType.SUCCESS;
    }

    if (!eventData.hasOwnProperty('base') || !eventData.base) {
      eventData['base'] = { ...{}, ...this.getBaseUser() };
    } else {
      eventData.base = { ...{}, ...this.getBaseUser(), ...eventData.base };
    }

    window.dataLayer.push(eventData);
  }

}
