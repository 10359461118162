import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusColor'
})
export class StatusColorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const statusName = args || value;

    return '<span class="status-' + statusName.toLowerCase() + '">' + value + '</span>';
  }

}
