<div class="templates-list-wrapper">
  <div class="templates-list-header">
    {{ listTitle }}
  </div>
  <div class="templates-list">
    <div class="template-wrapper" *ngIf="canAddNew">
      <div class="template-page-wrapper pointer" (click)="addNewTemplate()">
        <div class="template-page-background">
          <div class="template-page">
            <i class="icon-new">+</i>
          </div>
        </div>
        <div class="template-page-shadow"></div>
      </div>
      <div class="template-name" appTranslate>
        Dodaj nowy szablon
      </div>
    </div>
    <div class="template-wrapper" *ngFor="let template of templates" [@removeTemplate]>
      <div class="template-page-wrapper"
           [class.pointer]="templateType === TemplateType.AUTENTI"
           (click)="templateType === TemplateType.AUTENTI && editTemplate(template.id, TemplateMode.FILL)"
      >
        <div class="template-page-background {{ icon }}">
          <div class="template-page">
            <i class="icon-{{ icon }}"></i>
            <div class="edit-type" *ngIf="canAddNew">
              <i class="icon-delete"  (click)="removeTemplate($event, template.id)"></i>
              <div class="edit-buttons">
                <button class="button small"
                        (click)="editTemplate(template.id, TemplateMode.EDIT)"
                        appTranslate
                >
                  Tworzenie
                </button>
                <button *ngIf="!limitReached"
                        class="button small"
                        (click)="editTemplate(template.id, TemplateMode.FILL)"
                        appTranslate
                >
                  Uzupełnianie
                </button>
                <button *ngIf="limitReached"
                        class="button small disabled"
                        (click)="editTemplate(template.id, TemplateMode.FILL)"
                        [appTooltip]="'Maksymalny limit wysłanych dokumentów dla tego konta został osiągnięty. Kup pakiet PRO aby wysyłać dokumenty.' | translate: currentLanguageCode | async"
                        [tooltipOptions]="{ position: 'TOP', delay: '500ms' }"
                        appTranslate
                >
                  Uzupełnianie
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="template-page-shadow"></div>
      </div>
      <div class="template-name">
        {{ template.name }}
      </div>
    </div>

    <div class="templates-list-overlay" *ngIf="showFirstTimeInfo" [@disappear]>
      <div class="first-time-info">
        <div class="message" appTranslate>
          Wysyłasz wiele podobnych dokumentów? Abyś zaoszczędził czas przygotowaliśmy dla Ciebie standardowe ich wzory.
          Wykorzystaj je lub przygotuj swoje szablony.
        </div>
        <div class="action">
          <button class="button primary medium" (click)="hideFirstTimeInfo()">OK</button>
        </div>
      </div>
    </div>

  </div>
</div>
