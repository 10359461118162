import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Base64Service {

  constructor() { }

  encode(inputString: string): string {
    return btoa(encodeURIComponent(inputString).replace(/%([0-9A-F]{2})/g,
      (match, p1) => {
        return String.fromCharCode(Number(`0x${p1}`));
      }));
  }

  decode(base64String: string): string {
    return decodeURIComponent(atob(base64String).split('').map(c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
}
