<div class="gschool">
  <div class="scroll-page">
    <div class="page-wrapper">
      <div class="content-box">
        <header class="content-box-header">
          <div class="main-info">G-suite dla szkół</div>
          <div class="sub-info">Uzupełnij poniższy formularz aby wysłać dokument do rodziców.</div>
          <div class="sub-info">Poniżej możesz zapoznać się z przykładowym dokumentem <br />przed nadaniem.</div>
        </header>
      </div>

      <div class="content-box">
        <div class="content-box-content">
          <div class="gschool-pdf">
            <div class="pdf-info">
              <img [src]="'/assets/images/pdf.svg'">
              <div class="text">Zgoda rodzica na utworzenie<br /> konta w G Suite</div>
            </div>
            <div class="pdf-actions">
              <a class="button primary small" [href]="'/assets/files/zgoda_rodzica_lub_opiekuna_dziecka.pdf'">Wyświetl</a>
            </div>
          </div>
        </div>
      </div>

      <div class="content-box">
        <div class="content-box-content">
          <form class="gschool-form" [formGroup]="gschoolForm" (ngSubmit)="submitForm()">
            <section>
              <header>
                Dane szkoły
              </header>
              <div class="content">
                <div class="input-box">
                  <label for="nazwa_szkoly_nr">Nazwa i numer szkoły</label>
                  <input id="nazwa_szkoly_nr" class="aut-input" formControlName="nazwa_szkoly_nr" type="text">
                </div>
                <div class="input-box">
                  <label for="miejscowosc_szkoly">Miejscowość</label>
                  <input id="miejscowosc_szkoly" class="aut-input" formControlName="miejscowosc_szkoly" type="text">
                </div>
                <div class="input-box-group">
                  <div class="input-box">
                    <label for="imie_reprezentanta_szkoly">Imię reprezentanta szkoły</label>
                    <input id="imie_reprezentanta_szkoly" class="aut-input" formControlName="imie_reprezentanta_szkoly" type="text">
                  </div>
                  <div class="input-box">
                    <label for="nazwisko_reprezentanta_szkoly">Nazwisko reprezentanta szkoły</label>
                    <input id="nazwisko_reprezentanta_szkoly" class="aut-input" formControlName="nazwisko_reprezentanta_szkoly" type="text">
                  </div>
                </div>
                <div class="input-box">
                  <label for="kontakt_do_reprezentanta">E-mail</label>
                  <input id="kontakt_do_reprezentanta" class="aut-input" formControlName="kontakt_do_reprezentanta" type="text">
                </div>
              </div>
            </section>

            <section>
              <header>
                Dane ucznia
              </header>
              <div class="content">
                <div class="input-box-group">
                  <div class="input-box">
                    <label for="imie_ucznia">Imię</label>
                    <input id="imie_ucznia" class="aut-input" formControlName="imie_ucznia" type="text">
                  </div>
                  <div class="input-box">
                    <label for="nazwisko_ucznia">Nazwisko</label>
                    <input id="nazwisko_ucznia" class="aut-input" formControlName="nazwisko_ucznia" type="text">
                  </div>
                </div>
              </div>
            </section>

            <section>
              <header>
                Dane rodzica/opiekuna
              </header>
              <div class="content">
                <div class="input-box-group">
                  <div class="input-box">
                    <label for="imie_rodzica">Imię</label>
                    <input id="imie_rodzica" class="aut-input" formControlName="imie_rodzica" type="text">
                  </div>
                  <div class="input-box">
                    <label for="nazwisko_rodzica">Nazwisko</label>
                    <input id="nazwisko_rodzica" class="aut-input" formControlName="nazwisko_rodzica" type="text">
                  </div>
                </div>
                <div class="input-box">
                  <label for="kontakt_do_rodzica">E-mail</label>
                  <input id="kontakt_do_rodzica" class="aut-input" formControlName="kontakt_do_rodzica" type="text">
                </div>
              </div>
            </section>

            <div class="actions">
              <button type="submit"
                      class="button primary"
                      [disabled]="!sending && !gschoolForm.valid"
              >
                Wyślij
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>



