import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { CookiesService } from '../../services/cookies.service';
import { TranslationReplacements } from './translation.type';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  allowedLanguages: string[];
  subject: BehaviorSubject<Object>;

  constructor(
    private http: HttpClient,
    private cookie: CookiesService
  ) {
    this.allowedLanguages = ['pl', 'en'];
    this.subject = new BehaviorSubject<Object>(null);

    this.loadTranslation();
  }

  private loadTranslation(language: string = this.getLanguage()) {
    if (this.subject.getValue() && this.subject.getValue().hasOwnProperty(language)) {
      this.subject.next(this.subject.getValue());
      return;
    }

    this.getTranslationConfig(language).subscribe(translation => {
      this.subject.next({
        ...this.subject.getValue(),
        ...{ [language]: translation }
      });
    }, error => {
      const defaultLanguage = 'pl';

      this.getTranslationConfig(defaultLanguage).subscribe(defaultTranslation => {
        this.subject.next({
          ...this.subject.getValue(),
          ...{ [defaultLanguage]: defaultTranslation }
        });
      }, errror => {});
    });
  }

  private getTranslationConfig(language: string): Observable<Object> {
    return this.http.get('./assets/locale/' + language + '.json');
  }

  watchTranslation(): Observable<any> {
    return this.subject.asObservable();
  }

  getLanguage(): string {
    const language = this.cookie.get('lang');
    const selectLanguage = this.cookie.get('select-lang');

    return this.allowedLanguages.indexOf(language) !== -1 ? language :
      this.allowedLanguages.indexOf(selectLanguage) !== -1 ? selectLanguage : 'pl';
  }

  // @TODO legacy to remove
  translate(input: string): string {
    const translations = this.subject.getValue();

    return translations[this.getLanguage()][input] || input;
  }

  replace(text: string, replacements: TranslationReplacements): string {
    // tslint:disable-next-line:forin
    for (const replacement in replacements) {
      text = text.replace(`[${replacement}]`, replacements[replacement]);
    }

    return text;
  }
}
