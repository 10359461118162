export const enum Language {
  PL = 'PL',
  GB = 'GB',
  US = 'US',
  ES = 'ES',
  CZ = 'CZ'
}

export enum Charset {
  UTF8 = 'utf-8',
  CP1250 = 'cp1250',
  ISO88592 = 'iso-8859-2'
}

export interface Address {
  id?: string;
  email: string;
  tokens: any;
}

export class Address implements Address {}


