<div class="cygan-balls">
  <ul>
    <li *ngFor="let item of items"
        [class.active]="item.active"
        [class.done]="item.done"
        [ngStyle]="widthStyle"
    >
      {{ item.label | translate: currentLanguageCode | async }}
    </li>
  </ul>
</div>
