import { Component, Injector, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslationService } from './translation.service';

@Component({
  selector: 'app-translation',
  template: '',
})
export class TranslationComponent implements OnDestroy {

  protected subscription: Subscription;
  protected translationService: TranslationService;

  currentLanguageCode: string;

  constructor(
    injector: Injector
  ) {
    this.translationService = injector.get(TranslationService);

    this.subscription = this.translationService.watchTranslation().subscribe(translations => {
      this.currentLanguageCode = this.translationService.getLanguage();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
