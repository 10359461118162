<div class="add-role-panel">
  <div class="add-role-panel-scroll">
    <div class="add-role-panel-scrolled">

      <div class="add-role-header" appTranslate>
        Dodaj grupę danych
      </div>

      <div class="add-role-section">
        <div class="section-header" appTranslate>
          Nazwa grupy:
        </div>
        <div class="section-content">
          <div class="role-name">
            <div class="role-name-color role-{{ newRoleId }}"></div>
            <div class="role-name-input">
              <input type="text" class="aut-input" [(ngModel)]="newRoleName" (input)="validateRole()" />
            </div>
          </div>
        </div>
      </div>

      <div class="add-role-section line">
        <div class="section-header" appTranslate>
          Czy grupa będzie opisywała Ciebie?
        </div>
        <div class="section-content">
          <div class="self-role">
            <div class="aut-radio">
              <input id="self-yes"
                     type="radio"
                     name="self"
                     [value]="true"
                     [(ngModel)]="selfRole"
                     (change)="addSelfAddress()"
              >
              <label for="self-yes" appTranslate>Tak</label>
            </div>
            <div class="aut-radio">
              <input id="self-no"
                     type="radio"
                     name="self"
                     [value]="false"
                     [(ngModel)]="selfRole"
                     (change)="addSelfAddress()"
              >
              <label for="self-no" appTranslate>Nie</label>
            </div>
          </div>
        </div>
      </div>

      <div class="add-role-section line" *ngIf="!selfRole" [@slideUpDown]>
        <div class="section-header" appTranslate>
          Źródło danych dla pól połączonych z tą grupą:
        </div>
        <div class="section-content">
          <div class="data-source">
            <div class="aut-radio">
              <input id="inline"
                     type="radio"
                     name="data-source"
                     value="INLINE"
                     [(ngModel)]="dataSource"
                     (change)="checkDataSource()"
              >
              <label for="inline" appTranslate>Wprowadzę dane samodzielnie</label>
            </div>
            <div class="aut-radio">
              <input id="address-book"
                     type="radio"
                     name="data-source"
                     value="ADDRESS_BOOK"
                     [(ngModel)]="dataSource"
                     (change)="checkDataSource()"
              >
              <label for="address-book" appTranslate>Książka adresowa</label>
            </div>
          </div>
        </div>
      </div>

      <div class="add-role-section line" *ngIf="!selfRole && dataSource === 'ADDRESS_BOOK'" [@slideUpDown]>
        <div class="section-header" appTranslate>
          Czy chcesz teraz wybrać kontakty z książki adresowej, aby automatycznie uzupełnić tę grupę?
        </div>
        <div class="section-content">
          <div class="fill-contacts">
            <div class="aut-radio">
              <input id="fill-contacts-yes"
                     type="radio"
                     name="fill-contacts"
                     [value]="true"
                     [(ngModel)]="fillContacts"
                     (change)="showAddressBook(true)"
              >
              <label for="fill-contacts-yes" appTranslate>Tak</label>
            </div>
            <div class="aut-radio">
              <input id="fill-contacts-no"
                     type="radio"
                     name="fill-contacts"
                     [value]="false"
                     [(ngModel)]="fillContacts"
                     (change)="showAddressBook(false)"
              >
              <label for="fill-contacts-no" appTranslate>Nie, zrobię to później</label>
            </div>
          </div>
        </div>
      </div>

      <div class="add-role-section line" *ngIf="!selfRole && dataSource === 'ADDRESS_BOOK'" [@slideUpDown]>
        <div class="section-header">
          <span appTranslate>Pola dostępne dla tej grupy:</span>
          <div class="info" appTranslate>
            Wybrane źródło danych umożliwia dodanie następujących pól
          </div>
        </div>
        <div class="section-content">
          <div class="data-source-tokens">
            <ul class="tokens-list">
              <li class="token role-{{ newRoleId }}" *ngFor="let token of dataSourceTokens">[{{ token }}]</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="add-role-actions">
        <button id="add-role-cancel" class="button medium" (click)="closePanel()" appTranslate>
          Anuluj
        </button>
        <button id="add-role"
                class="button primary medium"
                [class.disabled]="!canAddRole"
                [disabled]="!canAddRole"
                (click)="addRole()"
                appTranslate
        >
          Dodaj grupę
        </button>
      </div>
    </div>

  </div>
</div>
