import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRole'
})
export class FilterRolePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.filter(role => {
      return role.roleId !== 0;
    });
  }

}
