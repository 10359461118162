import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoSpecialChars]',
})
export class EditTokenTabDirective {

  private posRegex: RegExp = new RegExp(/[a-zA-Z0-9_\-]/g);
  private negRegex: RegExp = new RegExp(/[^a-zA-Z0-9_\-]/g);

  constructor(public el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event) {
    if (event.data && !event.data.match(this.posRegex)) {
      this.el.nativeElement.value = this.el.nativeElement.value.substr(0, this.el.nativeElement.value.length - 1);
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    let paste = (event.clipboardData || window['clipboardData']).getData('text');
    paste = paste.replace(this.negRegex, '');

    setTimeout(() => {
      this.el.nativeElement.value = paste.substr(0, 30);
    }, 1);
  }

}
