import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AutModalService } from './aut-modal.service';
import { AutModalDirective } from './aut-modal.directive';
import { AutModalContentComponent } from './aut-modal.type';
import { OverlayService } from '../overlay/overlay.service';
import { OverlayConfig, OverlayType } from '../overlay/overlay.type';

@Component({
  selector: 'app-aut-modal',
  templateUrl: './aut-modal.component.html',
  styleUrls: ['./aut-modal.component.scss'],
  animations: [
    trigger('modal', [
      state('false', style({ opacity: 0, transform: 'translateY(-100%)' })),
      transition('false => true',
        animate('400ms ease',
          style({ opacity: 1, transform: 'translateY(0)' }))),
      transition('true => false', animate('400ms ease'))
    ])
  ]
})
export class AutModalComponent implements OnInit {

  @ViewChild(AutModalDirective) modalHost: AutModalDirective;

  showModal: boolean;

  constructor(
    private modal: AutModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private overlay: OverlayService
  ) { }

  ngOnInit() {
    this.showModal = false;
    this.modal.get().subscribe((show: boolean) => {
      if (show) {
        this.overlay.show({ color: 'dark' });
        this.loadComponent();
        this.showModal = true;
      } else {
        this.overlay.hide();
        this.showModal = false;
      }
    });

    this.overlay.get().subscribe((overlay: OverlayConfig) => {
      if (overlay.type === OverlayType.MAIN && !overlay.show) {
        this.showModal = false;
      }
    });
  }

  closeModal() {
    this.modal.close();
    this.overlay.hide();
  }

  loadComponent() {
    const componentData = this.modal.getComponent();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentData.component);
    const viewContainerRef = this.modalHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AutModalContentComponent>componentRef.instance).data = componentData.data;
  }

}
