import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrontPaginationService } from './fron-pagination.service';

export interface FrontPaginationEvent {
  from: number;
  to: number;
}

export interface FrontPagination {
  changePage(event: FrontPaginationEvent);
}

@Component({
  selector: 'app-front-pagination',
  templateUrl: './front-pagination.component.html',
  styleUrls: ['./front-pagination.component.scss']
})
export class FrontPaginationComponent implements OnInit {

  @Input() pageLimit;
  @Input() itemsCount;
  @Input() small = false;

  @Output() pageChanged;

  page: number;
  allPages: number[];

  constructor(private frontPagination: FrontPaginationService) {
    this.pageChanged = new EventEmitter<FrontPaginationEvent>();
  }

  ngOnInit() {
    this.frontPagination.getPaginator().subscribe(itemsCount => {
      this.itemsCount = itemsCount;
      this.calculatePages();
    });

    this.calculatePages();
  }

  private calculatePages() {
    this.page = 1;
    this.allPages = Array(Math.ceil(this.itemsCount / this.pageLimit)).fill(1).map((v, i) => i + 1);
  }

  changePage(page: number) {
    this.page = page;
    const offset = (this.page - 1) * this.pageLimit;

    this.pageChanged.emit({
      from: offset,
      to: offset + this.pageLimit
    });
  }

}
