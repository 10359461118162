import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceToHtml'
})
export class ReplaceToHtmlPipe implements PipeTransform {

  transform(value: any, replacements): any {
    for (const replacement in replacements) {
      if (value && replacements[replacement]) {
        value = value.replace(`{{${replacement}}}`, `<span class="${replacement}">${replacements[replacement]}</span>`);
      }
    }

    return value;
  }
}
