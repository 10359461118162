import { Component, OnInit } from '@angular/core';
import { animate, group, query, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
  animations: [
    trigger('addressAnimation', [
      transition('people => *', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(-100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
        group([
          query(':leave', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)' }))),
          query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
        ])
      ]),
      transition('add => *, edit => *, edit-schema => *', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
        group([
          query(':leave', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)' }))),
          query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
        ])
      ]),
    ])
  ]
})
export class AddressBookComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

  getPage(addressOutlet) {
    return addressOutlet.activatedRouteData['page'];
  }

}
