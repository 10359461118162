<div class="wrapper">
  <app-overlay></app-overlay>

  <app-aut-modal></app-aut-modal>

  <app-header></app-header>

  <div class="content" [class.active-autologout-bar]="isAutologout" [@pageAnimation]="getPage(mainOutlet)">
    <router-outlet #mainOutlet="outlet"></router-outlet>
  </div>
</div>


