import { Injectable } from '@angular/core';
import { Base64Service } from '../../../core/services/base64.service';
import { DataSource, RoleData } from '../editor/editor.type';
import { Receiver, SenderType, SignatureType } from './create.type';
import { Template } from '../templates/templates.type';

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  constructor(
    private base64: Base64Service
  ) {}

  getDataFromTemplate(template: Template) {
    const { title, message, content, roles, participants } = template.draft;

    return {
      title,
      message,
      content,
      roles,
      participants
    };
  }

  mapDataTokens(roles: RoleData[]) {
    return roles.map((role: RoleData) => {
        const tokensDataSource = [];

        if (role.dataSource.type === DataSource.INLINE) {
          for (const token in role.dataSource.tokens) {
            if (role.dataSource.tokens.hasOwnProperty(token)) {
              tokensDataSource.push({
                id: token,
                value: role.dataSource.tokens[token],
              });
            }
          }
        }

        role['tokens'] = [...[], ...tokensDataSource];

        return role;
      });
  }

  prepareDocument(documentData: any, roles: RoleData[], receivers: Receiver[]) {
    const sender = receivers.find((receiver: Receiver) => receiver.isUser);
    const participants = receivers.filter((receiver: Receiver) => !receiver.isUser)
      .map((receiver: Receiver) => {
      return {
        dataSource: {
          type: DataSource.INLINE,
          tokens: {
            firstName: receiver.firstName,
            lastName: receiver.lastName,
            email: receiver.email
          }
        },
        receiverType: receiver.receiverType
      };
    });

    return {
      draft: {
        title: this.base64.encode(documentData.title),
        message: this.base64.encode(documentData.message),
        content: this.base64.encode(documentData.content),
        roles: roles.map((role: RoleData, index) => {
          let dataSource =  {};

          if (role.dataSource.type === DataSource.INLINE) {
            const tokens = {};

            role.tokens.forEach(token => {
              tokens[token.id] = token.value;
            });

            dataSource = {
              type: DataSource.INLINE,
              tokens: tokens
            };
          }

          return {
            roleId: role.roleId,
            roleName: role.roleName,
            dataSource,
          };
        }),
        senderType: sender.receiverType.includes('SIGNER') ? SenderType.SIGNER : SenderType.VIEWER,
        signatureType: SignatureType.ESIGNATURE,
        participants,
        autoSign: false
      },
      documents: [{}]
    };
  }
}
