import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EnvironmentService } from '../../services/environment.service';
import { RestrictionType, SubscriptionData } from './subscriptions.type';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsRepository {

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = `${this.env.get('apiUrl')}/app/accounts/subscription`;
  }

  getRestrictions(): Observable<string[]> {
    const url = `${this.apiUrl}/restrictions`;

    return this.http.get<string[]>(url);
  }

  getSubscriptionData(): Observable<SubscriptionData> {
    return this.getRestrictions().pipe(
      map((restrictions: RestrictionType[]) => {
        return {
          restrictions: restrictions,
        };
      }),
      catchError(error => {
        return of({
          restrictions: [],
        });
      })
    );
  }

}
