import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toEnd'
})
export class ToEndPipe implements PipeTransform {

  months = {
    1: {
      pl: 'miesiąc',
      en: 'month'
    },
    4: {
      pl: 'miesiące',
      en: 'months'
    },
    5: {
      pl: 'miesięcy',
      en: 'months'
    }
  };

  days = {
    1: {
      pl: 'dzień',
      en: 'day'
    },
    2: {
      pl: 'dni',
      en: 'days'
    }
  };

  transform(value: any, ...args: any[]): any {
    const language = args[0] || 'pl';
    let message = '';
    const currentDate = new Date().getTime();
    const difference = value - currentDate;

    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    const monthsDifference = Math.floor(daysDifference / 30);

    if (monthsDifference > 0) {
      message = ` ${monthsDifference} `;

      switch (monthsDifference) {
        case 1:
          message += `${this.months[1][language]}`;
          break;
        case 2:
        case 3:
        case 4:
          message += `${this.months[4][language]}`;
          break;
        case 5:
        default:
          message += `${this.months[5][language]}`;
      }
    } else {
      message = ` ${daysDifference} ${this.days[daysDifference > 1 ? 2 : 1][language]}`;
    }

    return message;
  }

}
