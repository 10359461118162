<div class="automation">
  <div class="sidebar">
    <ul class="menu">
      <li class="item"
          routerLink="templates"
          routerLinkActive="active"
          (click)="sendGtmEvent('Szablony')"
          [appTooltip]="'Szablony' | translate: currentLanguageCode | async"
          [tooltipOptions]="{ position: 'RIGHT', delay: '500ms' }"
      >
        <fa-icon icon="file-signature"></fa-icon>
      </li>
      <li class="item"
          [appTooltip]="'Książka adresowa' | translate: currentLanguageCode | async"
          [tooltipOptions]="{ position: 'RIGHT', delay: '500ms' }"
      >
        <a [href]="addressBookUrl">
          <fa-icon icon="address-book"></fa-icon>
        </a>
      </li>
    </ul>
  </div>

  <div class="content" [@automationAnimation]="getTab(automationOutlet)">
    <router-outlet #automationOutlet="outlet"></router-outlet>
  </div>
</div>

