<div class="user-panel" *ngIf="show" [@userPanel]>
  <header>
    {{ user.type | translate: currentLanguageCode | async }}
  </header>

  <section class="user-data">
    <div class="user-logo" *ngIf="user.logo">
      <img [src]="user.logo">
    </div>
    <div class="user-company">
      {{ user.organizationName }}
    </div>
    <div class="user-job-title">
      {{ user.jobTitle }}
    </div>
    <div class="user-name">
      {{ user.firstName }} {{ user.lastName }}
    </div>
    <div class="user-email">
      {{ user.email }}
    </div>
  </section>

  <section class="user-actions">
    <button (click)="goToDocuments()" appTranslate>Documents</button>
    <button (click)="goToAccount()" appTranslate>Account</button>
    <button (click)="goToClassicSign()">
      {{ 'Classic Sign' | translate: currentLanguageCode | async }} <span>Labs</span>
    </button>
    <button (click)="goToVerifier()" appTranslate>Verifier</button>
    <button (click)="goToBrokerPanel()" appTranslate>Broker ID</button>
    <button id="autenti-logout" (click)="logout()">
      <i class="icon-logout"></i>{{ 'Logout' | translate: currentLanguageCode | async }}
    </button>
  </section>
</div>
