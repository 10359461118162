import { Pipe, PipeTransform } from '@angular/core';

import { TranslationService } from './translation.service';

@Pipe({
  name: 'translate',
  pure: true
})
export class TranslationPipe implements PipeTransform {

  private translation: any;

  constructor(private translationService: TranslationService) {
    this.translationService.watchTranslation().subscribe(translations => {
      this.translation = translations;
    });
  }

  applyReplacements(value, args): string {
    const textToReplace = this.translation[args.code][value] || value;
    const replacements  = args.replacements;

    return this.translationService.replace(textToReplace, replacements);
  }

  transform(value: any, args?: any): Promise<string> {
    if (!this.translation) {
      return new Promise((resolve => {
        const interval = setInterval(() => {
          if (this.translation) {
            clearInterval(interval);
            resolve({});
          }
        }, 100);
      })).then(() => {
        if (args.hasOwnProperty('replacements')) {
          return this.applyReplacements(value, args);
        }

        return this.translation[args] ? this.translation[args][value] || value : value;
      });
    }

    return new Promise((resolve => {
      resolve({});
    })).then(() => {
      if (args.hasOwnProperty('replacements')) {
        return this.applyReplacements(value, args);
      }

      return this.translation[args] ? this.translation[args][value] || value : value;
    });
  }

}
