import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslationService } from './translation.service';

@Directive({
  selector: '[appTranslate]'
})
export class TranslationDirective implements OnInit, OnDestroy {

  @Input() replacements: any;
  subscription: Subscription;

  constructor(
    private el: ElementRef,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    const elementContent = this.el.nativeElement.innerHTML.trim();

    this.subscription = this.translationService.watchTranslation().subscribe(translation => {
      if (translation) {
        let textToReplace = translation[this.translationService.getLanguage()][elementContent] || elementContent;

        if (this.replacements) {
          textToReplace = this.translationService.replace(textToReplace, this.replacements);
        }

        this.el.nativeElement.innerHTML = textToReplace;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
