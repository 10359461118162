<section class="user" *ngIf="loggedUser">
  <section class="user-area">

    <section class="user-plan" [class.free]="userBilling.planName !== 'Plan Pro'" *ngIf="userBilling.planName !== ''">
      <div class="user-plan-name">
        {{ userBilling.planName }}
      </div>
      <div class="user-plan-date">
        {{ 'to end' | translate: this.currentLanguageCode | async }}:
        {{ userBilling.expirationDate | toEnd: this.currentLanguageCode }}
      </div>
    </section>

    <section class="user-logo" *ngIf="loggedUser.logo">
      <img [src]="loggedUser.logo" />
    </section>

    <section class="user-initials-box" (click)="userPanelToggle()">
      {{ userInitials }}
    </section>

    <section class="user-settings-box" (click)="userPanelToggle()">
      <i class="icon-chevron-up" [class.rotate-180]="!showUserPanel"></i>
    </section>

  </section>

  <app-user-panel [user]="loggedUser" [show]="showUserPanel"></app-user-panel>

</section>
