import { Injectable } from '@angular/core';

import { Charset } from '../../pages/address-book/address-book.type';

export interface CsvOptions {
  separator: string;
  requiredFields: string[];
}

export const DEFAULT_CSV_SEPARATOR = ';';
export const CSV_FILE_SIZE_LIMIT = 1048576;

const NEW_LINE = new RegExp('[\r\n\x0B\x0C\u0085\u2028\u2029]+', 'gm');

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  defaultOptions: CsvOptions;

  constructor() {
    this.defaultOptions = {
      separator: DEFAULT_CSV_SEPARATOR,
      requiredFields: []
    };
  }

  private getFields(fields: string[], requiredFields: string[] = []): string[] {
    if (requiredFields.length) {
      const missingFields = [];

      requiredFields.forEach(requiredFiled => {
        if (!fields.includes(requiredFiled)) {
          missingFields.push(requiredFiled);
        }
      });

      if (missingFields.length) {
        throw new Error(`Niepoprawny format pliku, brak wymaganych pól: '${missingFields.join(', ')}'`);
      }
    }

    return fields.filter(field => field !== '');
  }

  parse(content: string, options: CsvOptions): any[] {
    const objects = [];
    const currentOptions = { ...this.defaultOptions, ...options };

    let headersFields = [];

    content.split(NEW_LINE).forEach((line, lineIndex) => {
      if (lineIndex === 0) {
        headersFields = this.getFields(line.split(currentOptions.separator), currentOptions.requiredFields);
      } else {
        if (line.length > 0) {
          const valueFields = line.split(currentOptions.separator);
          const object = {};

          headersFields.forEach((headerField, headerIndex) => {
            object[headerField] = valueFields[headerIndex] || '';
          });

          objects.push(object);
        }
      }
    });

    return objects;
  }

  readCsvFile(csvFile: File): Promise<any[]> {
    const charsets = [Charset.UTF8, Charset.CP1250, Charset.ISO88592];
    const promises = [];

    charsets.forEach(charset => {
      promises.push(
        new Promise((resolve, reject) => {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            resolve({ charset, content: fileReader.result });
          };
          fileReader.readAsText(csvFile, charset);
        })
      );

    });

    return Promise.all(promises);
  }
}
