<div class="create-modal">
  <header>
    {{ data.label | translate: currentLanguageCode | async }}
  </header>
  <div class="create-modal-content">
    <form [formGroup]="receiverForm" (ngSubmit)="closeModal(true)">
      <div class="email-input">
        <label for="email">
          <span appTranslate>E-mail odbiorcy</span>
          <span class="error"
                *ngIf="receiverForm.controls.email.errors?.arrayUnique"
                appTranslate
          >
            Odbiorca o podanym adresie już istnieje!
          </span>
        </label>
        <input id="email" type="text" class="aut-input" formControlName="email" #email>
      </div>

      <div class="names-input">
        <div class="name-input">
          <label for="first-name" appTranslate>Imię</label>
          <input id="first-name"
                 type="text"
                 class="aut-input"
                 [class.invalid]="receiverForm.controls.firstName.errors?.pattern"
                 formControlName="firstName"
          >
          <div class="aut-input-error"
               *ngIf="receiverForm.controls.firstName.errors?.pattern"
               appTranslate
          >
            Imię musi posiadać od 3 do 20 znaków
          </div>
        </div>
        <div class="name-input">
          <label for="last-name" appTranslate>Nazwisko</label>
          <input id="last-name"
                 type="text"
                 class="aut-input"
                 [class.invalid]="receiverForm.controls.lastName.errors?.pattern"
                 formControlName="lastName"
          >
          <div class="aut-input-error"
               *ngIf="receiverForm.controls.lastName.errors?.pattern"
               appTranslate
          >
            Nazwisko musi posiadać od 2 do 70 znaków
          </div>
        </div>
      </div>

      <div class="signer-options">
        <div class="title" appTranslate>
          Czego oczekujesz od odbiorcy?
        </div>
        <div class="options">
          <div class="option">
            <div class="aut-radio">
              <input id="signer"
                     type="radio"
                     formControlName="receiverType"
                     value="PERSON_SIGNER"
              >
              <label for="signer" appTranslate>Podpisania dokumentu</label>
            </div>
          </div>
          <div class="option">
            <div class="aut-radio">
              <input id="viewer"
                     type="radio"
                     formControlName="receiverType"
                     value="PERSON_VIEWER"
              >
              <label for="viewer" appTranslate>Tylko prawa do wglądu</label>
            </div>
          </div>
        </div>
      </div>

      <div class="create-modal-actions">
        <div class="left-group">
          <button type="button"
                  id="add-from-address-book"
                  class="button medium"
                  *ngIf="!data.receiver.isUser"
                  (click)="showAddressBook()"
                  appTranslate
          >
            Uzupełnij z książki adresowej
          </button>
        </div>
        <div class="right-group">
          <button type="button" id="cancel" class="button medium" (click)="closeModal()" appTranslate>
            Anuluj
          </button>
          <button type="submit"
                  class="button primary medium"
                  [class.disabled]="!receiverForm.valid"
                  [disabled]="!receiverForm.valid"
          >
            {{ data.buttonLabel | translate: currentLanguageCode | async }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
