<div #tokenTooltip
     class="token-tooltip"
     [ngClass]="'position-' + positionType.toString().toLowerCase()"
     (mouseleave)="hideTooltip()"
     [@appear]
>
  <div class="token-id">
    [{{ tokenId }}]
  </div>
  <div class="token-actions">
    <input #tokenTooltipInput
           type="text"
           [placeholder]="'Wpisz wartość' | translate: currentLanguageCode | async"
           [(ngModel)]="tokenValue"
           (keydown.enter)="handleClick()"
    >
    <button class="button primary medium"
            (click)="handleClick()"
            appTranslate
    >Wstaw</button>
    <i class="icon-path" (click)="hideTooltip()"></i>
  </div>
</div>
