<div class="switch" [class.focus]="focus">
  <input id="{{ id }}"
         type="checkbox"
         (focus)="focus = true"
         (blur)="focus = false"
         [value]="value"
         [disabled]="disabled"
         [(ngModel)]="model"
         (change)="switchChanged()"
  >
  <label class="switch-background" for="{{ id }}"></label>
</div>
