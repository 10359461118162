<div class="panel">
  <div class="content" [ngSwitch]="activeItem" [@panelMenuChange]="activeItem">
    <app-edit-token-panel *ngSwitchCase="'tokens'" [data]="data"></app-edit-token-panel>
    <app-settings-panel *ngSwitchCase="'settings'"></app-settings-panel>
  </div>

  <div class="side-panel address-book" *ngIf="showAddressBookPanel" [@sidePanel]>
    <app-address-book-panel></app-address-book-panel>
  </div>

</div>
