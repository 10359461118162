import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  private subject: BehaviorSubject<any>;

  constructor() {
    this.subject = new BehaviorSubject<any>({});
  }

  private validData(data: object) {
    return typeof data === 'object' && !Array.isArray(data) && data !== null;
  }

  setData(newData: object, key: string = null) {
    if (this.validData(newData)) {
      const prevData = this.subject.getValue();

      const insertData =
        Array.isArray(newData) ? newData :
          { ...prevData[key], ...newData };

      const data = key ?
        { ...{}, ...prevData, ...{ [key]: insertData } } :
        { ...{}, ...prevData, ...insertData };

      this.subject.next(data);
    }
  }

  getData(dataKey: string = null) {
    if (!dataKey) {
      return this.subject.getValue();
    }

    const data = this.subject.getValue();

    if (data.hasOwnProperty(dataKey)) {
      return data[dataKey];
    }

    return null;
  }

  watchData(): Observable<any> {
    return this.subject.asObservable();
  }

  removeData(dataKey: string) {
    const { [dataKey]: {}, ...data } = this.subject.getValue();
    this.subject.next(data);
  }

  clear() {
    this.subject.next({});
  }
}
