import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  templatesUrl: string;

  constructor(
    private env: EnvironmentService
  ) {
    this.templatesUrl = this.env.get('templatesUrl');
  }

  set(name: string, value: string, days: number = 7, path: string = '/') {
    const expires = new Date(Date.now() + (days * 24 * 60 * 60 * 1000)).toUTCString();
    let domain = '';

    if (this.templatesUrl.includes('.lcl.') || this.templatesUrl.includes('.accept.')) {
      domain = window.location.hostname.split('.').slice(-3).join('.');
    } else {
      domain = window.location.hostname.split('.').slice(-2).join('.');
    }

    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}; domain=${domain}`;
  }

  get(name: string): string {
    const cookies = document.cookie.split('; ');
    const cookieName = `${name}=`;

    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].replace(' ', '').split('=')[0] === name) {
        return cookies[i].split(cookieName)[1];
      }
    }

    return '';
  }
}
