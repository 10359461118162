import { Injectable } from '@angular/core';

import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { AUTENTI_CONFIG_KEY, AUTENTI_CONFIG_URL } from 'src/app/app.type';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  
  public init() {
    return from(
      fetch(AUTENTI_CONFIG_URL)
        .then((response) => {
          return response.json();
        }).catch(() => {
          console.error('Config file not loaded');
        })
    ).pipe(
      map((config) => {
        if (config) {
          window[AUTENTI_CONFIG_KEY] = config;
        }
        return;
      })
    ).toPromise();
  }
}
