import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'keyOrder'
})
export class KeyOrderPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const order = args ? Array.isArray(args) ? args : [] : [];
    const valueObject = {...{}, ...value};
    const result: KeyValue<any, any>[] = [];

    if (order.length) {
      order.forEach(keyOrder => {
        if (valueObject.hasOwnProperty(keyOrder)) {
          result.push({
            key: keyOrder,
            value: valueObject[keyOrder]
          });

          delete valueObject[keyOrder];
        }
      });
    }

    for (const key in valueObject) {
      if (valueObject.hasOwnProperty(key)) {
        result.push({
          key: key,
          value: valueObject[key]
        });
      }
    }

    return result;
  }

}
