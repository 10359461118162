<div class="import-csv-modal">
  <header appTranslate>
    Importowanie pliku CSV
  </header>
  <div class="csv-content">
    <ul>
      <li class="list-header">
        <div appTranslate>Kodowanie</div>
        <div appTranslate>Treść</div>
      </li>
      <li *ngFor="let row of data">
        <div class="charset">{{ row.charset }}</div>
        <div class="content">{{ row.content }}</div>
      </li>
    </ul>
  </div>
  <footer>
    <div class="info" appTranslate>Wybierz odpowiednie kodowanie:</div>
    <div class="buttons">
      <button class="button primary medium" *ngFor="let row of data" (click)="select(row.charset)">
        {{ row.charset }}
      </button>
    </div>
  </footer>
</div>
