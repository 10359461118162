import { SafeUrl } from '@angular/platform-browser';

export enum AccountType {
  NATURAL = 'NATURAL',
  ORGANIZATION = 'ORGANIZATION',
  UNKNOWN = 'UNKNOWN'
}

export interface UserAccount {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  type: AccountType;
  organizationName: string;
  jobTitle: string;
  logo: SafeUrl;
}

export interface UserBilling {
  planName: string;
  expirationDate: number;
}

export interface User {
  account: UserAccount;
  billing: UserBilling;
}
