import { AutomationComponent } from './pages/automation/automation.component';
import { AuthGuard } from './core/auth/auth.guard';
import { TemplatesComponent } from './pages/automation/templates/templates.component';
import { TemplateAutentiResolver } from './pages/automation/templates/template-autenti.resolver';
import { SubscriptionsResolver } from './core/components/subscriptions/subscriptions.resolver';
import { TemplateCustomResolver } from './pages/automation/templates/template-custom.resolver';
import { CreateComponent } from './pages/automation/create/create.component';
import { CreateGuard } from './pages/automation/create/create.guard';
import { ProgressComponent } from './pages/automation/create/progress/progress.component';
import { ProgressGuard } from './pages/automation/create/progress/progress.guard';
import { SummaryComponent } from './pages/automation/create/summary/summary.component';
import { SummaryGuard } from './pages/automation/create/summary/summary.guard';
import { EditorComponent } from './pages/automation/editor/editor.component';
import { TemplateMode, TemplateType } from './pages/automation/templates/templates.type';
import { TemplateResolver } from './pages/automation/templates/template.resolver';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { GSchoolComponent } from './pages/gschool/gschool.component';
import { GSchoolResolver } from './pages/gschool/gschool.resolver';

export const enum RouteUrl {
  LOGIN = '/login',
  TEMPLATES = '/automation/templates',
  NEW_TEMPLATE = '/automation/templates/custom/new',
  CUSTOM_TEMPLATE = '/automation/templates/custom',
  AUTENTI_TEMPLATE = '/automation/templates/autenti',
  CREATE = '/automation/templates/create',
  CREATE_PROGRESS = '/automation/templates/create/progress',
  CREATE_SUMMARY_ERROR = '/automation/templates/create/error/summary',
  DOCUMENTS = '/automation/documents',
  ADDRESS_BOOK = '/automation/addressbook',
  GSCHOOL = '/gschool'
}

export const Routes = [
  {
    path: '',
    redirectTo: 'automation',
    pathMatch: 'full'
  },
  {
    path: 'automation',
    component: AutomationComponent,
    canActivate: [AuthGuard],
    data: {
      page: 'automation'
    },
    children: [
      {
        path: '',
        redirectTo: 'templates',
        pathMatch: 'full',
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        resolve: {
          autentiTemplates: TemplateAutentiResolver,
          customTemplates: TemplateCustomResolver,
          subscriptionData: SubscriptionsResolver
        },
        data: {
          tab: 'templates'
        }
      },
      {
        path: 'templates/create',
        component: CreateComponent,
        canActivate: [CreateGuard],
        resolve: {
          subscriptionData: SubscriptionsResolver
        },
        data: {
          tab: 'create',
        }
      },
      {
        path: 'templates/create/:createProcessId',
        component: ProgressComponent,
        canActivate: [ProgressGuard],
        data: {
          tab: 'progress',
        }
      },
      {
        path: 'templates/create/:createProcessId/summary',
        component: SummaryComponent,
        canActivate: [SummaryGuard],
        data: {
          tab: 'summary',
        }
      },
      {
        path: 'templates/custom/new',
        component: EditorComponent,
        resolve: {
          subscriptionData: SubscriptionsResolver
        },
        data: {
          tab: 'edit-template',
          templateMode: TemplateMode.EDIT,
          templateType: TemplateType.CUSTOM,
          templateNew: true
        }
      },
      {
        path: 'templates/custom/:templateId',
        component: EditorComponent,
        resolve: {
          template: TemplateResolver,
          subscriptionData: SubscriptionsResolver
        },
        data: {
          tab: 'edit-template',
          templateMode: TemplateMode.FILL,
          templateType: TemplateType.CUSTOM,
          templateNew: false
        }
      },
      {
        path: 'templates/autenti/:templateId',
        component: EditorComponent,
        resolve: {
          template: TemplateResolver,
          subscriptionData: SubscriptionsResolver
        },
        data: {
          tab: 'edit-template',
          templateMode: TemplateMode.FILL,
          templateType: TemplateType.AUTENTI,
          templateNew: false
        }
      }
    ]
  },
  {
    path: 'zgoda-g-suite',
    component: GSchoolComponent,
    canActivate: [AuthGuard],
    resolve: {
      template: GSchoolResolver
    },
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
