import { Component } from '@angular/core';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

import { OverlayService } from './core/components/overlay/overlay.service';
import { TranslationService } from './core/components/translation/translation.service';
import { GtmService } from './core/services/gtm.service';
import { AuthService } from './core/auth/auth.service';
import { HeaderService } from './core/components/header/header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('pageAnimation', [
      transition('login => *, automation => addressbook', [
        style({ height: '!', width: '!' }),
        query(':enter', style({ transform: 'translateX(-100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
        group([
          query(':leave', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)' }))),
          query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
        ])
      ]),
      transition('addressbook => login, automation => login, addressbook => automation', [
        style({ height: '!', width: '!' }),
        query(':enter', style({ transform: 'translateX(100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
        group([
          query(':leave', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)' }))),
          query(':enter', animate('350ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' })))
        ])
      ]),
    ])
  ]
})
export class AppComponent {
  isAutologout = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private headerService: HeaderService,
    private overlay: OverlayService,
    private translation: TranslationService,
    private gtmService: GtmService
  ) {

    this.headerService.getAutologoutBarVisibility().subscribe((isAutologoutVisible) => {
      this.isAutologout = isAutologoutVisible;
    });

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.overlay.show({ color: 'dark', spinner: true });
      } else if (event instanceof NavigationError || event instanceof NavigationEnd) {
        this.overlay.hide();
      }
    });

    if (window.inline_manual_player) {
      // @ts-ignore
      window.inline_manual_player.setCallbacks({
        onTopicStart: (player_instance, topic_id) => {
          this.gtmService.sendMsg({
            event: 'Inline Manual',
            inlineManualDetails: {
              description: 'onTopicStart',
              playerInstance: player_instance,
              topicId: topic_id
            }
          });
        },
        onTopicNext: (player_instance, topic_id, step_id) => {
          this.gtmService.sendMsg({
            event: 'Inline Manual',
            inlineManualDetails: {
              description: 'onTopicNext',
              playerInstance: player_instance,
              topicId: topic_id,
              stepId: step_id
            }
          });
        },
        onTopicEnd: (player_instance, topic_id) => {
          this.gtmService.sendMsg({
            event: 'Inline Manual',
            inlineManualDetails: {
              description: 'onTopicEnd',
              playerInstance: player_instance,
              topicId: topic_id
            }
          });
        },
      });
    }
  }

  getPage(mainOutlet) {
    return mainOutlet.activatedRouteData['page'];
  }
}
