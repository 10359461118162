import {EventEmitter, Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';

import { EnvironmentService } from '../services/environment.service';
import { JwtService } from '../services/jwt.service';
import {
  AUTH_COOKIES,
  REFRESH_TOKEN_PATH,
  RefreshResponse, TimeToExpireType, TOKEN_EXPIRATION_SAFE_BANNER_TIME,
  TOKEN_EXPIRATION_SAFE_TIME
} from './auth.type';
import { CookiesService } from '../services/cookies.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private expirationInterval: any;
  private timeToExpire: EventEmitter<TimeToExpireType> = new EventEmitter();

  apiUrl: string;

  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private jwt: JwtService,
    private cookie: CookiesService,
  ) {
    this.apiUrl = `${this.env.get('apiUrl')}/${this.env.get('apiPrefix')}`;

    this.expirationInterval = setInterval(this.checkExpirationInterval.bind(this), 1000);
  }

  private clearAuthCookies() {
    AUTH_COOKIES.forEach(cookie => {
      this.cookie.set(cookie, '', 0);
    });
  }

  private checkExpirationInterval() {
    const currentTime = Math.round(new Date().getTime());
    const expTime = +this.cookie.get('expirationTime');
    let difference = Math.round(((expTime - currentTime) / 1000));

    if (difference <= 0) {
      this.logout(!!expTime);
      difference = 0;
    }

    this.timeToExpire.emit({safe: (difference > TOKEN_EXPIRATION_SAFE_BANNER_TIME), difference});
  }

  getTimeToExpire(): EventEmitter<TimeToExpireType> {
    return this.timeToExpire;
  }

  validateTokenExpiration(): Observable<any> {
    const currentTime = Math.round(new Date().getTime());
    const expTime = +this.cookie.get('expirationTime');
    let difference = Math.round(((expTime - currentTime) / 1000));

    if (difference <= 0) {
      this.logout();
      difference = 0;
    }

    if (difference > TOKEN_EXPIRATION_SAFE_TIME) {
      return of(true);
    }

    return this.refreshToken();
  }

  refreshToken(): Observable<any> {
    const url = `${this.apiUrl}/${REFRESH_TOKEN_PATH}`;

    return this.http.put(url, {})
      .pipe(
        share(),
        map((response: RefreshResponse) => {
          if (response.hasOwnProperty('expiresIn')) {
            const expirationTime = Math.round(new Date().getTime()) + response.expiresIn;
            this.cookie.set('expirationTime', `${expirationTime}`);
            this.timeToExpire.emit({safe: true, difference: 0});
          }

          return true;
        }),
        catchError((error: HttpErrorResponse) => {
          throw new HttpErrorResponse({
            status: 400,
            statusText: 'Cannot refresh token',
          });
        })
      );
  }

  logout(autoLogout?: boolean) {
    this.clearAuthCookies();

    const returnParam = `?redirectUrl=${window.location.origin}`;
    const query = `${returnParam}${autoLogout ? '&autoLogout' : ''}`;
    const url = `${this.env.get('accountUrl')}/logout${query}`;

    window.location.replace(url);
  }
}
