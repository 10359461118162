import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

import Swal from 'sweetalert2';

import { Address } from '../address-book.type';
import { AddressBookRepository } from '../address-book.repository';
import { AddressesService } from './addresses.service';
import { ImportCsvModalComponent } from '../../../core/components/import-csv-modal/import-csv-modal.component';
import { TranslationComponent } from '../../../core/components/translation/translation.component';
import { FrontPagination, FrontPaginationEvent } from '../../../core/components/front-pagination/front-pagination.component';
import { PopupService } from '../../../core/services/popup.service';
import { CSV_FILE_SIZE_LIMIT, CsvService, DEFAULT_CSV_SEPARATOR } from '../../../core/services/csv.service';
import { AutModalService } from '../../../core/components/aut-modal/aut-modal.service';
import { FrontPaginationService } from '../../../core/components/front-pagination/fron-pagination.service';
import { GtmEventType, GtmService } from '../../../core/services/gtm.service';
import { CsvErrorsModalComponent } from '../../../core/components/csv-errors-modal/csv-errors-modal.component';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
  animations: [
    trigger('loadFile', [
      state('void', style({ width: 0 })),
      transition(':enter', animate('200ms ease', style({ width: '!' })))
    ])
  ]
})
export class AddressesComponent extends TranslationComponent implements OnInit, FrontPagination {

  addresses: any[];
  filteredAddresses: any[];
  addressSchema: string[];
  pageLimit: number;
  addressesCount: number;
  acceptFormat: string;
  csvFile: File;
  originTexts: string[];
  infoTexts: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private repository: AddressBookRepository,
    private addressService: AddressesService,
    private popup: PopupService,
    private modal: AutModalService,
    private csv: CsvService,
    private frontPagination: FrontPaginationService,
    private gtm: GtmService,
    private injector: Injector
  ) {
    super(injector);
    this.pageLimit = 10;
  }

  ngOnInit() {
    this.acceptFormat = '.csv';
    this.csvFile = null;
    this.addressSchema = [];
    this.originTexts = [
      'Trwa import danych', 'Import kontaktów zakończony sukcesem', 'Nie można zaimportować kontaktów', 'Usunąć wszystkie kontakty?',
      'Po usunięciu nie będzie można cofnąć zmian!', 'Tak, usuń', 'Nie', 'Usunięto wszystkie kontakty', 'Nie udało się usunąć kontaktów',
      'Usunąć kontakt?', 'Po usunięciu nie będzie można cofnąć zmian!', 'Usunięto!', 'Kontakt został usunięty', 'Plik zbyt duży',
      'Rozmiar pliku przekracza 1 MB'
    ];
    this.infoTexts = [];

    this.translationService.watchTranslation().subscribe(translations => {
      const currentLanguage = this.translationService.getLanguage();
      this.infoTexts = this.originTexts.map(originText => {
        return translations[currentLanguage][originText] || originText;
      });
    });

    this.route.data.subscribe((data: {
      addresses: Address[],
      addressSchema: string[]
    }) => {
      this.addressSchema = data.addressSchema;
      this.addresses = this.mapAddresses(data.addresses);
      this.addressesCount = this.addresses.length;
      this.filteredAddresses = this.addresses.slice(0, this.pageLimit);
    });
  }

  private refreshAddresses() {
    this.repository.getAddresses().subscribe((addressesPage: any) => {
      this.addresses = this.mapAddresses(addressesPage.content);
      this.addressesCount = this.addresses.length;
      this.filteredAddresses = this.addresses.slice(0, this.pageLimit);

      this.frontPagination.refresh(this.addressesCount);
    });
  }

  private mapAddresses(addresses: Address[]) {
    return addresses.map((address: Address) => {
      const listAddress = {
        id: address.id || '',
        email: address.email || '',
        firstName: address.tokens.firstName || '',
        lastName: address.tokens.lastName || '',
        companyName: address.tokens.companyName || '',
        nip: address.tokens.nip || '',
      };

      if (listAddress.nip) {
        listAddress['nipStatus'] = this.addressService.validateNip(listAddress.nip);
      }

      return listAddress;
    });
  }

  private checkFileSize() {
    return this.csvFile.size <= CSV_FILE_SIZE_LIMIT;
  }

  private uploadFile(charset: string = 'utf-8') {
    const data = new FormData();
    data.append(this.csvFile.name, this.csvFile);

    this.repository.uploadAddressesCsvFile(data, charset).subscribe(success => {
      this.popup.success();
      this.refreshAddresses();
    }, error => {
      if (error.status === 400) {
        Swal.close();
        this.modal.show(CsvErrorsModalComponent, error.error).then();
      } else {
        this.popup.error();
      }
    });
  }

  changePage(event: FrontPaginationEvent) {
    this.filteredAddresses = this.addresses.slice(event.from, event.to);
  }

  search(text: string) {
    if (text.length === 0) {
      this.refreshAddresses();
    } else if (text.length < 3) {
      return;
    } else {
      text = text.replace(/[ ,]+/g, ',');
      const words = text.split(',');

      let searchQuery = '';

      for (let i = 0; i < words.length; i++) {
        if (i === 5) {
          break;
        }

        if (words[i].length > 2) {
          searchQuery += `${words[i]},`;
        }
      }

      this.repository.getAddresses(searchQuery.substr(0, searchQuery.length - 1)).subscribe(data => {
        this.filteredAddresses = this.mapAddresses(data);
      });
    }
  }

  downloadCSV(separator: string = DEFAULT_CSV_SEPARATOR) {
    const rows = [this.addressSchema];
    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map(field => field.join(separator)).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'example.csv');
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  fileChanged(event) {
    this.csvFile = event.target.files[0];

    if (this.csvFile && this.checkFileSize()) {
      this.csv.readCsvFile(this.csvFile).then(texts => {
        this.modal.show(ImportCsvModalComponent, texts).then(charset => {
          if (charset) {
            this.popup.info({
              title: this.infoTexts[0],
              timer: null,
              allowOutsideClick: false,
              onOpen: () => {
                Swal.showLoading();
              }
            });
            this.uploadFile(charset);
          }
        });
      });
    } else {
      this.popup.error({
        title: this.infoTexts[13],
        text: this.infoTexts[14]
      });
    }

    event.target.files = null;
  }

  removeAllAddresses() {
    this.popup.askWarning({
      title: this.infoTexts[3],
      text: this.infoTexts[4],
      confirmButtonText: this.infoTexts[5],
      cancelButtonText: this.infoTexts[6]
    }).then(result => {
      if (result.value) {
        this.repository.removeAllAddresses().subscribe(success => {
          this.popup.success({ text: this.infoTexts[7] });
          this.addresses.splice(0, this.addresses.length);

          this.gtm.sendMsg({
            event: 'submitForm',
            description: 'Removed all contacts',
          });

        }, error => {
          this.popup.error({ text: this.infoTexts[8] });
        });
      }
    });
  }

  removeAddress(addressId: string) {
    this.popup.askWarning({
      title: this.infoTexts[9],
      text: this.infoTexts[10],
      confirmButtonText: this.infoTexts[5],
      cancelButtonText: this.infoTexts[6]
    }).then(result => {
      if (result.value) {
        const deleted = this.addresses.find(address => {
          return address.id === addressId;
        });

        if (deleted) {
          this.repository.removeAddress(addressId).subscribe(response => {
            this.addresses.splice(this.addresses.indexOf(deleted), 1);
            this.popup.success({
              title: this.infoTexts[11],
              text: this.infoTexts[12]
            });

            this.gtm.sendMsg({
              event: 'submitForm',
              description: 'Remove contact',
              type: GtmEventType.SUCCESS,
              payload:  {
                addressId: addressId
              }
            });

            this.refreshAddresses();
          });
        }
      }
    });
  }
}
