import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { TemplatesRepository } from './templates.repository';
import { TemplateList, TemplateType } from './templates.type';

@Injectable({
  providedIn: 'root'
})
export class TemplateAutentiResolver implements Resolve<Observable<TemplateList[]>> {
  constructor(
    private repository: TemplatesRepository,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TemplateList[]> {
    return this.repository.getTemplatesList(TemplateType.AUTENTI);
  }
}
