import { Component, OnInit } from '@angular/core';

import { HeaderService } from './header.service';
import { HeaderData } from './header.type';
import { EnvironmentService } from '../../services/environment.service';
import { UserService } from './user/user.service';
import { AccountType } from './user/user.type';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  settings: HeaderData;
  organization: boolean;

  constructor(
    private header: HeaderService,
    private env: EnvironmentService,
    private user: UserService,
  ) {
    this.settings = {
      showHeader: false,
      showAbove: false,
    };
  }

  ngOnInit() {

    this.header.getData().subscribe((data: HeaderData) => {
      this.settings = { ...{}, ...this.settings, ...data };
    });

    this.user.watchUserData().subscribe(data => {
      if (data && data.hasOwnProperty('account')) {
        this.organization = data.account.type === AccountType.ORGANIZATION;
      }
    });
  }

  goToDocuments() {
    window.location.replace(this.env.get('docsUrl'));
  }
}
