import { Injectable, Type } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutModalService {

  private visibleSubject: Subject<boolean>;
  private componentSubject: BehaviorSubject<any>;
  private dataSubject: Subject<any>;

  constructor() {
    this.visibleSubject = new Subject<boolean>();
    this.componentSubject = new BehaviorSubject<any>(null);
    this.dataSubject = new Subject<any>();
  }

  setComponent(component: Type<{}>, componentData: any) {
    this.componentSubject.next({
      component: component,
      data: componentData
    });
  }

  getComponent(): any {
    return this.componentSubject.getValue();
  }

  get(): Observable<boolean> {
    return this.visibleSubject.asObservable();
  }

  show(component: Type<{}>, data: any = null): Promise<any> {
    this.setComponent(component, data);
    this.visibleSubject.next(true);

    return new Promise((resolve, reject) => {
      this.dataSubject.subscribe(modalData => {
        resolve(modalData);
      });
    });
  }

  close(data: any = null) {
    this.dataSubject.next(data);
    this.visibleSubject.next(false);
  }

}
