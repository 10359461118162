import { Component, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Subscription } from 'rxjs';

import { DataSource, EditorAction, EditorActionType, RoleData, TokenData } from '../../../editor.type';
import { EditorService } from '../../../editor.service';
import { DataStoreService } from '../../../../../../core/services/data-store.service';
import { HeaderService } from '../../../../../../core/components/header/header.service';
import { OverlayService } from '../../../../../../core/components/overlay/overlay.service';
import { OverlayType } from '../../../../../../core/components/overlay/overlay.type';
import { StoreKey } from '../../../../../../core/services/data-store.type';
import { CyganBallsService } from '../../../../../../core/components/cygan-balls/cygan-balls.service';
import { TranslationComponent } from '../../../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-fill-token-tab',
  templateUrl: './fill-token-tab.component.html',
  styleUrls: ['./fill-token-tab.component.scss'],
  animations: [
    trigger('multiSend', [
      state('false', style({ opacity: 0, height: 0, visibility: 'hidden' })),
      transition('false => true', [
        style({ visibility: 'visible' }),
        animate('200ms cubic-bezier(.35,0,.25,1)', style({ opacity: 1, height: '!' }))
      ]),
      transition('true => false', [
        animate('200ms cubic-bezier(.35,0,.25,1)', style({ opacity: 0, height: 0 }))
      ])
    ]),
    trigger('slide', [
      state('false, void', style({ height: 0, padding: 0, marginBottom: 0, overflow: 'hidden' })),
      transition('false => true', [
        animate('250ms cubic-bezier(.35,0,.25,1)', style({
          height: '!',
          padding: '!',
          marginBottom: '!',
          overflow: 'visible'
        })),
      ]),
      transition('true => false', [
        animate('250ms cubic-bezier(.35,0,.25,1)'),
      ]),
    ]),
    trigger('appear', [
      transition(':leave', animate('800ms ease', style({
        opacity: 0,
      })))
    ])
  ]
})
export class FillTokenTabComponent extends TranslationComponent implements OnInit, OnDestroy {

  @Input() rolesData: RoleData[];
  @Input() templateMode: string;

  @Output() addressBookShow: EventEmitter<RoleData>;

  hasEmptyTokenValues: boolean;
  limitReached: boolean;
  activeToken = null;
  activeRole = null;
  expandedRoles: any;

  templateEmpty: boolean;

  subscriptions: Subscription[];

  constructor(
    private router: Router,
    private editor: EditorService,
    private mainHeader: HeaderService,
    private dataStore: DataStoreService,
    private overlay: OverlayService,
    private cyganBalls: CyganBallsService,
    private injector: Injector
  ) {
    super(injector);
    this.addressBookShow = new EventEmitter<RoleData>();
  }

  ngOnInit() {
    this.clearSubscriptions();
    this.templateEmpty = true;
    this.limitReached = false;

    this.setRolesState();

    this.cyganBalls.setItems([
      { label: 'Tworzenie', done: true },
      { label: 'Uzupełnianie', active: true },
      { label: 'Dodawanie odbiorców' },
      { label: 'Wysyłanie' },
    ]);

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.CHECK_TOKENS_VALUES:
            this.checkTokensValues();
            break;
        }
      })
    );

    this.dataStore.watchData().subscribe(data => {
      if (data[StoreKey.EDITOR]) {
        this.templateEmpty = !data[StoreKey.EDITOR].canSaveTemplate;
        this.limitReached = data[StoreKey.EDITOR].subscriptionLimitReached;
      }
    });

    this.checkTokensValues();
  }

  ngOnDestroy() {
    this.dataStore.setData(this.expandedRoles, StoreKey.TAB_EXTENDED_ROLES);

    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  private setRolesState() {
    this.expandedRoles = this.dataStore.getData(StoreKey.TAB_EXTENDED_ROLES);

    if (!this.expandedRoles) {
      this.expandedRoles = {};
      this.rolesData.forEach(role => {
        this.expandedRoles[role.roleId] = true;
      });
    }
  }

  private checkTokensValues() {
    const tokensToCheck = this.rolesData;
    let emptyTokens = [];

    this.hasEmptyTokenValues = true;

    if (tokensToCheck.length) {
      emptyTokens = tokensToCheck.map((role: RoleData) => {
        return role.tokens.filter((token: TokenData) => {
          return token.count > 0 && token.value === '';
        });
      }).reduce((current, next) => {
        return current.concat(next);
      });
    }

    if (emptyTokens.length === 0) {
      this.hasEmptyTokenValues = false;
    }
  }

  private setInlineDataSource() {
    this.rolesData.map((roleData: RoleData) => {
      roleData.dataSource.type = DataSource.INLINE;
      return roleData;
    });
  }

  expandRole(roleIndex: number) {
    this.expandedRoles[roleIndex] = !this.expandedRoles[roleIndex];
  }

  verifyTokens() {
    this.checkTokensValues();
  }

  handleEditorInput() {
    this.editor.callAction({ type: EditorActionType.UPDATE_TOKEN });
  }

  showAddressBook(role: RoleData) {
    this.activeRole = role;

    this.editor.callAction({
      type: EditorActionType.SHOW_ADDRESS_BOOK,
      params: {}
    });

    this.dataStore.setData({
      activeRole: this.activeRole,
    }, StoreKey.ADDRESS_BOOK_PANEL);

    this.overlay.show({ type: OverlayType.EDITOR, color: 'dark' });
  }

  setActiveToken(token) {
    this.activeToken = this.activeToken === token ? null : token;
  }

  prepareToSend() {
    if (this.limitReached || this.templateEmpty || this.hasEmptyTokenValues) {
      return;
    }

    this.editor.callAction({
      type: EditorActionType.PREPARE_TO_SEND
    });
  }

}
