import { Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslationComponent } from '../translation/translation.component';
import {AuthService} from '../../auth/auth.service';
import {TimeToExpireType} from '../../auth/auth.type';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-autologout-bar',
  templateUrl: './autologout-bar.component.html',
  styleUrls: ['./autologout-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutologoutBarComponent extends TranslationComponent implements OnInit, OnDestroy {
  private secondsToExpire: number;
  private timeExpirationSubscription: Subscription;

  timeToExpire: string;
  refreshLoading: boolean;
  refreshSuccess: boolean;
  willSoonExpire: boolean;

  textContent: {
    [x: string]: string,
  };

  constructor(
    private authService: AuthService,
    private headerService: HeaderService,
    protected injector: Injector,
  ) {
    super(injector);
    this.willSoonExpire = false;
    this.secondsToExpire = 0;
    this.refreshLoading = false;
    this.refreshSuccess = false;

    this.textContent = {
      willSoonExpire: 'You will be signed out in {{timeToExpire}}. If you want to stay in this session, ',
      extendSession: 'renew session',
      extendingSessionInProgress: 'Renew session in progress...',
      sessionExtended: 'Session has been renewed',
    };
  }

  private formatTimeToExpire(secondsNumber: number): string {
    let minutes;
    let seconds;

    // tslint:disable-next-line:no-bitwise
    const minutesNumber = (secondsNumber / 60) | 0; // round number down - is faster than Math.floor()
    secondsNumber = secondsNumber % 60; // rest of seconds

    minutes = `${minutesNumber}`;
    seconds = `${secondsNumber}`;
    if (seconds.length < 2) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  private renewSession() {
    this.refreshLoading = true;
    this.authService.refreshToken().subscribe((refreshSuccess) => {
      if (!refreshSuccess) {
        this.authService.logout(true);
      }
    },
    (error) => {
      this.authService.logout(true);
    });
  }

  ngOnInit() {

    this.timeExpirationSubscription = this.authService.getTimeToExpire().subscribe((timeToExpire: TimeToExpireType) => {
      if (this.willSoonExpire && this.willSoonExpire !== !timeToExpire.safe) {
        this.refreshLoading = false;
        this.refreshSuccess = true;
        setTimeout(() => {
          this.refreshSuccess = false;
        }, 3000);
      }

      this.willSoonExpire = !timeToExpire.safe;
      this.secondsToExpire = timeToExpire.difference;
      this.timeToExpire = this.formatTimeToExpire(timeToExpire.difference);
      this.headerService.getAutologoutBarVisibility().emit(this.willSoonExpire || this.refreshLoading || this.refreshSuccess);
    });
  }

  clickRenewSession() {
    this.renewSession();
  }

  ngOnDestroy() {
    this.timeExpirationSubscription.unsubscribe();
  }
}
