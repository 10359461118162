<div class="templates">
  <div class="scroll-page templates-wrapper">
    <app-templates-list [templates]="autentiTemplates"
                        [listTitle]="'Gotowe szablony dokumentów' | translate: currentLanguageCode | async"
                        [templateType]="TemplateType.AUTENTI"
                        [hasOverlay]="true"
    ></app-templates-list>
    <app-templates-list [templates]="customTemplates"
                        [listTitle]="'Moje szablony' | translate: currentLanguageCode | async"
                        [templateType]="TemplateType.CUSTOM"
                        [canAddNew]="true"
    ></app-templates-list>
  </div>
</div>

