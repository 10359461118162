import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

import { GSchoolService } from './gschool.service';
import { Template } from '../automation/templates/templates.type';
import { PopupService } from '../../core/services/popup.service';
import { CreateRepository } from '../automation/create/create.repository';
import { CreateService } from '../automation/create/create.service';
import { ReceiverType } from '../automation/editor/editor.type';
import { Receiver } from '../automation/create/create.type';
import { UserService } from '../../core/components/header/user/user.service';

@Component({
  selector: 'app-gschool',
  templateUrl: './gschool.component.html',
  styleUrls: ['./gschool.component.scss']
})
export class GSchoolComponent implements OnInit {

  template: Template;
  gschoolForm: FormGroup;
  receivers: Receiver[];
  tokens: string[];
  sending: boolean;

  constructor(
    private route: ActivatedRoute,
    private service: GSchoolService,
    private user: UserService,
    private createRepository: CreateRepository,
    private create: CreateService,
    private formBuilder: FormBuilder,
    private popup: PopupService
  ) { }

  ngOnInit() {
    this.sending = false;
    this.tokens = [];
    this.receivers = [];

    this.route.data.subscribe((data: {
      template: Template
    }) => {
      this.template = data.template;

      this.template.draft.roles.map(role => {
        for (const token in role.dataSource.tokens) {
          if (role.dataSource.tokens.hasOwnProperty(token)) {
            this.tokens.push(token);
          }
        }
      });

      this.createForm();
    });
  }

  private createForm() {
    this.gschoolForm = this.formBuilder.group({});

    this.tokens.forEach(tokenName => {
      const formControl = new FormControl('');

      switch (tokenName) {
        case 'imie_rodzica':
          formControl.setValidators([Validators.required, Validators.pattern(/^.{3,20}$/)]);
          break;
        case 'nawisko_rodzica':
          formControl.setValidators([Validators.required, Validators.pattern(/^.{2,70}$/)]);
          break;
        case 'kontakt_do_reprezentanta':
        case 'kontakt_do_rodzica':
          formControl.setValidators([Validators.required, Validators.email]);
          break;
      }

      this.gschoolForm.addControl(tokenName, formControl);
    });

    const emailControl = new FormControl('', [Validators.required, Validators.email]);
    this.gschoolForm.addControl('kontakt_do_rodzica', emailControl);
  }

  private clearForm() {
    const clearValues = {
      imie_ucznia: '',
      nazwisko_ucznia: '',
      imie_rodzica: '',
      nazwisko_rodzica: '',
      kontakt_do_rodzica: ''
    };

    const currentValues = this.gschoolForm.getRawValue();

    this.gschoolForm.setValue({...currentValues, ...clearValues});
  }

  private createReceivers(firstName, lastName, email) {
    const userData = this.user.getUserAccount();

    this.receivers = [];
    this.receivers.push({
      isUser: true,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      receiverType: ReceiverType.PERSON_VIEWER
    });

    this.receivers.push({
      isUser: false,
      firstName,
      lastName,
      email,
      receiverType: ReceiverType.PERSON_SIGNER
    });
  }

  submitForm() {
    const { imie_rodzica, nazwisko_rodzica, kontakt_do_rodzica, ...tokens } = this.gschoolForm.getRawValue();

    this.template.draft.roles[0].dataSource.tokens = {...{}, ...{ imie_rodzica, nazwisko_rodzica }, ...tokens};

    this.createReceivers(imie_rodzica, nazwisko_rodzica, kontakt_do_rodzica);
    this.sendForm();
  }

  sendForm() {
    const documentData = this.create.getDataFromTemplate(this.template);
    const rolesData = this.create.mapDataTokens(documentData.roles);

    const data = this.create.prepareDocument(documentData, rolesData, this.receivers);
    this.sending = true;

    this.popup.info({
      title: 'Wysyłanie dokumentu',
      timer: null,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });

    this.createRepository.sendDocument(data).subscribe(documentId => {
      this.createRepository.getDocumentStatus(documentId).subscribe(res => {
        this.popup.success({
          title: 'Sukces',
          text: 'Dokument wysłany'
        });

        this.clearForm();
        this.sending = false;
      }, error => {
        this.popup.error();
        this.sending = false;
      });
    }, error => {
      this.popup.error();
      this.sending = false;
    });
  }

}
