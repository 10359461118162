<div class="editor">
  <div class="content">
    <div class="edit-area-wrapper-scroll">
      <div class="edit-area-wrapper" (scroll)="handleEditorScroll($event)">

        <div class="template">
          <div class="template-settings">
            <div class="template-settings-header" appTranslate>
              Ustawienia
            </div>
            <div class="template-settings-content">
              <div class="template-settings-data">
                <div class="template-name">
                  <div class="template-settings-data-header">
                    <span appTranslate>Nazwa</span>: <i class="icon-add-info"
                              [appTooltip]="'Pod taką nazwą będzie zapisany tytuł dokumentu. Możesz użyć pola np. [nazwisko], dzięki czemu każdy dokument będzie miał indywidualną nazwę' | translate: currentLanguageCode | async"
                              [tooltipOptions]="{ delay: '50ms' }"></i>
                    <span class="required" *ngIf="templateTitleText?.trim() === ''" appTranslate>* Nazwa nie może być pusta</span>
                  </div>
                  <div class="template-settings-data-content name"
                       contenteditable="true"
                       #templateTitleRef
                       (focus)="handleSettingsFocus($event)"
                       (keydown.enter)="disableEnter($event)"
                       (keydown)="disableShortcuts($event)"
                       (paste)="disablePaste($event)"
                       (input)="checkTemplateSettings()"
                       (dragover)="handleEditorDragOver($event)"
                       (drop)="handleEditorDrop($event)"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="template-header">
            <span appTranslate>Treść</span>&nbsp;<i class="icon-add-info"
                     [appTooltip]="'Z poniższej treści utworzymy szablon oraz dokument do podpisania. Możesz w niej użyć pól aby sprawniej wypełniać dane które się zmieniają w każdym dokumencie' | translate: currentLanguageCode | async"
                     [tooltipOptions]="{ delay: '50ms' }"></i>
            <span class="required" *ngIf="page.innerText.trim() === ''" appTranslate>* Treść nie może być pusta</span>
          </div>

          <div class="toolbar-container">
            <app-toolbar></app-toolbar>
          </div>

          <div class="page"
               contenteditable="true"
               #page
               (click)="handleEditorClick($event)"
               (paste)="handleEditorPaste($event)"
               (input)="handleEditorInput()"
               (keydown)="handleEditorKeyDown($event)"
               (keyup)="handleEditorKeyUp($event)"
               (dragover)="handleEditorDragOver($event)"
               (drop)="handleEditorDrop($event)"
          ></div>
        </div>

      </div>
    </div>

    <app-panel [data]="rolesData"></app-panel>

  </div>
</div>
