import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterToken',
  pure: false
})
export class FilterTokenPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const tokens = [...[], ...value];

    return tokens.filter(token => {
      return token.count > 0;
    });
  }

}
