import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { TooltipComponent } from '../../tooltip.component';
import { TooltipService } from '../../tooltip.service';
import { EditorActionType } from '../../../../../pages/automation/editor/editor.type';

@Component({
  selector: 'app-token-tooltip',
  templateUrl: './token-tooltip.component.html',
  styleUrls: ['./token-tooltip.component.scss'],
  animations: [
    trigger('appear', [
      state('void', style({ opacity: 0 })),
      transition(':enter', animate('100ms 100ms ease-in', style({ opacity: 1 }))),
      transition(':leave', animate('100ms ease-out', style({ opacity: 0 })))
    ])
  ]
})
export class TokenTooltipComponent extends TooltipComponent implements OnInit, AfterViewInit {

  @ViewChild('tokenTooltip') tokenTooltip: ElementRef;
  @ViewChild('tokenTooltipInput') tokenTooltipInput: ElementRef;

  tokenId: string;
  tokenValue: string;
  tooltip: TooltipService;

  constructor(injector: Injector) {
    super(injector);

    this.tooltip = injector.get(TooltipService);
  }

  ngOnInit() {
    this.tokenValue = this.data.token.value || '';
    this.tokenId = this.data.token.id;
  }

  ngAfterViewInit() {
    this.setTooltipPosition(this.tokenTooltip);

    (<HTMLElement>this.tokenTooltipInput.nativeElement).focus();
  }

  hideTooltip() {
    this.tooltip.hide();
  }

  handleClick() {
    this.data.token.value = this.tokenValue;

    this.editor.callAction({ type: EditorActionType.UPDATE_TOKEN });

    this.hideTooltip();
  }

}
