import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { DataStoreService } from '../../../core/services/data-store.service';
import { RouteUrl } from '../../../app.routes';
import { StoreKey } from '../../../core/services/data-store.type';

@Injectable({
  providedIn: 'root'
})
export class CreateGuard implements CanActivate {

  constructor(private router: Router, private dataStore: DataStoreService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const data = this.dataStore.getData(StoreKey.DOCUMENT_DATA);

    if (data) {
      return true;
    }

    this.router.navigate([RouteUrl.TEMPLATES]);
    return false;
  }
}
