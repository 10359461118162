import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { AUTENTI_CONFIG_KEY } from 'src/app/app.type';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private env: any;

  constructor() {
    this.refresh();
  }

  private refresh() {
    this.env = { ...environment, ...window[AUTENTI_CONFIG_KEY] };
  }

  get(key: string) {
    this.refresh();
    return this.env[key];
  }
}
