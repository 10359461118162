import { Component, Injector, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AuthService } from '../../../../auth/auth.service';
import { TranslationComponent } from '../../../translation/translation.component';
import { EnvironmentService } from '../../../../services/environment.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  animations: [
    trigger('userPanel', [
      state('void', style({
        opacity: 0,
        transform: 'translateX(8px) translateY(-8px)'
      })),
      transition(':enter', animate('200ms cubic-bezier(.35, 0, .25, 1)', style({
        opacity: 1,
        transform: 'translate(0, 0)'
      }))),
      transition(':leave', animate('200ms cubic-bezier(.35, 0, .25, 1)', style({
        opacity: 0,
        transform: 'translateX(8px) translateY(-8px)'
      })))
    ]),
  ]
})
export class UserPanelComponent  extends TranslationComponent implements OnInit {

  @Input() user: any;
  @Input() show: boolean;

  constructor(
    private auth: AuthService,
    private env: EnvironmentService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {}

  goToDocuments() {
    window.location.replace(this.env.get('docsUrl'));
  }

  goToAccount() {
    window.location.replace(this.env.get('accountUrl'));
  }

  goToClassicSign() {
    window.location.replace(this.env.get('classicSignUrl'));
  }

  goToVerifier() {
    window.location.replace(this.env.get('verifyUrl'));
  }

  goToBrokerPanel() {
    window.location.replace(this.env.get('brokerPanelUrl'));
  }

  logout() {
    this.auth.logout();
  }
}
