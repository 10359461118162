import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { EnvironmentService } from '../../core/services/environment.service';
import { GSchoolTemplateData } from './gschool.type';
import { TemplatesRepository } from '../automation/templates/templates.repository';
import { Template, TemplateType } from '../automation/templates/templates.type';

@Injectable({
  providedIn: 'root'
})
export class GSchoolService {

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private reposiotry: TemplatesRepository
  ) {
    this.apiUrl = `${this.env.get('apiUrl')}/${this.env.get('apiPrefix')}`;
  }

  getGSchoolTemplate(): Observable<Template> {
    const url = `${this.apiUrl}/gsuite/school`;

    return this.http.get<GSchoolTemplateData>(url).pipe(
      flatMap(gschool => {
        return this.reposiotry.getTemplate(gschool.defaultTemplateId, TemplateType.AUTENTI);
      })
    );
  }
}
