import { Component, ElementRef, Injector, Input, Renderer2 } from '@angular/core';

import { TooltipPositionType } from './tooltip.type';
import { EditorService } from '../../../pages/automation/editor/editor.service';
import { TranslationComponent } from '../translation/translation.component';

@Component({
  selector: 'app-tooltip',
  template: ''
})
export class TooltipComponent extends TranslationComponent {

  @Input() data: any;

  renderer: Renderer2;
  editor: EditorService;

  position: any;
  positionType: TooltipPositionType;

  constructor(injector: Injector) {
    super(injector);

    this.renderer = injector.get(Renderer2);
    this.editor = injector.get(EditorService);

    this.position = {};
    this.positionType = TooltipPositionType.TOP;
  }

  setTooltipPosition(element: ElementRef) {
    const elementWidth = element.nativeElement.offsetWidth;
    const elementHeight = element.nativeElement.offsetHeight;

    let topPosition = 0;
    let leftPosition = 0;

    switch (this.positionType) {
      case TooltipPositionType.TOP:
        leftPosition = this.data.position.left - (elementWidth / 2);
        topPosition = this.data.position.top - (elementHeight + 10);

        this.renderer.setStyle(element.nativeElement, 'top',  topPosition + 'px');
        this.renderer.setStyle(element.nativeElement, 'left', leftPosition + 'px');
        break;
      case TooltipPositionType.LEFT:
        topPosition = this.data.position.top - (elementHeight / 2);
        leftPosition = this.data.position.left - (elementWidth + 10);

        this.renderer.setStyle(element.nativeElement, 'top',  topPosition + 'px');
        this.renderer.setStyle(element.nativeElement, 'left',  leftPosition + 'px');
        break;
      case TooltipPositionType.RIGHT:
        topPosition = this.data.position.top - (elementHeight / 2);
        leftPosition = this.data.position.left + 2;

        this.renderer.setStyle(element.nativeElement, 'top',  topPosition + 'px');
        this.renderer.setStyle(element.nativeElement, 'left',  leftPosition + 'px');
        break;
    }
  }
}
