import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { TextTooltipComponent } from './types/text-tooltip/text-tooltip.component';
import { TooltipData } from './tooltip.type';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  componentRef: ComponentRef<TooltipComponent>;
  containerRef: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  show(containerRef: ViewContainerRef, data: TooltipData, component: Type<TooltipComponent> = TextTooltipComponent) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    this.containerRef = containerRef;
    this.componentRef = containerRef.createComponent(componentFactory);
    this.componentRef.instance.data = data;
  }

  hide() {
    if (this.containerRef) {
      this.containerRef.detach();
    }
  }

}
