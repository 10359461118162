<header class="main" [class.above]="settings.showAbove">
  <section class="header-info">
    <div class="logo" [class.business]="organization" (click)="goToDocuments()"></div>
    <div class="name" appTranslate>
      Templates
    </div>
  </section>

  <section class="header-content" *ngIf="settings.showHeader">
    <div></div>
    <app-user></app-user>
  </section>
</header>

<app-autologout-bar></app-autologout-bar>
