import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { EditorAction, EditorActionType, Node, TokenParams } from './editor.type';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  private actionSubject: BehaviorSubject<EditorAction>;

  constructor() {
    this.actionSubject = new BehaviorSubject<EditorAction>({
      type: EditorActionType.NOOP_ACTION,
      params: ''
    });
  }

  private insertSpace(range: Range, hard: boolean = false): Text {
    const space = document.createTextNode(hard ? '\u00A0' : '');

    range.insertNode(space);
    range.collapse(false);

    return space;
  }

  getEditor(): Observable<EditorAction> {
    return this.actionSubject.asObservable();
  }

  callAction(action: EditorAction) {
    this.actionSubject.next(action);
  }

  insertToken(range: Range, tokenParams: TokenParams): boolean {
    const token = document.createElement('token');

    token.setAttribute('data-id', tokenParams.id);
    token.setAttribute('data-role', tokenParams.roleId.toString());

    let container = range.startContainer;
    const offset = range.startOffset;

    if (!container.hasChildNodes()) {
      container = this.insertSpace(range);
    }

    switch (container.nodeName) {
      case Node.TD:
      case Node.LI:
        container = this.insertSpace(range);
        break;
    }

    if (container.nodeName === Node.DIV) {
      const childBefore = container.childNodes[offset - 1];

      if (childBefore) {
        switch (childBefore.nodeName) {
          case Node.TOKEN:
            container = this.insertSpace(range);
            break;
          case Node.TEXT:
            container = childBefore;
            break;
        }
      }
    }

    if (container.nodeName === Node.TEXT) {
      range.insertNode(token);
      range.setStartAfter(token);

      if (!token.nextSibling) {
        const space = this.insertSpace(range);
        range.setStartAfter(space);
      }

      range.collapse(false);

      return true;
    }

    return false;
  }
}
