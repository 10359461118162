import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';

import { Subscription } from 'rxjs';

import { EditorAction, EditorActionType, RoleData } from '../../editor.type';
import { EditorService } from '../../editor.service';
import { HeaderService } from '../../../../../core/components/header/header.service';
import { DataStoreService } from '../../../../../core/services/data-store.service';
import { Address } from '../../../../address-book/address-book.type';
import { OverlayService } from '../../../../../core/components/overlay/overlay.service';
import { StoreKey } from '../../../../../core/services/data-store.type';
import { TemplateMode, TemplateType } from '../../../templates/templates.type';
import { GtmEventType, GtmService } from '../../../../../core/services/gtm.service';
import { TranslationComponent } from '../../../../../core/components/translation/translation.component';

@Component({
  selector: 'app-edit-token-panel',
  templateUrl: './edit-token-panel.component.html',
  styleUrls: ['./edit-token-panel.component.scss'],
  animations: [
    trigger('tabChange', [
      transition('EDIT => FILL' , [
        style({ height: '!' }),
        query('.tab-content:enter', style({ transform: 'translateX(-100%)' })),
        query('.tab-content:enter, .tab-content:leave',
          style({ height: '!', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 })),
        group([
          query('.tab-content:leave', animate('250ms ease', style({ transform: 'translateX(100%)' }))),
          query('.tab-content:enter', animate('250ms ease', style({ transform: 'translateX(0)' })), { limit: 1 })
        ])
      ]),
      transition('FILL => EDIT' , [
        style({ height: '!' }),
        query('.tab-content:enter', style({ transform: 'translateX(100%)' })),
        query('.tab-content:enter, .tab-content:leave',
          style({ height: '!', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 })),
        group([
          query('.tab-content:leave', animate('250ms ease', style({ transform: 'translateX(-100%)' }))),
          query('.tab-content:enter', animate('250ms ease', style({ transform: 'translateX(0)' })), { limit: 1 })
        ])
      ]),
    ]),
    trigger('sidePanel', [
      state('void', style({
        height: '!',
        transform: 'translateX(100%)',
        background: '#fff',
        zIndex: 1
      })),
      transition('void => *', [
        animate('250ms cubic-bezier(.35,0,.25,1)', style({
          transform: 'translateX(0)'
        }))
      ]),
      transition('* => void', [
        animate('250ms cubic-bezier(.35,0,.25,1)', style({
          transform: 'translateX(100%)'
        })),
      ])
    ]),
  ]
})
export class EditTokenPanelComponent extends TranslationComponent implements OnInit, OnDestroy {

  @Input() data: RoleData[];

  activeRole = null;
  showAddressBookPanel: boolean;
  showAddRolePanel: boolean;
  showOverlay: boolean;
  templateType: TemplateType;
  templateMode: TemplateMode;
  TemplateMode = TemplateMode;

  subscriptions: Subscription[];

  constructor(
    private mainHeader: HeaderService,
    private editor: EditorService,
    private dataStore: DataStoreService,
    private overlay: OverlayService,
    private gtm: GtmService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.clearSubscriptions();

    const editorData = this.dataStore.getData(StoreKey.EDITOR);
    this.templateType = editorData.templateType;
    this.templateMode = editorData.templateMode;

    this.showAddressBookPanel = false;
    this.showOverlay = false;

    this.subscriptions.push(
      this.editor.getEditor().subscribe((action: EditorAction) => {
        switch (action.type) {
          case EditorActionType.SHOW_ADD_ROLE_PANEL:
            this.showAddRolePanel = true;
            break;
          case EditorActionType.CLOSE_ADD_ROLE_PANEL:
            this.showAddRolePanel = false;
            break;
          case EditorActionType.CLOSE_ADDRESS_BOOK:
            this.useAddressData(action.params);
            break;
       }
      })
    );
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subscriber => {
        subscriber.unsubscribe();
      });
    }

    this.subscriptions = [];
  }

  switchTemplateMode(templateMode: TemplateMode) {
    this.templateMode = templateMode;

    this.dataStore.setData({ templateMode: templateMode }, StoreKey.EDITOR);
  }

  useAddressData(address: Address) {
    if (!address) {
      this.showAddressBookPanel = false;
      this.overlay.hide();

      return;
    }

    const data = this.dataStore.getData(StoreKey.ADDRESS_BOOK_PANEL);
    this.activeRole = data.activeRole;

    this.gtm.sendMsg({
      event: 'submitForm',
      description: 'Fill role data from address book',
      type: GtmEventType.SUCCESS,
      payload: {
        roleId: this.activeRole.roleId,
        roleName: this.activeRole.roleName
      }
    });

    this.data = this.data.map(role => {
      if (role.roleId === +this.activeRole.roleId) {
        role.tokens.map(token => {
          token.value = address[token.id] || token.value;
          return token;
        });
      }

      return role;
    });

    this.showAddressBookPanel = false;
    this.overlay.hide();

    this.editor.callAction({ type: EditorActionType.UPDATE_TOKEN });
  }

}
