<div class="scroll-page create">
  <div class="content-box">
    <div class="content-box-header">
      <span appTranslate>Dodawanie odbiorców</span>
      <div class="content-box-header-actions">
      </div>
    </div>
    <div class="content-box-content">
      <div class="create-wrapper">
        <div class="create-header">
          <app-progress-balls [items]="stepItems"></app-progress-balls>
        </div>
        <div class="roles-receivers">
          <div class="roles">
            <div class="title" appTranslate>
              Grupy danych występujące w dokumencie
            </div>
            <div class="subtitle" appTranslate>
              Dodaj jako odbiorcę dokumentu osobę występującą w tej grupie.
            </div>
            <ul class="roles-list">
              <li class="role" *ngFor="let roleReceiver of roleReceivers">
                <div class="role-data">
                  <div class="color role-{{ roleReceiver.roleId }}"></div>
                  <div class="data">
                    <div class="name role-color-{{ roleReceiver.roleId }}">{{ roleReceiver.roleName }}</div>
                    <div class="contact">
                      <span class="empty"
                            *ngIf="!roleReceiver.firstName.trim() && !roleReceiver.lastName.trim() &&
                            !roleReceiver.email.trim(); else singleLabels"
                            appTranslate
                      >brak danych osobowych</span>
                      <ng-template #singleLabels>
                        <span [class.empty]="!roleReceiver.firstName.trim()">
                        {{ roleReceiver.firstName || 'brak imienia' }},
                      </span>
                        <span [class.empty]="!roleReceiver.lastName.trim()">
                        {{ roleReceiver.lastName || 'brak nazwiska' }},
                      </span>
                        <span [class.empty]="!roleReceiver.email.trim()">
                        {{ roleReceiver.email || 'brak adresu email' }}
                      </span>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="role-actions">
                  <button class="button small"
                          *ngIf="!isReceiver(roleReceiver.roleId)"
                          (click)="addReceiver(roleReceiver)"
                          appTranslate
                  >
                    Dodaj jako odbiorcę
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="receivers">
            <div class="title" appTranslate>
              Odbiorcy dokumentu
            </div>
            <div class="content">
              <div class="receivers-list-empty" *ngIf="!receivers.length" appTranslate>
                Brak odbiorców
              </div>
              <ul class="receivers-list">
                <li class="receiver" *ngFor="let receiver of receivers" (click)="editReceiver(receiver)">
                  <div class="receiver-personal-data">
                    <div class="receiver-initials" [class.user]="receiver.isUser">
                      {{ receiver.firstName[0].toUpperCase() }}{{ receiver.lastName[0].toUpperCase() }}
                    </div>
                    <div class="receiver-data">
                      <div class="receiver-name">{{ receiver.firstName }} {{ receiver.lastName }}</div>
                      <div class="receiver-email">{{ receiver.email }}</div>
                    </div>
                  </div>

                  <div class="receiver-additional-data">
                    <div class="receiver-type">
                      {{ receiverLabel[receiver.receiverType] | translate: currentLanguageCode | async }}
                    </div>
                    <button class="button small icon-only danger"
                            (click)="removeReceiver(receiver)"
                            [disabled]="receiver.isUser"
                    >
                      <i class="icon-delete"></i>
                    </button>
                  </div>
                </li>
              </ul>

              <div class="receivers-actions">
                <button class="button primary" (click)="addReceiver()" appTranslate>
                  Dodaj nowego odbiorcę
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="create-actions">
        <div class="info">
          <span *ngIf="disabledInfo.signer" appTranslate>Przynajmniej jeden z odbiorców musi podpisywać dokument</span>
        </div>
        <button *ngIf="limitReached"
                class="button primary"
                [class.disabled]="limitReached || !receivers.length || disableButton"
                (click)="sendDocument()"
                [appTooltip]="'Maksymalny limit wysłanych dokumentów dla tego konta został osiągnięty. Kup pakiet PRO aby wysyłać dokumenty.' | translate: currentLanguageCode | async"
                [tooltipOptions]="{ position: 'LEFT', delay: '500ms', wide: true }"
                appTranslate
        >
          Wyślij dokument
        </button>
        <button *ngIf="!limitReached"
                class="button primary"
                [class.disabled]="!receivers.length || disableButton"
                [disabled]="!receivers.length || disableButton"
                (click)="sendDocument()"
                appTranslate
        >
          Wyślij dokument
        </button>
      </div>

    </div>
  </div>

  <div class="side-panel" *ngIf="showAddressBook" [@sidePanel]>
    <app-address-book-panel [options]="{ self: false, tokensFilter: ['firstName', 'lastName', 'email']}"></app-address-book-panel>
  </div>

</div>
